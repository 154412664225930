import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';

const cx = classNames.bind(style);

interface NoticeModelProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  content: string;
  openAccount?: boolean;
}

const NoticeModel: React.FC<NoticeModelProps> = ({
  isOpen,
  handleClose,
  title,
  content,
  openAccount,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => {
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        className="popup_header"
      >
        <Modal.Title className={cx('w-100', 'text-center', 'text-white')} style={{ paddingLeft: '30px' }}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={cx(openAccount && 'pt-5')}
        style={{ whiteSpace: 'pre-line' }}
      >
        <span style={{ fontSize: '20px' }}>{content}</span>

        {openAccount && (
          <div className="text-center mb-3 mt-5">
            <span
              className={cx('submitBtn')}
              onClick={() => {
                window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401')
                  
              }}
            >
              立即開戶
            </span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NoticeModel;
