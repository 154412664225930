import React from 'react';
import classNames from 'classnames/bind';

// import { CheckBox } from 'components/Icons';

import style from './index.module.css';

const cx = classNames.bind(style);

export interface CheckBoxWithTextProps {
  status: 'A' | 'B' | 'N'; //0買賣現沖 1先買現沖 2禁現沖
}

const CheckBoxWithText: React.FC<CheckBoxWithTextProps> = ({ status }) => {
  return (
    <div className={cx('wrap', 'h-100', 'w-100', 'min-36px')}>
      <div className={cx('dayTradingBox')}>
        <div className={cx('col-12', 'dayTradingFont', 'text-center')}>
          {status === 'A' ? '雙向' : status === 'B' ? '單向' : '禁止'}
        </div>
        <div className={cx('col-12', 'dayTradingFont')}>現沖</div>
      </div>
    </div>
  );
};

export default CheckBoxWithText;
