import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface userResetPayloadType {
  email: string;
  code: string;
  password: string;
}
export interface userForgetPasswordType {
  code: number;
  msg: string;
}

export const userReset = async (axios: AxiosInstance, payload: userResetPayloadType) => {
  // const { uid } = (window.parent as any).reactGetLoginInfo();
  // const uidstring = `?uid=${uid}`;
  const { data } = await axios.post<userForgetPasswordType>(
    `${process.env.REACT_APP_URL}api/user_reset.php`, payload
  );

  console.log(data)

  if (data.code === 0) {
    toast.success(`密碼變更成功，請返回隨身e策略重新登入`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    setTimeout(() => {
      window.location.href = "https://www.kgieworld.com.tw/ExternalFiles/mobile/WFLinkApp.aspx?rUrl=kgiA";
    }, 3000);
  }
  return data;
};
