import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface teamJoinPayload {
  cu_id: string;
  team_code: string;
  school_id: number;
  student_id: string;
  professor: string;
}

export interface teamJoinResponse {
  err: string;
  message: string;
}

export const teamJoin = async (
  axios: AxiosInstance,
  payload: teamJoinPayload
) => {

  const res = await axios.post<teamJoinResponse>(
    "api2/Team/TeamJoin", payload
  );


  if (res.data.err !== "0000") {
    toast.error(
      `隊伍加入失敗:${"(" + res.data.err + ")" + res.data.message}`,
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  } else {
    // toast.success(`隊伍加入成功`, {
    //   position: "top-left",
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
  }

  return res.data;
};
