import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Col } from 'react-bootstrap';
import Cross from './../../static/image/Esc.png';
// import { LineShare } from '../../icons/';
import classNames from 'classnames/bind';
import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';
import { LineShareButton } from 'react-share';
import { loginInfo } from '../../model';
import { toast } from 'react-toastify';
import { isAndroid } from 'mobile-device-detect';
import lineShare from '../../static/image/lineShare.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const cx = classNames.bind(style);

interface AleartModelProps {
  isOpen: boolean;
  handleClose: () => void;
  handleCloseAllModel?: () => void;
  type: 'income' | 'reset' | 'groupCreate' | 'groupJoin' | 'checkConfirm' | null;
  handleCheck: () => void;
  isLineShareModel?: boolean;
  teamData?: { teamName: string; teamCode: string; leaderName: string };
  loginInfo?: loginInfo;
  singleSubmitSuccess?: boolean;
  successName?: string | null;
  isChecking?: boolean;
}

const AleartModel: React.FC<AleartModelProps> = ({
  isOpen,
  handleClose,
  handleCloseAllModel,
  handleCheck,
  type,
  isLineShareModel,
  teamData,
  loginInfo,
  singleSubmitSuccess,
  successName,
  isChecking,
}) => {
  const title = ` \n ${teamData?.leaderName} 所屬的隊伍 ${teamData?.teamName} 邀請你一起參加【凱基股神大賽】。 \n 報名輸入隊伍代碼： ${teamData?.teamCode} ，比賽開始前完成線上開戶，獨享獎金翻倍！`;
  const shareUrl = `https://kgi-simulatedtrade.tw/#/home&text=${title}`;
  const copyBoardText = `${teamData?.leaderName} 所屬的隊伍 ${teamData?.teamName} 邀請你一起參加【凱基股神大賽】。 \n 請下載【隨身e策略】，點擊APP首頁【投資實驗室】，報名輸入隊伍代碼： ${teamData?.teamCode} ，比賽開始前完成線上開戶，凱基客戶獨享獎金翻倍！  \n https://kgi-simulatedtrade.tw/#/home`;
  const handleCheckClick = () => {
    handleCheck();
  };

  return (
    <>
      <Modal
        isOpen={isOpen && type !== null}
        contentLabel=""
        onRequestClose={handleClose}
        className={cx(
          !!(type === 'groupCreate' || type === 'groupJoin')
            ? 'TeamJoinModel'
            : `AlertModel`
        )}
        closeTimeoutMS={300}
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {isLineShareModel || singleSubmitSuccess ? (
          <>
            <div className={cx('darkHeader')}>
            <div className={cx('modalTitle')}>
            <h2>{successName ? `${successName}` : ''}</h2>
              </div>
              <div className={cx('modalRight')}>
                <button className={cx('modalCloseButton')} onClick={handleClose}>
                  <img width={35} height={35} src={Cross} alt="close" />
                </button>
              </div>
            </div>
            {teamData?.teamCode !== '' ? (
              <div>
                <div className="text-center mb-1" style={{ marginTop: '24px' }}>
                  建立成功
                </div>
                <div className="text-center mb-1">現在利用團體組代碼</div>
                <div className="text-center mb-1">邀請親朋好友加入隊伍！</div>
                <div className="my-4 text-center">
                  <span className={cx('teamCodeSection', 'mx-1')}>
                    {teamData?.teamCode}
                  </span>
                </div>
                <div className="text-center mt-2 d-flex align-items-center justify-content-center">
                  <CopyToClipboard
                    text={copyBoardText}
                    onCopy={() => {
                      toast.success(`已複製成功！`, {
                        position: 'top-right',
                        autoClose: 1300,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                    }}
                  >
                    <span className={cx('mx-1', 'copyShareButton')}>
                      複製團隊代碼
                    </span>
                  </CopyToClipboard>

                  <LineShareButton
                    className={cx('mx-1', 'lineShareButton')}
                    url={shareUrl}
                  >
                    <img
                      src={lineShare}
                      alt="lineShare"
                      style={{ height: '31px' }}
                    />
                  </LineShareButton>
                </div>
              </div>
            ) : (
              <div className={cx('mainfont', 'text-center', 'mt-5')}>
                報名成功
              </div>
            )}

            <div
              className={cx('text-center', 'mt-5')}
              style={{ marginBottom: '30px' }}
            >
              <span className={cx('return-btn')} onClick={handleCloseAllModel}>
                返回模擬交易首頁
              </span>
            </div>
          </>
        ) : !!(type === 'groupCreate' || type === 'groupJoin') ? (
          <>
            <div className={cx('darkHeader')}>
              <div className={cx('modalTitle')}>
                {type === 'groupCreate' ? '是否建立隊伍？' : '是否加入隊伍？'}
              </div>
              <div className={cx('modalRight')}>
                <button className={cx('modalCloseButton')} onClick={handleClose}>
                  <img width={35} height={35} src={Cross} />
                </button>
              </div>
            </div>
            <div
              className={cx(
                type === 'groupCreate' && 'contentWrap',
                'd-flex',
                'justify-content-center',
                'align-items-center'
              )}
            >
              <div className={cx('alertmodalContent')}>
                {type === 'groupCreate' && (
                  <Col
                    sm={10}
                    style={{ color: 'black' }}
                    className={cx(
                      'd-flex',
                      'justify-content-center',
                      'align-items-center'
                    )}
                  >
                    <div className={cx('text-danger', 'fw-bold', 'fs-4')}>
                      隊伍名稱及代碼申請後不可以修改！
                    </div>
                  </Col>
                )}
                {type === 'groupJoin' && (
                  <Col
                    sm={10}
                    style={{ color: 'black' }}
                    className={cx(
                      'd-flex',
                      'justify-content-center',
                      'align-items-center'
                    )}
                  >
                    <div
                      className={cx(
                        'my-3',
                        'text-center',
                        'lh-lg',
                        'fs-5',
                        'fw-bold'
                      )}
                    >
                      是否加入
                      <br />
                      <span className={cx('py-3', 'fs-4', 'team_name_color')}>
                        {teamData?.teamName}
                      </span>
                      <br />
                      的隊伍?
                    </div>
                  </Col>
                )}
              </div>
            </div>
            <div className={cx('alertmodelFoot')}>
              <div className={cx('button-group')}>
                <Col style={{ display: 'inline-grid' }}>
                  <Button className={cx('alert-button')} onClick={handleCheckClick}>
                    確定
                  </Button>
                </Col>
                <Col style={{ display: 'inline-grid' }}>
                  <Button
                    className={cx('alert-button-cancel')}
                    onClick={handleClose}
                  >
                    取消
                  </Button>
                </Col>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cx('alertmodelHearder')}>
              <div className={cx('modalTitle')}></div>
              <div className={cx('modalRight')}>
              {type !== 'checkConfirm' && (
            <button className={cx('modalCloseButton')} onClick={handleClose}>
              <img width={35} height={35} src={Cross} />
            </button>
               )}
              </div>
            </div>
            <div className={cx('contentWrap')}>
              <div className={cx('alertmodalContent', 'justify-content-center')}>
                {type === 'income' && (
                  <Col sm={10} style={{ color: 'black' }}>
                    <div className={cx('mainfont')}>是否入金200,000元？</div>
                    <div className={cx('subfont')}>(每日最多可入金一次)</div>
                  </Col>
                )}
                {type === 'checkConfirm' && (
                  <Col sm={10} style={{ color: 'black' }}>
                    <div className={cx('subfont' ,'text-center')}>請確認為本人身分證無誤<br/> 一經送出即不可修改!</div>
                  </Col>
                )}
                {type === 'reset' && (
                  <Col sm={10} style={{ color: '#CF1A1A' }}>
                    <div className={cx('mainfont', 'text-center')}>
                      清除交易紀錄
                      <br />
                      並恢復預設初始資金？
                    </div>
                  </Col>
                )}
              </div>
            </div>
            <div className={cx('alertmodelFoot')}>
              <div className={cx('button-group')}>
                <Col style={{ display: 'inline-grid' }}>
                <Button
                    className={cx('alert-button')}
                    onClick={() => {
                      handleCheckClick();
                    }}
                    disabled={type === 'checkConfirm' && isChecking}
                  >
                  {type === 'checkConfirm'
                  ? '確認'
                  : type === 'income'
                  ? '入金'
                  : type === 'reset'
                  ? '清除'
                  : ''}
                  </Button>
                </Col>
                <Col style={{ display: 'inline-grid' }}>
                  <Button
                    className={cx('alert-button-cancel')}
                    onClick={handleClose}
                    disabled={isChecking}
                  >
                    取消
                  </Button>
                </Col>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default AleartModel;
