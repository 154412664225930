import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface SearchWarrantsParameters {
  sym: string;
  rows?: number;
  page?: number;
}

export interface searchWarrantsRes {
  err: string;
  recs: number;
  pages: number;
  warrants: warrantsType[];
  QueryTime?: string;
}
export interface warrantsType {
  id: string;
  name: string;
}

export const searchWarrants = async (
  axios: AxiosInstance,
  { sym, rows, page }: SearchWarrantsParameters
) => {
  const requestData = JSON.stringify({
    sym,
    rows: 20,
    page: 0,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  function searchWarrantFormatter(data: any) {
    return {
      key: data.id,
      value: data.id,
      name: data.name,
    };
  }
  const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");

  try {
    const { data } = await axios.post<searchWarrantsRes>(
      "api2/Trade/Warrants",
      requestData,
      { headers }
    );

    if (!data || !data.warrants) {
      toast.warn(`搜尋權證${":(" + 9999 + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", warrants: null, QueryTime };
    }

    if (data.err !== "0000") {
      toast.warn(`搜尋權證${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return {
      err: data.err,
      recs: data.recs,
      pages: data.pages,
      warrants: data.warrants.map(searchWarrantFormatter),
      QueryTime: QueryTime,
    };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", warrants: null, QueryTime };
  }
};
