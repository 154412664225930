import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export interface OrderCancelParameters {
  cu_id: string;
  order_no: number;
  cancel_user_id: number;
}

interface OrderCancelResponse {
  err: string;
  message?: string;
}

export const orderCancel = async (
  axios: AxiosInstance,
  { cu_id, order_no, cancel_user_id }: OrderCancelParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
    order_no,
    cancel_user_id
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<OrderCancelResponse>(
      "api2/Trade/OrderCancel",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", favorites: null };
    }

    if (data.err !== "0000") {
      toast.warn(`刪單:${data.message + "(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return data;
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", favorites: null };
  }
};
