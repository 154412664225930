import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface FavoriteRemoveParameters {
  cu_id: string;
  stock_id: string;
}

interface FavoriteRemoveResponse {
  err: string;
  message?: string;
}

export const favoriteRemove = async (
  axios: AxiosInstance,
  { cu_id, stock_id }: FavoriteRemoveParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
    stock_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<FavoriteRemoveResponse>(
      "api2/Favorite/FavoriteRemove",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", message: "刪除失敗" };
    }
    if (data.err !== "0000") {
      toast.warn(`刪除自選${":(" + data.err + ")" + data.message}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    data.err === "0000" &&
      toast.success(`刪除自選成功:${"(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    return data;
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", message: "刪除失敗" };
  }
};
