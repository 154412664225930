import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface teamNameQueryPayload {
  cu_id: string;
  team_code: string;
}

export interface teamNameQueryResponse {
  err: string;
  message: string;
  data: any;
}

export const teamNameQuery = async (
  axios: AxiosInstance,
  payload: teamNameQueryPayload
) => {

  // const res = await axios.post<applyQueryResponse>(
  const res = await axios.post<teamNameQueryResponse>(
    "api2/Team/TeamNameQuery", payload
  );
  if (res.data.err !== "0000") {
    toast.error(
      `隊伍查詢失敗:${"(" + res.data.err + ")" + res.data.message}`,
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }



  return res.data;
};
