import { AxiosInstance } from "axios";

export interface userForgetPasswordPayloadType {
  email: string;
}
export interface userForgetPasswordType {
  code: number;
  msg: string;
}

export const userForgetPassword = async (axios: AxiosInstance, payload: userForgetPasswordPayloadType) => {
  // const { uid } = (window.parent as any).reactGetLoginInfo();
  // const uidstring = `?uid=${uid}`;
  const { data } = await axios.post<userForgetPasswordType>(
    `${process.env.REACT_APP_URL}api/user_forget.php`, payload
  );

  console.log(data)
  if (!data) {

  }
  return data;
};
