import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface verifyCheckParameters {
  cu_id: string | null;
}
interface verified {
  /*
     v_type：1=簡訊
  */
  v_type: string;
  status: boolean;
}
interface verifyCheckResponse {
  err: string;
  verified: verified[];
  message?: string;
  /*
    提醒認證彈窗(登入後)
  */
  w1: string;
  /*
    註冊成功訊息與提醒認證
  */
  w2: string;
  /*
    提醒認證彈窗(登入後)
  */
  m2: string;
}

export const verifyCheck = async (
  axios: AxiosInstance,
  { cu_id }: verifyCheckParameters
) => {
  if (cu_id === null || cu_id === "") {
    return;
  }
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const res = await axios.post<verifyCheckResponse>(
      "api2/Invitation/VerifyCheck",
      requestData,
      { headers }
    );

    if (!res.data) {
      return { err: "9999", verified: [], w1: "", w2: "", m2: "" };
    }
    if (res.data.err !== "0000" && res.data.err !== "8000") {
      toast.error(
        `查詢簡訊驗證失敗:${"(" + res.data.err + ")" + res.data.message}`,
        {
          position: "top-left",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
    return res.data;
  } catch (error) {
    return { err: "401", verified: [], w1: "", w2: "", m2: "" };
  }
};
