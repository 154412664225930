import { AxiosInstance } from "axios";

export interface fbUserJoinPayloadType {
  email: string;
  phone: string;
  name: string;
  fbname: string;
}
export interface fbUserJoinType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number
}

export const fbUserJoin = async (axios: AxiosInstance, payload: fbUserJoinPayloadType) => {

  const { data } = await axios.post<fbUserJoinType>(
    `${process.env.REACT_APP_URL}api/fb_join.php`, payload
  );

  console.log(data)
  if (!data) {

  }
  return data;
};
