import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface GetTaskResponse {
  err: string;
  user_task: user_task[];
  message?: string;
}
export interface UserTaskQueryParameters {
  cu_id: string | null;
}
export interface user_task {
  user_id: number;
  mStartDate: string;
  mEndDate: string;
  sysDate?: string;
  taskList: taskListType[];
}

export interface taskListType {
  taskId: string;
  taskName: string;
  finishedTime: string | null;
}


export const getTaskList = async (
  axios: AxiosInstance,
  { cu_id }: UserTaskQueryParameters) => {

  const requestData = JSON.stringify({
    cu_id,
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const response = await axios.post<GetTaskResponse>("api2/Warrant/GetUserTask", requestData,
      { headers });
    if (!response.data) {
      toast.error(`取任務清單Error:not found`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", user_task: [] };
    }
    if (response.data.err !== "0000") {
      toast.warn(`任務清單${"(" + response.data.message + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return response.data;
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", user_task: [] };
  }
};
