import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface invitationQueryParameters {
  cu_id: string | null;
}

interface invitationResponse {
  err: string;
  inv_code: string;
  message?: string;
}

export const invitationQuery = async (
  axios: AxiosInstance,
  { cu_id }: invitationQueryParameters
) => {
  if (cu_id === null) {
    return;
  }
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<invitationResponse>(
      "api2/Invitation/InvitationQuery",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", inv_code: "" };
    }
    if (data.err !== "0000" && data.err !== "9904") {
      console.log(data.message + "(" + data.err + ")");
      toast.warn(`查詢邀請碼${"(" + data.err + "):" + (data.message || "")}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", inv_code: "" };
  }
};
