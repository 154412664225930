export interface SessionIdMessage {
  FCODE: "session";
  SessionID: string;
  BaseClOrdId: string;
  Hostname: string;
}

export enum Mode {
  NEWORDER = 10,
  NEWORDERFAIL = 11,
  DELORDER = 20,
  DELORDERFAIL = 21,
  CHANGEORDER = 30,
  CHANGEORDERFAIL = 31,
  ORDERDEAL = 90,
}

export interface orderMessage {
  AVQ: number;
  BS1: string;
  CID: string;
  CIK: string;
  FUNC: number;
  ORN: string;
  ORS: number;
  ORT: string;
  P1: string;
  QTY: number;
  SYM: string;
  TIF: string;
}

export type OrderSocketMessage = SessionIdMessage;
