import { AxiosInstance } from "axios";

export interface bellCheckParameters {
  cu_id: string | null;
  t1: string;
  t2: string;
}

interface bellCheckResponse {
  err: string;
  bell?: boolean;
  message?: string;
}

export const bellCheck = async (
  axios: AxiosInstance,
  { cu_id, t1, t2 }: bellCheckParameters
) => {
  if (cu_id === null || !t1 || !t2) {
    return;
  }
  const requestData = JSON.stringify({
    cu_id,
    t1,
    t2,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const res = await axios.post<bellCheckResponse>(
      "api2/Trade/Bell",
      requestData,
      { headers }
    );

    if (!res.data) {
      return { err: "9999", bell: false };
    }
    return res.data;
  } catch (error) {
    return { err: "401", bell: false };
  }
};
