import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface GetWarrantQueryParameters {
  cu_id: string;
  sym: string;
  cp: string;
  p1: number;
  p2: number;
  d1: number;
  d2: number;
  r1: number;
  r2: number;
  pub: string;
  ep1: number;
  ep2: number;
  qty1: number;
  qty2: number;
  df1: number;
  df2: number;
  lv1: number;
  lv2: number;
  rows?: number;
  page: number;
  order: string;
  asc: boolean;
}

export interface WarrantQuery {
  err: string;
  pages: number;
  recs: number;
  warrant: WarrantQueryType[] | null;
  QueryTime?: string;
}
export interface WarrantQueryType {
  action?: any;
  stock_id: string;
  stock_name: string;
  stock_money: string;
  stock_buy: string;
  stock_sale: string;
  stock_percent: string;
  target_money: string;
  target_percent: string;
  run_money: number;
  run_num: number;
  run_percent: number;
  surplus_day: number;
  lever: number;
  volume: number;
  percent_buy: string;
  diff_ratio: number;
  target_name_1: string;
  target_name_2: string;
  publisher: string;
  favor: boolean;
  QueryTime?: string;
  target_upprice?: string;
  target_downprice?: string;
  ref_price: number;
}

export const getWarrantQuery = async (
  axios: AxiosInstance,
  {
    cu_id,
    sym,
    cp,
    p1,
    p2,
    d1,
    d2,
    r1,
    r2,
    pub,
    ep1,
    ep2,
    qty1,
    qty2,
    df1,
    df2,
    lv1,
    lv2,
    rows,
    page,
    order,
    asc,
  }: GetWarrantQueryParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
    sym,
    cp,
    p1: p1,
    p2: p2,
    d1: d1 === 9999 ? 0 : d1,
    d2: d2 === 9999 ? 0 : d2,
    r1: r1 === 9999 ? 0 : r1,
    r2: r2 === 9999 ? 0 : r2,
    pub,
    ep1: ep1 ? ep1 : 0,
    ep2: ep2 ? ep2 : 0,
    qty1: qty1 === 999999 ? 0 : qty1,
    qty2: qty2 === 999999 ? 0 : qty2,
    df1: df1 === 999999 ? 0 : df1,
    df2: df2 === 999999 ? 0 : df2,
    lv1: lv1 === 999999 ? 0 : lv1,
    lv2: lv2 === 999999 ? 0 : lv2,
    rows: rows || 8,
    page,
    order,
    asc,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  if (!sym && !cp) {
    return;
  }
  const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");
  try {
    const { data } = await axios.post<WarrantQuery>(
      "api2/Warrant/WarrantQuery",
      requestData,
      { headers }
    );
    if (!data) {
      toast.error(`權證查詢Error:not found`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", warrant: null, QueryTime, pages: 0, recs: 0 };
    }
    if (data.err !== "0000") {
      toast.warn(`權證查詢${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { QueryTime, ...data };
  } catch (error) {
    return { err: "9999", warrant: null, QueryTime, pages: 0, recs: 0 };
  }
};
