import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface GetLeaderBoardParameters {
  cu_id: string;
}

interface LeaderBoardResponse {
  err: string;
  code: string;
  rank_date: string;
  rank?: number;
  leaderboard: LeaderBoardType[] | null;
  message?: string;
}
export interface LeaderBoardType {
  /**
   * 投資報酬率
   */
  roi: number;

  /**
   * 投組淨值
   */
  nav: number;
  /**
   * 排名
   */
  ranking: number;
  /**
   * 上次排名
   */
  rank_prev: number;
  user_name: string;
}

function Formatter(data: LeaderBoardType): LeaderBoardType {
  const rankChange = Number(data.rank_prev - data.ranking);
  const markerWord =
    data.user_name.length >= 2
      ? data.user_name.substring(0, 1) + "Ｏ" + data.user_name.substring(2, 3)
      : data.user_name;
  return {
    roi: data.roi,
    nav: data.nav,
    ranking: data.ranking,
    rank_prev: data.rank_prev === 65535 ? 65535 : rankChange,
    user_name: data.user_name,
  };
}

export const getLeaderBoard = async (
  axios: AxiosInstance,
  { cu_id }: GetLeaderBoardParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<LeaderBoardResponse>(
      "api2/Warrant/LeaderBoard",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", code: "", leaderboard: null, rank_date: null };
    }
    if (!data.leaderboard) {
      if (data.err !== "0000") {
        toast.warn(`排行榜${":(" + data.err + ")" + data.message}`, {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    if (data.err !== "0000") {
      toast.warn(`排行榜${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    const takeFirstFifty = (leaderboard: LeaderBoardType[]) => {
      if (leaderboard.length > 50) {
        return leaderboard.slice(0, 50)
      }
      return leaderboard
    }

    return {
      err: data.err || "9999",
      code: data.code || "",
      rank: data.rank,
      leaderboard: data.leaderboard
        ? takeFirstFifty(data.leaderboard.map(Formatter).filter((x) => x.ranking !== 65535))
        : [],
      rank_date: data.rank_date
        ? dayjs(data.rank_date).format("YYYY/MM/DD")
        : "--",
    };
  } catch (error) {
    console.log("error", error);
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", code: "", leaderboard: null, rank_date: null };
  }
};
