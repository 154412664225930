import React from 'react';
import anime from 'animejs';
import { useUpdateEffect } from 'react-use';
import classnames from 'classnames/bind';

import styles from './index.module.css';

const cx = classnames.bind(styles);

export interface BottomBlinkerProps {
  compareValue: any;
}

const BottomBlinker: React.FC<
  BottomBlinkerProps & React.ComponentPropsWithoutRef<'div'>
> = ({ compareValue, className = '', children, ...rest }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useUpdateEffect(() => {
    anime({
      targets: ref.current,
      opacity: [
        { value: 1, duration: 500 },
        { value: 0, duration: 500 },
      ],
      easing: 'linear',
    });
  }, [compareValue]);
  return (
    <div className={cx('bottom-blinker', className)} {...rest}>
      <div>{children}</div>
      <div ref={ref} className={cx('blinker')}></div>
    </div>
  );
};

export default BottomBlinker;
