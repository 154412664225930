import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface applyQueryPayload {
  cu_id: string;
  uid ?: string;
}

export interface applyQueryResponse {
  err: string;
  message: string;
  data: any;
  kgi_gust:string;
}

export const applyQuery = async (
  axios: AxiosInstance,
  payload: applyQueryPayload
) => {

  // const res = await axios.post<applyQueryResponse>(
  const res = await axios.post<any>(
    "api2/Team/ApplyQuery", payload
  );



  return res.data;
};
