import { Decimal } from 'decimal.js';

import { ScaleItem } from '../model/productsTree';

export interface Options {
  max: number;
  min: number;
}

function scaleCalculator(
  current: number,
  isPlus: boolean,
  step: number,
  scaleItems: ScaleItem[],
  options: Options = {
    max: 99999999999,
    min: -99999999999,
  },
): number[] {
  if (scaleItems.length === 0 || step === 0) {
    return [];
  }

  const result: number[] = [];
  const max = new Decimal(options.max);
  const min = new Decimal(options.min);
  let prevValue = new Decimal(current);
  while (step > 0) {
    let index = getCurrentScaleItemIndex(
      prevValue.toNumber(),
      isPlus,
      scaleItems,
    );
    if (index === -1) {
      break;
    }

    const { Numerator, Denominator, MinMovement } = scaleItems[index];
    const tick = new Decimal(Numerator)
      .dividedBy(Denominator)
      .mul(MinMovement)
      .mul(isPlus ? 1 : -1);
    const newValue = prevValue.add(tick);
    if (newValue.isNaN() || newValue.gt(max) || newValue.lt(min)) {
      break;
    }

    result.push(newValue.toNumber());

    prevValue = newValue;
    step--;
  }

  return result;
}

function getCurrentScaleItemIndex(
  current: number,
  isPlus: boolean,
  scaleItems: ScaleItem[],
): number {
  let isNegative = current < 0;
  current = Math.abs(current);

  return scaleItems.findIndex(({ ScopeMin, ScopeMax }) => {
    const func = isPlus
      ? isNegative
        ? downer
        : upper
      : isNegative
        ? upper
        : downer;

    return func(current, ScopeMin, ScopeMax);
  });
}

function upper(current: number, scopeMin: number, scopeMax: number) {
  return (
    (current >= scopeMin || scopeMin === 0) &&
    (current < scopeMax || scopeMax === 0)
  );
}
function downer(current: number, scopeMin: number, scopeMax: number) {
  return (
    (current > scopeMin || scopeMin === 0) &&
    (current <= scopeMax || scopeMax === 0)
  );
}

function getClosest(scaleItems: ScaleItem[], isPlus: boolean, _price: number) {
  const index = getCurrentScaleItemIndex(_price, true, scaleItems);
  if (index < 0) {
    // return scaleItems[0].ScopeMin;
  }

  const { Numerator, Denominator, MinMovement } = scaleItems[index];
  const isNegative = _price < 0;
  const minStep = new Decimal(Numerator)
    .dividedBy(Denominator)
    .mul(MinMovement);
  const price = new Decimal(_price);
  const delta = price.mod(minStep);
  let base = price.sub(delta);

  // 有餘數的時候需要做處理
  if (!delta.equals(0)) {
    if (isNegative) {
      base = base.minus(minStep);
    }
    if (!isPlus) {
      base = base.plus(minStep);
    }
  }

  return base.toNumber();
}

function isValidNumber(scaleItems: ScaleItem[], price: number) {
  if (scaleItems.length === 0) {
    return false;
  }

  // /** 要將價格取絕對值再判斷屬於哪個檔差之間， 檔差表只有正數的 */
  const abs = Math.abs(price);
  let currentStepRange = scaleItems.find((each) => {
    return (
      abs >= each.ScopeMin && (abs <= each.ScopeMax || each.ScopeMax === 0)
    );
  });

  if (currentStepRange === undefined) {
    return false;
  }

  const minStep = new Decimal(currentStepRange.Numerator)
    .dividedBy(currentStepRange.Denominator)
    .mul(currentStepRange.MinMovement);

  return new Decimal(price).mod(minStep).eq(0);
}

export { scaleCalculator, getCurrentScaleItemIndex, isValidNumber, getClosest };
