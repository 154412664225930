import { AxiosInstance } from "axios";
// import getEnv from 'tools/getEnv';

export interface RegisterResponse {
  result: "success" | "fail";
}

export type RegisterAvailableTypes =
  | "Tick"
  | "KLine"
  | "BA"
  | "1BA"
  | "UE"
  | "BNS";

export interface RegisterParameters {
  symbols: string[] | string;
  types: RegisterAvailableTypes[];
  masterSessionId: string;
  others?: { [k: string]: string };
}

export const register = async (
  axios: AxiosInstance,
  { symbols, types, masterSessionId, others }: RegisterParameters
) => {
  const baseRequestData = {
    Prods: Array.isArray(symbols) ? symbols : [symbols],
    Types: types,
    ...others,
  };
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const results = await Promise.all([
    axios.post<RegisterResponse>(
      `/api/reg`,
      JSON.stringify({ SessionId: masterSessionId, ...baseRequestData }),
      {
        baseURL: `${process.env.REACT_APP_URL}wa`,
        headers,
        withCredentials: true,
      }
    ),
  ]);

  return results.every(({ data: { result } }) => result === "success");
};

export default register;
