import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface campaignResetParameters {
  code: string;
}

interface campaignResetResponse {
  err: string;
  code: string;
  message?: string;
}

export const campaignReset = async (
  axios: AxiosInstance,
  { code }: campaignResetParameters
) => {
  const requestData = JSON.stringify({
    code,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<campaignResetResponse>(
      "api2/Warrant/CampaignReset",
      requestData,
      { headers }
    );
    if (!data) {
      toast.error(`資金重設失敗:${"(" + "9999" + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", message: "資金重設失敗!" };
    }
    if (!data || data.err !== "0000") {
      toast.error(`資金重設失敗:${"(" + data.err + ")" + data.message}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.success(`資金重設成功:${"(" + data.err + ")" + data.message}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", message: "資金重設失敗!" };
  }
};
