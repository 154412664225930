import { AxiosInstance } from "axios";
import { toast } from "react-toastify";



export interface schoolListResponse {
  err: string;
  message: string;
  data: schoolData[];
}
export interface schoolData {
  value: number;
  name: string;
  key: number;
}

export const getSchoolList = async (
  axios: AxiosInstance,
) => {

  const res = await axios.post<schoolListResponse>(
    "api2/Warrant/SchoolList",

  );
  function Formatter(data: any) {
    return {
      name: data.name,
      value: data.id,
      key: data.id,
    };
  }

  // if (res.data.err !== "0000") {
  //   toast.error(
  //     `學校列表取得失敗:${"(" + res.data.err + ")" + res.data.message}`,
  //     {
  //       position: "top-left",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     }
  //   );
  // }

  return { ...res.data, data: res.data.data.map(Formatter) };
};
