import React from 'react';
import { Container, Badge } from 'react-bootstrap';
import { Column } from 'react-table';
import { Table } from '../../components/DataTable';

import { useAsyncRetry } from 'react-use';
import api from '../../api';
import { ProfitRPLType } from '../../api/newApi/getProfit';
import classNames from 'classnames/bind';

import style from './index.module.css';

const cx = classNames.bind(style);

export interface Profited {
  cuid: string;
  campaignName: string | null;
}

const numberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
const Digits2NumberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
function useProfitInfo(cuid: string, retryToggle: boolean) {
  const { loading, value, retry } = useAsyncRetry(
    () => api.getProfitQuery({ cu_id: cuid }),
    [cuid, retryToggle]
  );
  const profit = React.useMemo(() => {
    return value?.rpl;
  }, [loading]);
  const QueryTime = React.useMemo(() => {
    return value?.QueryTime ? value?.QueryTime : '';
  }, [loading]);

  return {
    retry,
    loading,
    value,
    profit,
    QueryTime,
  };
}
const Profited: React.FC<Profited> = ({ cuid, campaignName }) => {
  const [retryToggle, setRetryToggle] = React.useState(false);
  const {
    loading: ProfitLoading,
    value,
    QueryTime,
    retry,
  } = useProfitInfo(cuid, retryToggle);
  // React.useEffect(() => { (window.parent as any).resizeIframe() }, [value])
  const columns = React.useMemo<Column<ProfitRPLType>[]>(
    () => [
      {
        Header: '成交時間',
        accessor: 'st',
        sticky: 'left',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            <div>
              {cellInfo.cell.row.original.st}
              <br />
              {cellInfo.cell.row.original.stime}
            </div>
          </>
        ),
      },
      {
        Header: '商品',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: '商品代號',
        accessor: 'sym',
        disableSortBy: true,
      },
      {
        Header: '股數',
        accessor: 'q',
        disableSortBy: true,
      },
      {
        Header: '成交價',
        accessor: 'p',
        disableSortBy: true,
      },
      {
        Header: '帳面收入',
        accessor: 'sa',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(
                  Math.round(cellInfo.cell.row.original.sa).toString()
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: '投資成本',
        accessor: 'bc',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(
                  Math.round(cellInfo.cell.row.original.bc).toString()
                )}
              </div>
            }
          </>
        ),
      },

      {
        Header: '已實現損益',
        accessor: 'pf',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  cellInfo.cell.row.original.pf > 0
                    ? 'red'
                    : cellInfo.cell.row.original.pf < 0
                    ? 'green'
                    : ''
                )}
              >
                {numberFormatter(
                  Math.round(cellInfo.cell.row.original.pf).toString()
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: '報酬率(%)',
        accessor: 'roi',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  cellInfo.cell.row.original.roi > 0
                    ? 'red'
                    : cellInfo.cell.row.original.roi < 0
                    ? 'green'
                    : ''
                )}
              >
                {Digits2NumberFormatter(
                  cellInfo.cell.row.original.roi.toString()
                )}
              </div>
            }
          </>
        ),
      },
      // {
      //   Header: '手續費',
      //   accessor: 'f',
      //   disableSortBy: true,
      //   Cell: (cellInfo) => (
      //     <>
      //       {
      //         <div>
      //           {numberFormatter(
      //             Math.round(cellInfo.cell.row.original.f).toString()
      //           )}
      //         </div>
      //       }
      //     </>
      //   ),
      // },
      // {
      //   Header: '稅',
      //   accessor: 't',
      //   disableSortBy: true,
      //   Cell: (cellInfo) => (
      //     <>
      //       {
      //         <div>
      //           {numberFormatter(
      //             Math.round(cellInfo.cell.row.original.t).toString()
      //           )}
      //         </div>
      //       }
      //     </>
      //   ),
      // },
    ],
    []
  );
  return (
    <Container className={cx('container-left')} fluid="xl">
      <div className={cx('text-color', ' container-left', 'my-2')}>
        {' '}
        已實現損益清單{' '}
        <Badge className={cx('badge-box')}>{value?.rpl.length || 0}筆</Badge>
      </div>
      <div className={cx('updateTime')}>更新時間：{QueryTime || ''}</div>
      <Table
        columns={columns}
        data={value?.rpl || []}
        loading={ProfitLoading}
        pagination={true}
      />
      <div className={cx('text-secondary', 'mt-2', 'mb-3', 'small')}>
        <div className="my-2">Tip:</div>
        <br />
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">投資成本（含手續費、稅）</div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">帳面收入（含手續費、稅）</div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">
            手續費（含買+賣方）：買入金額*0.1425%(未滿20元按20元計收)+賣出金額
            *0.1425%(未滿20元按20元計收)
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">稅：賣出金額 *交易稅</div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">損益：帳面收入-投資成本　</div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◎</div>
          <div className="flex-grow-1">報酬率(%) ：損益/投資成本</div>
        </div>
      </div>
    </Container>
  );
};

export default Profited;
