import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as Icons from '../../icons';
import classNames from 'classnames/bind';

import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';

const cx = classNames.bind(style);

interface CampaignQAProps {
  isOpen: boolean;
  handleClose: () => void;
  type: 1 | 2 | 3; //1活動辦法 2常見問題 3公告
}

const CampaignQAModel: React.FC<CampaignQAProps> = ({
  isOpen,
  handleClose,
  type,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel=""
      onRequestClose={handleClose}
      className=""
      overlayClassName="CampaignSubmitModelOverlayWhite"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={cx('d-flex', 'flex-column', 'flex-overlay')}>
        <div className={cx('mainModalTitle')}>
          <div className={cx('modalTitle')}>
            {type === 1 ? '活動辦法' : type === 2 ? '常見問題' : '公告'}
          </div>
          <div
            className={cx('modalLeft')}
            onClick={() => {
              handleClose();
            }}
          >
            <Icons.ArrowLeft fill={''} width={30} height={30} />
          </div>
        </div>
        {/* 活動辦法 */}
        {type === 1 ? (
          <div className={cx('px-4', 'pt-2', 'submitContent')}>
            <h4>一、活動目的</h4>
            <p>凱基證券(下稱本公司)與中華郵政公司(下稱中華郵政)舉辦之模擬交易競賽
              均不收費，目的為金融教育之友善服務，鼓勵投資人透過模擬交易競賽增
              進股票交易知識並認識風險，活動期間提供之模擬金僅供活動期間使用，
              不得折換現金，模擬交易競賽結束後，活動期間所產生之模擬金及帳務將
              全數歸零，與實際交易無涉。</p>
            <h4>
              二、參加對象            </h4>
            <p>1.限本國籍年滿18歲之中華郵政存簿儲金儲戶。</p>
            <p>2.每人限參加一次，嚴禁一人使用多組帳號參加競賽。</p>
            <p>3.限使用手動交易，嚴禁使用程式進行下單交易。</p>

            <h4>
              三、獎項說明
            </h4>
            <p> 1. 凱基證券新客戶參加獎
              凱基證券新客戶獨享參加獎，凡活動期間透過下方指定連結新開立本公司
              台股帳戶及分戶帳(e財庫)之新客戶，下載隨身e策略APP，選擇模擬交易
              ，註冊模擬交易或報名參賽並完成5筆模擬交易，即可獲300元電子禮券
              。(<a href="https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401" target="_blank" rel="noopener noreferrer">https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401</a>)
            </p>
            <p>
              2.分享獎
            </p>
            <p>
              本次競賽期間分享50字以上心得，例如選股心得、參賽心得、投資風險體
              驗心得，email至主辧單位<a href="mailto:bmk@kgi.com">bmk@kgi.com</a>，活動結束後一周將由主辧單位擇
              優挑選20 位，每位獲贈1,000點i郵購點數。
            </p>
            <p>
              3.競賽獎
            </p>
            <table border={1} style={{ borderCollapse: "collapse" }}>
              <tr>
                <td style={{ border: "1px solid black" }}>名次</td>
                <td style={{ border: "1px solid black" }}>每位獎勵</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>第1名</td>
                <td style={{ border: "1px solid black" }}>20,000點 i郵購點數</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>第2名</td>
                <td style={{ border: "1px solid black" }}>15,000 點i郵購點數</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>第3名</td>
                <td style={{ border: "1px solid black" }}>10,000 點i郵購點數</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>第4~5名</td>
                <td style={{ border: "1px solid black" }}>5,000 點i郵購點數</td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black" }}>第6~10名</td>
                <td style={{ border: "1px solid black" }}>3,000 點i郵購點數</td>
              </tr>
            </table>

            <p>排名方式：名次依序按照“獲利金額”排名由大到小，結算時間為2024年10
              月2日13:30，若有兩人以上參賽者獲利金額相同，依序比較報酬率、投組
              淨值、交易筆數較多者為獲勝，上述獲利金額、報酬率、淨值由模擬交易
              平台計算為準。</p>
            <p>
              4.獲獎通知
            </p>
            <p>
              主辧單位將依報名時填寫之email通知得獎人，若報名時填寫錯誤email導
              致無法領獎，主辦單位恕不負責且不予以補寄。得獎者請於2024.10.20前
              ，依通知函所需資料回覆領獎資訊，逾期視為放棄得獎。
            </p>
            <p>
              5.獎項發放
            </p>
            <p>
              a.競賽獎、分享獎：贈獎內容為「i郵購紅利購物金點數」。1點=1元，可
              買商品、禮券、餐券喲！發送說明如下：
            </p>
            <li>將於2024.11.30前，依得獎者於活動報名時提供之身分證字號，以行
              動郵局APP推播發送紅利點數序號至行動郵局APP通知收件夾-優惠
              訊息區內。</li>
            <li>
              得獎者須於得獎名單公布後，申請網路郵局使用者代號、網路密碼及
              下載、登入行動郵局APP，並開啟優惠訊息推播通知。(無開啟行動郵
              局APP推播通知者，恕無法領取競賽獎或分享獎之贈獎)。
            </li>
            <br></br>
            <p>b.參加獎：贈獎內容為300元「宜睿智慧電子禮券」，將於2024.11.30前
              ，發送兌換連結及序號至得獎者於註冊模擬交易時提供之email。</p>
            <h4>四、競賽計算</h4>
            <p>1.投資標的及投資組合</p>
            <p>a.投資標的：國內上市櫃公司股票（含ETF、證券，不含興櫃）。</p>
            <p>b. 初始模擬金：報名截止日前具備凱基證券客戶身分(含新舊戶)即享初始模
              擬金加碼，競賽模擬金為1,000萬元；非凱基證券客戶初始模擬金為100
              萬元。</p>
            <p>c. 最小交易單位：以1張(1000股)為交易單位，不含零股交易。</p>
            <p>d. 競賽規則：須至少購買3檔(含)以上標的，不足3檔參賽者成績將不計入最
              終競賽排名。</p>
            <p>2. 交易計算：
            </p>
            <p>a. 股票交易均計算『交易成本』，交易成本包含買進手續費、賣出手續費及
              交易稅。
            </p>
            <p>b. 手續費(買賣皆收)：以買入價及賣出價之0.1425%計算，未滿20元按20元
              計收。
            </p>
            <p>c. 交易稅：台股現股交易以賣出價之0.3%計算；現股當沖交易以賣出價之
              0.15%計算；ETF現股/當沖交易以賣出價之0.1%計算。
            </p>
            <p>d. 帳面收入(含手續費、稅)：加總每筆庫存〔(現價×庫存張數×1000) - (現價
              ×庫存張數×1000×手續費率) - (現價×庫存張數×1000×交易稅率)〕
            </p>
            <p>e. 庫存
            </p>
            <li>成交均價(不含手續費、稅)：加總每筆(買入價或賣出價 × 庫存張數) / 庫
              存股數。
            </li>
            <li>交易成本(包含稅/費)：
            </li>
            <li style={{ listStyleType: "circle" }}>買入：(買入價 - 賣出價) × 庫存張數 × 1000 × 0.1425% + 買入價 × 庫存張數 × 1000 × 0.3%先買後賣：加總每筆(買入價×庫存張數×1000) + (買入價×庫存張數
              ×1000×手續費率)。
            </li>
            <li style={{ listStyleType: "circle" }}>先賣後買：加總每筆(賣出價×庫存張數×1000)－( 賣出價×庫存張數
              ×1000×手續費率) - (賣出價×庫存張數×1000×交易稅率)。
            </li>
            <li>
              帳面收入(元)：(現價× 庫存張數 × 1000 ) - (現價× 庫存張數 × 1000 × 手
              續費率) - (現價× 庫存張數 × 1000 × 交易稅率)。
            </li>
            <p>f. 損益
            </p>
            <li>
              先買後賣：帳面收入(元) – 交易成本。
            </li>
            <li>
              先賣後買：交易成本 - 帳面收入(元)。
            </li>
            <li>
              損益率(%)：損益/交易成本(包含稅/費)。
            </li>
            <li>
              現先賣均價：加總每筆(賣出價×賣出張數+賣出手續費+當沖賣出交易稅) /
              庫存股數。
            </li>
            <p>g. 除權息成本調整：當發生股票除權時，配發的股票股利成本會以0元計算
              ，除息則是在除權息日收盤後，隔日開盤前將配息匯入模擬交易帳號。
            </p>


            <p>3. 交易說明：</p>
            <p>a. 下單金額限制：當日下單金額(含尚未成交的在途下單)不得超過現金資產
              總額。</p>
            <p>b. 灰色鍵：模擬交易未提供的功能以灰色呈現，更完整功能歡迎開戶體驗。</p>
            <p>c. 交易時間：周一~周五證交所公告之交易日，委託時間 8:30~13:30，撮合
              時間 9:00~13:30。
            </p>
            <p>d. 現股當沖：系統預設開放此交易資格，當日買進且當天收盤前賣掉，視為
              現股當沖，若當日買進未於收盤前賣掉，系統將逕行沖銷，若現股先賣出
              須按［現先賣］且收盤前須買進沖掉。
            </p>
            <p>e. 信用當沖：系統不提供融資/融券之信用當沖。
            </p>
            <p>f. 資產總計
            </p>
            <li>淨資產：現金資產 + 帳面收入(*每日盤後15:30批次作業後將與「排行榜
              的投組淨值」一致)。</li>
            <li>損益：淨資產-初始資金。</li>
            <li>
              獲利金額：競賽期間買進並賣出後的盈虧金額之總計。
            </li>
            <li>
              報酬率(%)：盤中「現價」計算；盤後批次作業後會與「排行榜的報酬率
              」一致。
            </li>
            <br />
            <h4>五、注意事項
            </h4>
            <p>1.每位參賽者，僅能申請一組交易帳號。若重複報名參賽，主辦單位得取
              消其參賽資格。
            </p>
            <p>2.參賽者應自行交易，不可委託他人代為操作交易，不可透過程式進行交
              易，經查違反規定者將取消參賽資格。
            </p>
            <p>3.參賽者需為中華郵政存簿儲金儲戶，將以競賽報名提供的身分證字號及
              存簿局帳號(局號7位，帳號7位)核對身分。若經發現不符資格，主辦單位
              得取消其參賽資格。
            </p>
            <p>4.i郵購紅利購物金點數(以下簡稱購物金)：
            </p>
            <p>a.「購物金」為中華郵政附條件、附期限贈予ｉ郵購會員之虛擬點數。購
              物金並非會員之資產，會員不得要求將購物金轉讓予其它會員或任何第三
              人，亦不得要求將不同會員帳號之點數併入一個帳號中。此外，ｉ郵購會
              員不得要求中華郵政將購物金折算為現金，亦不得要求給予非由中華郵政
              主動提供之回饋項目或以其他方式為給付。
            </p>
            <p>b.購物金限ｉ郵購會員於ｉ郵購消費使用。無ｉ郵購會員者，需先註冊加入
              會員。購物金1點可折抵購物金額新臺幣1元；本次活動贈送之購物金自行
              動郵局APP推播發送購物金之日起使用效期為1年。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)每次訂單沒有使用最少點數之限制。ｉ郵購會員可以購物金折抵訂單
              商品價額(運費無法折抵)，再以其他付款方式支付差價購買，當結帳進行
              時，付款方式頁面可輸入扣抵之購物金金額；如果ｉ郵購會員之可用購物
              金餘額不足支付全部訂單總額，ｉ郵購會員仍需選擇任何一種付款方式來
              支付剩餘金額。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)ｉ郵購會員確認訂單後，ｉ郵購會員使用之購物金將會立刻自ｉ郵購
              會員之購物金帳戶裡扣除，同時也會更新ｉ郵購會員之購物金帳戶可用餘
              額。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)以購物金購買之商品，均無法再給ｉ郵購會員回饋點數，只有在扣除
              購物金點數外之消費才能再計算回饋點數。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)使用購物金換購商品時，每次交易最高可折抵該筆訂單商品總金額之
              100%。i郵購各店家並將就實際付款之金額（不含購物金折抵之部分）直
              接開立統一發票或收據予ｉ郵購會員。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e)若因取消訂購或其他事由退貨並退還款項者，i郵購將扣除會員因該
              次消費所獲得之購物金；如該次消費有使用購物金折抵產品價額，亦將回
              復該購物金。</p>
            <p>c. i郵購會員因使用帳號違反「i郵購會員同意條款」或違反中華民國法律
              而喪失參與本活動之資格時，中華郵政將取消該會員累積之所有購物金。</p>
            <p>d. i郵購會員以線上購物所累積之購物金換購商品，依相關稅法規定，並不
              屬於會員之所得，中華郵政將不代會員扣取任何稅款。</p>
            <p>e.其他注意事項請參考i郵購網站有關購物金使用說明。</p>
            <p>5.得獎者應遵守以下事項，否則視同放棄獲獎資格：(1)參加獎得獎者自主
  辦單位以email通知後，七日(日曆日)內必須回覆。(2)競賽獎及分享獎之
  得獎者，須於收到主辦單位email通知七日(日曆日)內完成登入行動郵局
  APP，並開啟優惠訊息推播通知，方得領取贈獎獎品。(3)參賽者如需參賽
  證明，請email至主辦單位 <a href="mailto:bmk@kgi.com">bmk@kgi.com</a> ，提供姓名、所屬單位/學校系
  級，主辦單位將提供電子檔之參賽證明。
</p>
            <p>6.參賽者於參加本活動之同時，視為同意接受並遵守本活動之活動辦法、
              模擬交易競賽規則及注意事項等規範，如有違反，主辦單位得取消其參賽
              資格及獲獎權利。</p>
            <p>7.模擬交易平台所提供的股市報價資訊，雖依據實體台股市場之報價，但
              不能做為實體交易市場實際買賣的依據，請勿將此平台作為報價系統參考
              ，主辦單位所提供之模擬交易環境，僅為提供使用者模擬交易認識風險、
              瞭解操作模式，所有使用者模擬帳戶成交紀錄，均為虛擬資料，與實際金
              融交易市場無關。參賽者若於實體交易市場進行相同或類似投資行為，應
              自行判斷風險，概與主辦單位及模擬帳號、模擬交易無關。參賽者參加本
              活動及模擬帳號操作交易結果，不得以任何理由，向主辦單位及主辦單位
              之母公司、子公司、關係企業、員工等主張任何法律責任。參賽者如已開
              立台股帳戶，請留意正式環境與模擬交易環境之差異，模擬交易設有”模擬
              交易”浮水印，買進時交易畫面底色為紅色，賣出時間交易畫面底色為綠色
              。</p>
            <p>8.若有下述異常情況發生，以致影響用戶排名，主辦單位概不負責，服務
              系統可能暫停該帳戶交易，並建議重新開始：(1)因本次活動在網際網路上
              進行，各地網路速度品質不一、用戶端電腦情況不一，有可能造成交易指
              令不完整，因而影響帳戶交易計算及紀錄。(2)若有多人共用同一台單機，
              造成帳戶交易紀錄交錯，進而影響交易及結算。</p>
            <p>9.參加獎得獎者須開立主辦單位台股帳戶及分戶帳(e財庫)始符合領獎資格
              。</p>
            <p>10.依中華民國稅法規定，本活動獎項之得獎者如為中華民國境內居住之個
              人，每人每年度累積取得贈品之價值超過新台幣1,000元者，主辦單位將
              依規定開立扣繳憑單列單申報該主管稽徵機關，併入其所得額中計算綜合
              所得稅。若得獎者為在中華民國境內居住之個人，獎品價值總額超過新台
              幣2萬元，必須代扣10%中獎獎金稅額；得獎者若非為在中華民國境內居
              住之個人，不論得獎者所得之金額，須就中獎所得扣繳20%機會中獎獎金
              稅額。得獎者必須提供身分證明文件作為主辦單位代扣繳報稅之用，若得
              獎者經主辦單位通知拒絕繳納代扣稅額，視為得獎者放棄得獎權益。</p>
            <p>11.參賽者需同意主辦單位使用其姓名、相片、交易資料及心得分享等權利
              。</p>
            <p>12.除法律另有規定，參加本活動留下個人基本資料者，可就其個人資料依
              法行使下列權利：查詢或請求閱覽、請求製給複製本、請求補充或更正、
              請求停止蒐集、處理或利用、請求刪除、請求處理限制、請求資料可攜性
              、拒絕自動化剖析、拒絕直接行銷目的之個人資料處理。</p>
            <p>13.主辦單位保留隨時變更活動辦法、獎項內容，以及暫停、延長、提前終
              止本活動之權利，本活動辦法若未盡詳細事宜，並得隨時修訂公佈，修改
              訊息將於本網站上公佈，不另行通知。主辦單位就活動內容、獎項內容得
              依實際狀況及較佳效果斟酌調整，並保有對於活動規則之解釋權利。</p>


          </div>

          // 常見問題
        ) : type === 2 ? (
          <div className={cx('px-4', 'pt-2', 'submitContent')}>
            <p className="">1. 請問參賽需要費用嗎?</p>
            <p>
            凱基證券與中華郵政舉辦之模擬交易競賽，均為免費提供之服務，目的為
            推廣金融教育，鼓勵投資人透過模擬交易增進股票交易知識並認識風險。
            </p>
            <p>
              2. 請問模擬交易競賽是否有提供操作資金 ?
            </p>
            <p>
            如在本次模擬交易競賽報名截止日前具備凱基證券客戶身分，即享初始模
擬金加碼，競賽模擬金為1,000萬元；非凱基證券客戶初始模擬金為100萬
元。
            </p>
            <p>
            3.模擬交易時庫存有金額，為何無法買入股票?
            </p>
            <p>當日下單金額不得超過虛擬現金資產總額(含尚未成交的在途下單)，例如
庫存仍有400萬元，該次下單350萬元，若平台回饋”餘額不足”，請檢視是
否有尚未成交的在途下單。</p>
            <p>4. 請問可當沖交易嗎?</p>
            <p>系統預設開放現股當沖交易資格，當日買進且當天收盤前賣掉，視為現股
當沖；若現股先賣出須按［現先賣］且收盤前須買進沖掉。若當日買進未
於收盤前賣掉，系統將逕行沖銷，使用該項功能請務必自行評估是否影響
個人操作績效。系統不提供融資/融券之信用當沖交易。</p>
            <p>5.競賽參加獎是每個人都可以獲得嗎?</p>
            <p>只要於活動期間新開立凱基證券台股帳戶及分戶帳(e財庫)之新客戶，下載
隨身e策略APP，選擇模擬交易，註冊後報名參賽並完成5筆模擬交易，不
論獲利多寡，均可獲得300元獎金(宜睿智慧電子禮券)，將於2024.11.30
前發送兌換連結及序號至得獎者於註冊模擬交易時提供之email。</p>
            <p>6. 請問參加獎中的全新開立本公司台股帳戶的定義是什麼?</p>
            <p>A：新戶定義為本次活動新開立本公司台股帳戶前，未曾於本公司開立過任何
              台股帳戶。</p>
            <p>7. 競賽分享獎如何可以獲得?</p>
           
            <p>競賽分享獎，只要於競賽期間回饋50字以上的參賽心得，舉凡選股過程、
操作流程、投資風險體驗等，email至主辧單位  <a href="mailto:bmk@kgi.com">bmk@kgi.com</a>，活動結束
一周後將由主辧單位擇優挑選20 位，每位獲贈1,000點「i郵購紅利購物金
點數」。</p>

            <p>8. 我符合得獎資格，請問如何通知我?</p>
            <p>主辦單位將以註冊模擬交易時所提供的email聯繫得獎人，註冊時請留意
            所提供的email 是否填寫正確，是否為經常使用之電子信箱。</p>
            <p>9.如何領取競賽獎或分享獎之i郵購紅利購物金點數?</p>
            <p>(一)將於2024.11.30前，依得獎者於活動報名時提供之身分證字號，以行
動郵局APP推播發送紅利點數序號至行動郵局APP通知收件夾-優惠訊息區
內。</p>
<p>(二)得獎者須於得獎名單公布後，申請網路郵局使用者代號、網路密碼及
下載、登入行動郵局APP，並開啟優惠訊息推播通知(無開啟行動郵局APP
推播通知者，恕無法領取競賽獎或分享獎之贈獎)。</p>
<p>10. 哪裡可以使用i郵購紅利購物金點數?有使用期限嗎?</p>
<p>請註冊並登入i郵購會員<a href='https://www.postmall.com.tw/index.aspx'>https://www.postmall.com.tw/index.aspx</a> 
點選「會員中心」&gt;點選「登錄紅利點數」&gt;點選「紅利兌領」，輸入「i郵
購紅利購物金序號」、「驗證碼」後點選「登錄紅利」，即可於i郵購消費
時使用。本次活動贈送之購物金自行動郵局APP推播發送購物金之日起使
用效期為1年。</p>
          </div>
        ) : (
          <div className={cx('px-4', 'pt-2', 'submitContent')}>
            <p>1.test</p>
            <p>2.test</p>
            <p>3.test</p>
            <p>4.test</p>
            <p>5.test</p>

          </div>
        )}
      </div>
    </Modal>
  );
};

export default CampaignQAModel;
