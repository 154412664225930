import React from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';
import { REGEX_PASSWORD, REGEX_EMAIL } from '../../constants';
import api from '../../api';
import { toast } from 'react-toastify';
import { isDisabled } from '@testing-library/user-event/dist/utils';
const cx = classNames.bind(style);

interface PasswordChangeProps {
  isOpen: boolean;
  handleClose: () => void;
  type: string; //type forget 忘記密碼 expire 密碼到期 change 變更密碼 finish 變更完成
  cuid?: string;
  email?: string;
  setPasswordChangeType: (type: string) => void;
  openModel: () => void;
  noticeInfo: any;
  showNoticeModel: () => void;
  setNoticeInfo: (e: any) => void;
  forgetPasswordUserData: any;
}

const PasswordChangeModel: React.FC<PasswordChangeProps> = ({
  isOpen,
  handleClose,
  type,
  cuid,
  email,
  setPasswordChangeType,
  openModel,
  noticeInfo,
  showNoticeModel,
  setNoticeInfo,
  forgetPasswordUserData,
}) => {
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [resetEmail, setResetEmail] = React.useState('');
  const [btnDisable, setBtnDisable] = React.useState(false);
  const [sumbitError, setSumbitError] = React.useState<string[]>([]);
  const errMsg = React.useRef({
    differentPassword: '*兩次密碼不相符',
    passwordLength: '*密碼格式錯誤',
    wrongPassword: '*密碼格式錯誤',
  });
  const [errorMsg, setErrorMsg] = React.useState('');
  const resetInput = () => {
    setPassword('');
    setPassword2('');
    setResetEmail('');
    setSumbitError([]);
  };
  React.useEffect(() => {
    if (btnDisable) {
      setTimeout(() => {
        setBtnDisable(false);
      }, 3000);
    }
  }, [btnDisable]);
  const emailReset = async () => {
    if (!REGEX_EMAIL.test(resetEmail)) {
      document.querySelector('#resetEmail')?.classList.add('errorInput');
      setErrorMsg('請輸入正確的Email格式');
      return;
    } else {
      setBtnDisable(true);
      const res = await api.userForgetPassword({ email: resetEmail });
      if (res.code === 0) {
        handleClose();
        setNoticeInfo({
          title: '發送密碼成功',
          content: `已發送重設密碼連結至註冊信箱，請開啟信件前往設定新密碼`,
        });
        showNoticeModel();
        resetInput();
      } else {
        setBtnDisable(false);
        toast.error(`信件寄送失敗${':(' + res.code + ')' + res.msg}`, {
          position: 'top-right',
          autoClose: 1300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  const handleCheck = async () => {
    if (sumbitError.length > 0) {
      return;
    } else {
      if (forgetPasswordUserData.email && forgetPasswordUserData.code) {
        const data = {
          code: forgetPasswordUserData.code,
          password: password,
          email: forgetPasswordUserData.email,
        };
        const res = await api.userReset(data);
        if (res.code === 0) {
          window.history.pushState(
            '',
            document.title,
            window.location.pathname + window.location.search
          );
          resetInput();
          handleClose();
          setPasswordChangeType('finish');
          openModel();
        } else {
          toast.error(`變更密碼失敗${':(' + res.code + ')' + res.msg}`, {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        console.log(res);
      } else {
        const data = {
          cuid: cuid as string,
          password: window.btoa(password),
        };
        const res = await api.changePassword(data);
        if (res.err === '0000') {
          resetInput();
          handleClose();
          setPasswordChangeType('finish');
          openModel();
        }
        console.log(res);
      }
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        resetInput();
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        className={cx('popup_header')}
      >
        <Modal.Title className={cx('w-100', 'text-center', 'text-white')}>
          {type === 'finish'
            ? '修改密碼完成'
            : type === 'forget'
            ? '忘記密碼'
            : '變更密碼'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={cx('modal-body')}>
          {type === 'forget' ? (
            <>
              <p>請輸入註冊信箱</p>
              <input
                placeholder="Email"
                id="resetEmail"
                className={cx('w-100', 'form-control')}
                value={resetEmail}
                onChange={(e) => {
                  setErrorMsg('');
                  e.target.classList.remove('errorInput');
                  setResetEmail(e.target.value);
                }}
              ></input>
              <div style={{ color: 'red' }} className="text-center">
                {errorMsg}
              </div>
              <div className={cx('mt-5', 'px-5')}>
                <div
                  className={cx('submitBtn', 'text-white', 'py-2')}
                  onClick={() => {
                    if (btnDisable) return;

                    emailReset();
                  }}
                >
                  送出
                </div>
              </div>
            </>
          ) : type === 'expire' ? (
            <>
              <p>
                密碼已三個月未變更，
                <br />
                是否前往變更密碼？
              </p>
              <div className={cx('d-flex', 'justify-content-center', 'mt-5')}>
                <div
                  className={cx(
                    'mx-2',
                    'modalCloseButton',
                    'submitBtn',
                    'text-white',
                    'px-4'
                  )}
                  onClick={() => {
                    api.changePassword({
                      cuid: cuid as string,
                      password: '',
                    });
                    handleClose();
                    !!(noticeInfo.title !== '' && noticeInfo.content !== '') &&
                      showNoticeModel();
                  }}
                >
                  稍後變更
                </div>
                <div
                  className={cx(
                    'mx-2',
                    'modalCloseButton',
                    'submitBtn',
                    'text-white',
                    'px-4'
                  )}
                  onClick={() => {
                    handleClose();
                    setPasswordChangeType('change');
                    openModel();
                  }}
                >
                  前往變更
                </div>
              </div>
            </>
          ) : type === 'change' ? (
            <>
              <div className={cx('d-flex', 'mt-2')}>
                <div className={cx('col-5', 'text-start')}>
                  新密碼
                  <br />
                  <small>(6~12位英數字)</small>
                </div>
                <div className={cx('col-7')}>
                  <input
                    type="password"
                    value={password}
                    className="w-100 form-control"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (
                        e.target.value !== '' &&
                        !REGEX_PASSWORD.test(e.target.value)
                      ) {
                        let error = [...sumbitError, 'wrongPassword'];
                        setSumbitError(error);
                      } else {
                        let error = sumbitError.filter(
                          (item) => item !== 'wrongPassword'
                        );
                        setSumbitError(error);
                      }
                    }}
                    onBlur={(e) => {
                      if (password !== '' && password !== password2) {
                        let error = [...sumbitError, 'differentPassword'];
                        setSumbitError(error);
                        console.log('123131232131234', sumbitError);
                      } else {
                        let error = sumbitError.filter(
                          (item) => item !== 'differentPassword'
                        );
                        setSumbitError(error);
                      }
                    }}
                  ></input>
                  <div
                    className={cx(
                      'errMsg',
                      sumbitError.length > 0 &&
                        sumbitError.includes('wrongPassword')
                        ? 'd-block'
                        : 'd-none',

                      'text-start'
                    )}
                  >
                    <small>{errMsg.current.wrongPassword}</small>
                  </div>
                </div>
              </div>

              <div className={cx('d-flex', 'mt-2')}>
                <div className={cx('col-5', 'text-start')}>再次輸入新密碼</div>
                <div className={cx('col-7')}>
                  <input
                    type="password"
                    value={password2}
                    className="w-100  form-control"
                    onChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (password !== password2) {
                        let error = [...sumbitError, 'differentPassword'];
                        setSumbitError(error);
                      } else {
                        let error = sumbitError.filter(
                          (item) => item !== 'differentPassword'
                        );
                        setSumbitError(error);
                      }
                    }}
                  ></input>
                  <div
                    className={cx(
                      sumbitError.length > 0 &&
                        sumbitError.includes('differentPassword')
                        ? 'd-block'
                        : 'd-none',
                      'errMsg',
                      'text-start'
                    )}
                  >
                    <small>{errMsg.current.differentPassword}</small>
                  </div>
                </div>
              </div>
              <div className={cx('mt-5')}>
                <Button
                  className={cx('submitBtn')}
                  onClick={() => {
                    handleCheck();
                  }}
                >
                  確認
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>修改密碼完成！</p>
              <div className={cx('d-flex', 'justify-content-center', 'mt-5')}>
                <div
                  className={cx('mx-2', 'modalCloseButton', 'text-white')}
                  onClick={() => {
                    handleClose();
                    !!(noticeInfo.title !== '' && noticeInfo.content !== '') &&
                      showNoticeModel();
                  }}
                >
                  確認
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordChangeModel;
