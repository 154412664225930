import React from "react";
import { useSetState, useMountedState } from "react-use";
import { merge, tap } from "rxjs";
import dayjs from "dayjs";
import { map } from "rxjs/operators";
import { AsyncState } from "react-use/lib/useAsync";
import api from "../api";
import { FavoriteQuery, FavoriteType } from "../api/newApi/getFavoriteQuery";
import { BnsSubject, TickSubject } from "../websocket/quote";

export interface UseFavoriteQueryParams {
  cu_id: string;
  retryToggle: boolean;
  sessionId: string | null;
  mtype: string;
}

export type FavoriteMap = {
  [symbol: string]: FavoriteQuery;
};

// export interface BNSType {
//   "11000": string;
//   PriceDec: string;
//   delta: string;
//   diff_ratio: string;
//   forluma_price: string;
//   gamma: string;
//   lever: string;
//   percent_buy: string;
//   percent_sale: string;
//   publisher: string;
//   rho: string;
//   run_money: string;
//   run_name: string;
//   run_num: string;
//   run_percent: string;
//   stock_buy: string;
//   stock_id: string;
//   stock_money: string;
//   stock_name: string;
//   stock_percent: string;
//   stock_sale: string;
//   surplus_day: string;
//   target_money: string;
//   target_percent: string;
//   theta: string;
//   vega: string;
//   volume: string;
// }

export const useFavoriteInfo = function ({
  cu_id,
  retryToggle,
  sessionId,
  mtype,
}: UseFavoriteQueryParams): AsyncState<FavoriteQuery> {
  const isMount = useMountedState();
  const priceDecimalRef = React.useRef<number>(4);
  const [favoritState, setFavoritState] = useSetState<
    AsyncState<FavoriteQuery>
  >({
    loading: true,
  });
  const { loading, error } = favoritState;
  const [symbols, setSymbols] = React.useState<string[]>([]);

  React.useEffect(() => {
    (async function () {
      if (sessionId === null) {
        return;
      }
      await api.registerTick({
        symbols: symbols,
        types: ["Tick", "1BA", "BNS"],
        // types: ["BNS"],
        masterSessionId: sessionId,
      });
    })();
  }, [symbols, sessionId]);
  React.useEffect(() => {
    (async function getFavorit() {
      setFavoritState(() => ({ loading: true }));

      const favoritsData = await api.getFavoriteQuery({
        cu_id: cu_id,
      });
      function Formatter(data: any): FavoriteType {
        // const a = Math.floor(Math.random() * 5);
        // const b = Math.floor(Math.random() * 6.3);
        // const c = Math.floor(Math.random() * 3.3);
        return {
          stock_id: data.stock_id,
          stock_name: data.stock_name,
          stock_money: data.stock_money,
          stock_buy: data.stock_buy,
          stock_sale: data.stock_sale,
          stock_percent: data.stock_percent,
          volume: data.volume,
          high_price: data.high_price,
          low_price: data.low_price,
          ref_price: data.ref_price,
          stock_gain: `${(+data.stock_money - +data.ref_price)}`,
        };
      }
      if (!isMount()) {
        return;
      }

      if (favoritsData.err !== "0000") {
        setFavoritState(() => ({
          loading: false,
          error: new Error(favoritsData.err),
        }));
      } else {
        const symbols = favoritsData.favorites
          ? favoritsData.favorites.map((i) => i.stock_id + ".TW" + mtype)
          : [];
        const formatterResponse = (favoritsData.favorites?.map(Formatter)) || [];
        setSymbols(symbols);
        setFavoritState(() => ({ loading: false, value: { ...favoritsData, favorites: formatterResponse } }));
      }
    })();
  }, [isMount, setFavoritState, retryToggle]);

  React.useEffect(() => {
    if (loading || error !== undefined) {
      return;
    }
    const subscriber = merge(TickSubject)
      .pipe(
        // bufferTime(3000),
        // filter((messages) => messages.length > 0),
        tap((message) => {
          console.log("message22", message);
        }),
        map((messages) => {
          const digitMover = (() => {
            const delimiter = Math.pow(10, priceDecimalRef.current);
            return (value: number) => value / delimiter;
          })();
          function Formatter(data: any): FavoriteType {
            return {
              stock_id: data.symbol.split(".")[0],
              stock_name: data.stock_name,
              stock_buy: '' + digitMover(data.bidPrice),
              stock_sale: '' + digitMover(data.askPrice),
              stock_percent: '' + data.upDownRate / 100,
              volume: data.totalVolume,
              stock_money: '' + digitMover(data.price),
              stock_gain: '' + digitMover(data.upDown),
              high_price: digitMover(data.high),
              low_price: digitMover(data.low),
              ref_price: digitMover(data.open),
            };
          }
          const newDate = Formatter(messages);
          // console.log('newData', newDate)
          // debugger
          return newDate;
        })
      )
      .subscribe((message) => {
        if (!isMount()) {
          return;
        }
        setFavoritState((prev) => {
          const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");
          const newLists = message;
          const existingLists = prev.value?.favorites || [];

          function upsert(array: FavoriteType[], element: FavoriteType) {
            const i = array.findIndex(
              (_element: any) => _element.stock_id === element.stock_id
            );

            if (i !== -1) {
              element.stock_name = array[i].stock_name;
              array[i] = element;
            } else {
              array.push(element);
            }
          }

          upsert(existingLists, newLists);
          // console.log("??", existingLists);
          const newState = {
            QueryTime: QueryTime,
            err: prev.value?.err || "",
            favorites: existingLists,
          };

          return {
            value: newState,
          };
        });
      });

    return () => {
      subscriber.unsubscribe();
    };
  }, [symbols, loading, error, setFavoritState, isMount]);
  return favoritState;
};

export default useFavoriteInfo;
