import { AxiosInstance } from "axios";

export interface fbUserLoginPayloadType {
  fbid: string;
  fbname: string;
  fbemail: string;
}
export interface fbUserLoginType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number
}


export const fbUserLogin = async (axios: AxiosInstance, payload: fbUserLoginPayloadType) => {

  const { data } = await axios.post<fbUserLoginType>(
    `${process.env.REACT_APP_URL}api/fb_login.php`, payload
  );

  console.log(data)
  if (!data) {

  }
  return data;
};
