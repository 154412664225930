import SockJS from "sockjs-client";
import { v4 as uuid } from "uuid";

import base64StringTest from "../../tools/base64-string-test";

import pako from "pako";
import { decode as messagepackDecode } from "messagepack";

import { toast } from "react-toastify";

import { OrderReportMessageSubject, HeartbeatSubject } from "./subject";

import { Mode } from "./model";

import { orderMessage } from "./model";

export interface Parameter {
  url: string;
  cu_id: string;
  campaignName: string;
}

export function connectWebSocket({
  url,
  cu_id,
  campaignName,
}: Parameter): WebSocket {
  function tokenGenerator(cu_id: string) {
    const uuidPart = uuid();
    return `APEX-${cu_id}$${uuidPart}`;
  }

  const sock = new SockJS(url, null, {
    sessionId: () => tokenGenerator(cu_id),
  });

  sock.onopen = function () {
    console.log(`{ order } web socket [onOpen], open on: ${sock.url}`);
    // debugger
    toast.info(`${campaignName}已連線`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  sock.onclose = function () {
    // debugger
    console.log("{ order } web socket [onClose]");
  };

  sock.onerror = (e) => {
    console.log(`{ order } web socket [onError], reason: ${JSON.stringify(e)}`);  
    console.error("WebSocket Error:", e);
  };

  sock.onmessage = ({ data }) => {
    const res = base64StringTest(data)
      ? messagepackDecode(pako.inflate(window.atob(data)))
      : data;
    console.log("orderSocketOnmessage", res);
    // debugger
    if (isHeartBeatMessage(res)) {
      handleGetHeartBeatMessage(res);
      return;
    }
    // console.log("{ order } web socket [onMessage]: ", res["FUNC"], res);

    const mode = res["FUNC"] as Mode;
    switch (mode) {
      case Mode.NEWORDER:
        handleGetOrderMessage(res);
        return;
      case Mode.NEWORDERFAIL:
        handleGetOrderMessage(res);
        return;
      case Mode.DELORDER:
        handleGetOrderMessage(res);
        return;
      case Mode.DELORDERFAIL:
        handleGetOrderMessage(res);
        return;
      case Mode.CHANGEORDER:
        handleGetOrderMessage(res);
        return;
      case Mode.CHANGEORDERFAIL:
        handleGetOrderMessage(res);
        return;
      case Mode.ORDERDEAL:
        handleGetOrderMessage(res);
        return;

      default:
        console.log('{ order } web socket [onMessage]: "unknown"', res);
        return;
    }
  };

  return sock;
}

function handleGetOrderMessage(res: any): void {
  const symbol = res["SYM"];
  const qty = res["QTY"];
  const price = res["P1"];
  const orderNo = res["ORN"];
  const avq = res["AVQ"];
  const bs = res["BS1"];
  const cik = res["CIK"];
  const func = res["FUNC"];
  const ors = res["ORS"];
  const cid = res["CID"];
  const ort = res["ORT"];
  const tif = res["TIF"];

  const result: orderMessage = {
    AVQ: avq,
    BS1: bs,
    CID: cid,
    CIK: cik,
    FUNC: func,
    ORN: orderNo,
    ORS: ors,
    ORT: ort,
    P1: price,
    QTY: qty,
    SYM: symbol,
    TIF: tif,
  };
  OrderReportMessageSubject.next(result);
}

function handleGetHeartBeatMessage(res: any): void {
  HeartbeatSubject.next(`Heartbeat:${res.Heartbeat}`);
  // console.log(res);
}
function isHeartBeatMessage(res: any): boolean {
  return ["hb", "heartbeat", "Heartbeat", "beat"].some((v) =>
    res.hasOwnProperty(v)
  );
}
