import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface GetOrderQueryParameters {
  cu_id: string;
  rows?: number;
  page: number;
}

export interface OrderQueryResponse {
  err: string;
  message?: string;
  orders: OrderType[];
  pages: number;
  recs: number;
  QueryTime?: string;
}
export interface OrderType {
  orn: number;
  /**
   * 權證代號
   */
  sym: string;
  /**
   * 權證名稱
   */
  name: string;
  bS1: string;
  p1: number;
  qty: number;
  ort: string;
  /**
   * 最後一次成交單價
   */
  dp: number;
  /**
   * 最後一次成交數量
   */
  dq: number;
  /**
   * 未成交金額
   */
  hp: number;
  /**
   * 未成交量
   */
  hq: number;
  /**
   * 委託狀態
   */
  stat: string;
  /**
   * 委託狀態
   */
  status: number;
  /**
   * 委託時間
   */
  ot: string;
  /**
   * 最後一次成交時間
   */
  dt: string;
  /**
   *總成交張數
   */
  tda: number;
  /**
   *總成交金額[未含稅+費]
   */
  tdq: number;
  /**
   *未成交金額
   */
  ha: number;
  ottime?: string;
  dttime?: string;
  tif: string;
  detail?: {
    qty: number;
    price: number;
    time: string;
  }[];
  user_name?: string;
  day_trade?: boolean;
  action?: {
    orn: number;
    sym: string;
    bS1: string;
    p1: number;
    qty: number;
    status: number;
  };
}

function Formatter(data: OrderType): OrderType {
  return {
    orn: data.orn,
    /**
     * 權證代號
     */
    sym: data.sym,
    /**
     * 權證名稱
     */
    name: data.name,
    bS1: data.bS1,
    p1: data.p1,
    qty: data.qty,
    /**
     * 成交金額
     */
    dp: data.dp,
    /**
     * 成交量
     */
    dq: data.dq,
    /**
     * 未成交金額
     */
    hp: data.hp,
    /**
     * 未成交量
     */
    hq: data.hq,
    tif: data.tif,
    /**
     * 委託狀態
     */
    stat: data.stat,
    /**
     *   /**
     * 委託狀態
     */
    status: data.status,
    tda: data.tda,
    tdq: data.tdq,
    ha: data.ha,
    ort: data.ort,
    /**
     * 委託時間
     */
    ot: dayjs(data.ot).format("MM/DD"),
    ottime: dayjs(data.ot).format("HH:mm:ss"),
    /**
     * 成交時間
     */
    dt: data.dt ? dayjs(data.dt).format("MM/DD") : "--",
    user_name: data.user_name,
    day_trade: data.day_trade,
    dttime: data.dt ? dayjs(data.dt).format("HH:mm:ss") : "--",
    detail: data.detail,
    action: {
      orn: data.orn,
      sym: data.sym,
      bS1: data.bS1,
      p1: data.p1,
      qty: data.qty,
      status: data.status,
    },
  };
}

export const orderQuery = async (
  axios: AxiosInstance,
  { cu_id, page }: GetOrderQueryParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
    rows: 20,
    page,
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<OrderQueryResponse>(
      "api2/Trade/OrderQuery",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", orders: null, pages: 0, recs: 0 };
    }
    if (data.err === "9999" && data.message) {
      toast.error(`${(data.message || "") + "(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    if (data.err !== "0000") {
      toast.warn(`Error${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");

    return {
      err: data.err || "",
      orders: data.orders.map(Formatter),
      pages: data.pages,
      recs: data.recs,
      QueryTime,
    };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return;


  }
};
