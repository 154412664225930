import React, { useState } from 'react';
import classNames from 'classnames/bind';
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import * as Icons from '../../icons';
import SelectSearch from 'react-select-search';
import './select-search.css';
import { useAsync, useAsyncRetry } from 'react-use';
import api from '../../api';
import { useSingleQuote } from '../../hook/useSingleQuote';
import { target } from '../../api/newApi/getTargetsQuery';
import { Publisher } from '../../api/newApi/getPublisherQuery';
import PriceWithStepper from '../../components/NumberInputWithStepper';
import dayjs from 'dayjs';
import CheckBoxWithText from '../../components/CheckBoxWithText';
import { toast } from 'react-toastify';
import axios from 'axios';
import style from './index.module.css';
import { loginInfo } from '../../model';

const cx = classNames.bind(style);

export interface Order {
  cuid: string;
  campaignName: string | null;
  sessionId: string | null;
  mtype: string;
  invitationCode: string | null;
  handleOninvitationCode: (code: string) => void;
  campaign_active: boolean;
  continueBell: () => void;
  setOrderBackgroundColor: (e: string) => void;
  loginInfo: loginInfo;
  userName: string;
  setNoticeInfo: (obj: any) => void;
  setNoticeOpenAccount: (accountOpen: boolean) => void;
  setNoticeModelShow: (show: boolean) => void;
}
interface SelectTarget {
  name: string;
  value: string;
  key?: string;
}
const sideRadios = [
  { name: '買', value: 'B' },
  { name: '賣', value: 'S' },
];
const dayTradingTypeRadios = [
  { name: '現股', value: false, disabled: false },
  { name: '現先賣', value: true, disabled: false },
  { name: '融資', value: false, disabled: true },
  { name: '融券', value: false, disabled: true },
  { name: '借券', value: false, disabled: true },
  { name: '賣品', value: false, disabled: true },
];
const orderPriceTypeRadios = [
  {
    name: '跌停',
    value: '4',
    className: 'limitDownType',
  },
  {
    name: '平盤',
    value: '5',
    className: 'openingPriceType',
  },
  {
    name: '漲停',
    value: '3',
    className: 'limitUpType',
  },
  {
    name: '市價',
    value: '1',
    className: 'marketPriceType',
  },
];
const orderTypeMapping = {
  '1': '市價',
  '2': '限價',
  '3': '漲停',
  '4': '跌停',
  '5': '平盤',
};
const orderTimeRadios = [
  { name: '整股ROD', value: 'R' },
  { name: '整股IOC', value: 'I' },
  { name: '整股FOK', value: 'F' },
];
const financialInstrumentTypes = [
  { name: '證券', disabled: false },
  { name: '期貨', disabled: true },
  { name: '選擇權', disabled: true },
  { name: '複委託', disabled: true },
  { name: '外期權', disabled: true },
];

function useRegisterData(symbol: string, sessionId: string | null) {
  React.useEffect(() => {
    (async function () {
      if (sessionId === null) {
        return;
      }
      // (window.parent as any).vm.scrollTo();
      await api.registerTick({
        symbols: symbol,
        types: ['Tick', 'BA', 'BNS'],
        masterSessionId: sessionId,
      });
    })();
  }, [symbol, sessionId]);
}

const Order: React.FC<Order> = (props) => {
  const {
    cuid,
    campaignName,
    sessionId,
    mtype,
    invitationCode,
    handleOninvitationCode,
    campaign_active,
    continueBell,
    setOrderBackgroundColor,
    loginInfo,
    userName,
    setNoticeInfo,
    setNoticeOpenAccount,
    setNoticeModelShow,
  } = props;

  console.log('campaign??', campaign_active);
  // const { GuideDisplay, retry } = useUserGuideQuery(cuid);
  const Digits2NumberFormatter = (value: string) =>
    (+value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const Digits3NumberFormatter = (value: string) =>
    (+value).toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  const [symCnName, setSymCnName] = useState<string>('');
  const [orderBS, setOrderBS] = useState<'DB' | 'DS' | 'B' | 'S' | null>(null);
  const [orderP1, setOrderP1] = useState<number | null>(null);
  const [orderQty, setOrderQty] = useState<number>(1);
  const [orderTif, setOrderTif] = useState<'R' | 'F' | 'I'>('R');
  const [orderOrt, setOrderOrt] = useState<'1' | '2' | '3' | '4' | '5' | null>(
    null
  ); //價格類型(1=市價,2=限價,3=漲停,4=跌停,5=平盤)
  const [dayTrading, setDayTrading] = useState<boolean>(false);
  const [sym, setSym] = useState<any>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [selfChoiceArray, setSelfChoiceArray] = useState<any>([]);
  const [isThisSymInSelfChoice, setIsThisSymInSelfChoice] =
    useState<boolean>(false);
  const [t1, setT1] = useState<string>(
    dayjs().format('YYYY/MM/DD HH:mm:ss.000')
  );
  const [t2, setT2] = useState<string>('');
  React.useEffect(() => {
    const data = window.sessionStorage.getItem('ORDER_OBJECT');
    if (!data) return;
    const dataObj = JSON.parse(data);
    setSym(dataObj.stock);
    if (dataObj.BS) {
      setTimeout(() => {
        setOrderBS(dataObj.BS);
        setOrderBackgroundColor(dataObj.BS);
      }, 300);
    }
    setTimeout(() => {
      window.sessionStorage.removeItem('ORDER_OBJECT');
    }, 500);
  }, []);
  const reset = () => {
    // setSym(null);
    // setSymCnName('');
    setOrderBS(null);
    setOrderP1(null);
    setOrderQty(1);
    setOrderTif('R');
    setOrderOrt(null);
    setOrderBackgroundColor('');
    setDayTrading(false);
  };
  function checkIsSelfSelect(selfSelectArray: any) {
    setIsThisSymInSelfChoice(
      !!selfSelectArray.find((item: any) => item.stock_id === sym)
    );
  }
  function showOpenAccountModel() {
    setNoticeInfo({
      title: '開通完整功能',
      content: '體驗更多更完整服務 \n 歡迎立即開戶',
    });
    setNoticeOpenAccount(true);
    setNoticeModelShow(true);
  }
  async function getSelfSelect() {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}api2/Favorite/FavoriteQuery`,
      {
        cu_id: cuid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (res.data.err === '0000') {
      const selfSelectArray = res.data.favorites;
      setSelfChoiceArray(selfSelectArray);
    }
  }

  React.useEffect(() => {
    getSelfSelect();
  }, []);
  React.useEffect(() => {
    getSelfSelect();
  }, [toggle, setToggle]);
  React.useEffect(() => {
    reset();
    checkIsSelfSelect(selfChoiceArray);
  }, [sym]);
  React.useEffect(() => {
    checkIsSelfSelect(selfChoiceArray);
  }, [selfChoiceArray]);

  const handlePrice = (price: number) => {
    setOrderP1(price);
    setOrderOrt('2');
  };

  const handlePriceType = (type: '1' | '2' | '3' | '4' | '5') => {
    setOrderOrt(type);
    if (type !== '2') {
      setOrderP1(0);
    }
  };

  const handleOrderPlace = () => {
    if (!orderBS || !orderTif || !orderOrt || orderP1 == null) {
      toast.warn('請填妥下單條件', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      orderOrt === '2' &&
      (!orderP1 || Number(orderP1) <= 0 || orderP1 <= 0)
    ) {
      toast.warn('限價單請輸入價格', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    api
      .OrderPlace({
        cu_id: cuid,
        src: 'WEB',
        sym: sym,
        bS1:
          orderBS === 'DB' || orderBS === 'B'
            ? 'B'
            : orderBS === 'DS' || orderBS === 'S'
            ? 'S'
            : '',
        p1: orderP1,
        qty: orderQty,
        tif: orderTif,
        ort: orderOrt,
        icc: invitationCode === null ? '1' : '0',
        dt: dayTrading,
        uid: loginInfo.uid as number,
      })
      .then((e) => {
        reset();
        if (!e.inv_code) {
          return;
        }
        if (e.inv_code) {
          handleOninvitationCode(e.inv_code);
        }
      });
  };

  const RELOAD_TIME = 60000;

  const currentSymbol = sym ? sym + `.TW${mtype}` : '';

  const asyncState = useSingleQuote({
    symbol: currentSymbol,
    sessionId: sessionId || '',
  });
  React.useEffect(() => {
    console.log('asyncState', asyncState);
    if (!asyncState.loading) {
      const stockName = asyncState?.value?.NameSlave?.split('.')[0] || '';
      const stockNum = asyncState?.value?.NameSlave?.split('.')[1] || '';

      if (symCnName !== `${stockName}(${stockNum})`) {
        setSymCnName(`${stockName}(${stockNum})`);
        if (asyncState.value) {
          if (asyncState.value.Asks.length > 0) {
            if (asyncState.value?.Asks[0].Price === 0) {
              setOrderOrt('1');
            } else {
              setOrderOrt('2');
            }
            setOrderP1(asyncState.value?.Asks[0].Price as number);
          } else if (asyncState.value.Bids.length > 0) {
            if (asyncState.value?.Bids[0].Price === 0) {
              setOrderOrt('1');
            } else {
              setOrderOrt('2');
            }
            setOrderP1(asyncState.value?.Bids[0].Price as number);
          } else {
            setOrderP1(null);
          }
        }
      }
    }
  }, [asyncState]);

  const handleOnFiveOrder = (buyOrSell: 'B' | 'S', price: number) => {
    // handleBuyOrSell(buyOrSell)

    handlePrice(price);
    if (price === 0) {
      setOrderOrt('1');
    }
  };

  const handleOnchange = (e: any) => {
    const splitWord = e.substring(e.indexOf('(') + 1, e.lastIndexOf(')'));
    console.log('?????????', splitWord);
    console.log('?????????', e);
    // debugger;
    setSym(e);
  };
  useRegisterData(currentSymbol, sessionId);
  React.useEffect(() => {
    console.log('asdhjk', sym);
  }, [sym]);
  const OrderPlace = () => {
    handleOrderPlace();
  };
  function searchWarrantFormatter(data: any) {
    return {
      value: data.id,
      name: `${data.name}(${data.id})`,
    };
  }
  const searchInput = React.useMemo(() => {
    if (sym === '') {
      return (
        <SelectSearch
          className={cx(
            'select-search-one',
            'select-search-order',
            'boxShadow_none',
            'order_search_input'
          )}
          options={[]}
          search
          emptyMessage="找不到標的"
          placeholder={`${symCnName ? symCnName : '請輸入關鍵字'}`}
          value={sym}
          onChange={(e) => handleOnchange(e)}
        />
      );
    }
    return (
      <SelectSearch
        className={cx(
          'select-search-one',
          'select-search-order',
          'boxShadow_none',
          'order_search_input'
        )}
        options={[]}
        debounce={400}
        getOptions={(query) => {
          return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL}api2/Trade/Warrants`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                sym: query || '',
                rows: 9999,
                page: 0,
              }),
            })
              .then((response) => response.json())
              .then((e) => {
                const data = e.warrants;
                resolve(data.map(searchWarrantFormatter));
              })
              .catch(reject);
          });
        }}
        search
        emptyMessage="找不到標的"
        placeholder={`${symCnName ? symCnName : '請輸入關鍵字'}`}
        value={sym}
        onChange={(e) => handleOnchange(e)}
      />
    );
  }, [symCnName]);

  return (
    <>
      <div>
        <div className={cx('pt-3')}>
          <div className={cx('d-flex', 'justify-content-evenly', 'mb-2', '')}>
            {financialInstrumentTypes.map((v) => {
              return (
                <div
                  className={cx(
                    'w-100',
                    'mx-1',
                    'financialTypeBtn',
                    v.disabled ? 'disable_financialType' : ''
                  )}
                  onClick={() => {
                    if (v.disabled) {
                      showOpenAccountModel();
                      return;
                    }
                  }}
                >
                  {v.name}
                </div>
              );
            })}
          </div>
          <div className={cx('financialTypeBtn', 'mb-2', 'text-start')}>
            <span className={cx('stockIcon')}>證</span>
            <span>證-{userName}</span>
          </div>
          <Form>
            <Form.Group as={Row} className="mb-2">
              <div className={cx('d-flex', 'col-7', 'order_search_wrap')}>
                <span
                  className={cx(
                    'order_search_icon',
                    'w-20',
                    'h-100',
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                    'max_height_36px'
                  )}
                >
                  <Icons.Search width={28} height={28}></Icons.Search>
                </span>
                <span className={cx('max_height_36px', 'w-100')}>
                  {searchInput}
                </span>
              </div>
              <div
                className={cx(
                  'd-flex',
                  'col-5',
                  'justify-content-between',
                  'ps-0'
                )}
              >
                <div
                  className={cx(
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                    'flex-grow-1'
                  )}
                >
                  <div className={cx('text-center')}>
                    {asyncState.value?.NameSlave?.split('.')[0]}
                  </div>
                </div>
                <div className="flex-grow-0">
                  {asyncState.value?.DayTradingMark && (
                    <CheckBoxWithText
                      status={
                        asyncState.value?.DayTradingMark as 'A' | 'B' | 'N'
                      }
                    ></CheckBoxWithText>
                  )}
                </div>
              </div>
            </Form.Group>
            <div className={cx('d-flex', 'justify-content-evenly', 'mb-2', '')}>
              {dayTradingTypeRadios.map((v) => {
                return (
                  <div
                    className={cx(
                      'w-100',
                      'financialTypeBtn',
                      v.disabled ? 'disable_financialType' : '',
                      v.name === '現先賣' &&
                        asyncState.value?.DayTradingMark === 'N' &&
                        'disable_financialType',
                      !v.disabled && dayTrading === v.value
                        ? 'dayTradingBtn_chosen'
                        : ''
                    )}
                    onClick={() => {
                      if (v.disabled) {
                        showOpenAccountModel();
                        return;
                      }

                      if (v.name === '現先賣') {
                        if (asyncState.value?.DayTradingMark === 'N') return;
                        setOrderBS('S');
                        setOrderBackgroundColor('S');
                      }
                      setDayTrading(v.value);
                    }}
                  >
                    {v.name}
                  </div>
                );
              })}
            </div>
            <Form.Group as={Row} className="mb-2">
              <div className={cx('col-6')}>
                <SelectSearch
                  className={cx(
                    'select-search-one',
                    'select-search-order',
                    'boxShadow_none',
                    'order_search_input',
                    'orderTimeRadiosCustomPadding'
                  )}
                  options={orderTimeRadios}
                  value={orderTif}
                  onChange={(e) => {
                    setOrderTif(e as any);
                  }}
                />
              </div>
              <div className={cx('d-flex', 'col-6')}>
                {sideRadios.map((radio, idx) => {
                  return (
                    <Button
                      key={idx}
                      id={`sideRadio-${idx}`}
                      variant={
                        radio.value === null
                          ? ''
                          : radio.value === 'S'
                          ? 'outline-success'
                          : 'outline-danger'
                      }
                      name="sideRadio"
                      value={radio.value}
                      // checked={orderBS === radio.value}
                      onClick={() => {
                        setOrderBS(radio.value as 'B' | 'S');
                        setOrderBackgroundColor(radio.value);
                      }}
                      style={{ fontSize: '18px' }}
                      className={cx(
                        'px-2',
                        'px-md-4',
                        'mx-1',
                        'w-50',
                        orderBS === radio.value
                          ? radio.value === 'S'
                            ? 'btn_chosen_sell'
                            : 'btn_chosen_buy'
                          : '',
                        radio.value === 'B' && dayTrading ? 'btn_disabled' : ''
                      )}
                    >
                      {radio.name}
                    </Button>
                  );
                })}
              </div>
            </Form.Group>

            <Form.Group as={Row} className="mb-2">
              <Col md="9" xs="9">
                <PriceWithStepper
                  value={orderQty}
                  onChange={setOrderQty}
                ></PriceWithStepper>
              </Col>

              <Col
                style={{
                  display: 'flex',
                  paddingLeft: '0',
                  flexDirection: 'column',
                }}
              >
                <small>交易單位</small>
                <small>1000股</small>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col md="9" xs="9">
                {!(orderOrt === '2' || orderOrt === null) ? (
                  <InputGroup.Text
                    className={cx('justify-content-center', 'mktDisableInput')}
                  >
                    {orderTypeMapping[orderOrt as '1' | '2' | '3' | '4' | '5']}
                  </InputGroup.Text>
                ) : (
                  <PriceWithStepper
                    value={orderP1}
                    onChange={setOrderP1}
                    scaleItems={asyncState.value?.ScaleItems}
                    min={0}
                  ></PriceWithStepper>
                )}
              </Col>
              <Col
                style={{
                  display: 'flex',
                  paddingLeft: '0',
                  flexDirection: 'column',
                }}
              >
                <small>預估成本</small>
                <small>
                  {orderP1
                    ? (orderP1 * orderQty * 1000).toLocaleString('en-US')
                    : '--'}
                </small>
              </Col>
            </Form.Group>
            <div className={cx('d-flex', 'align-items-center')}>
              <div
                className={cx(
                  'd-flex',
                  'justify-content-evenly',
                  'align-items-center',
                  'col-9'
                )}
              >
                {orderPriceTypeRadios.map((item, idx) => {
                  return (
                    <button
                      className={cx(
                        'w-100',
                        'mx-1',
                        'financialTypeBtn',
                        'fw-bold',
                        !sym ? 'disable_financialType' : '',
                        item.className,
                        orderOrt === item.value
                          ? `${item.className}_selected`
                          : ''
                      )}
                      key={idx}
                      id={`priceType-${idx}`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!sym) return;
                        if (orderOrt === item.value) {
                          setOrderP1(null);
                          setOrderOrt('2');
                        } else {
                          handlePriceType(
                            item.value as '1' | '2' | '3' | '4' | '5'
                          );
                        }
                      }}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
              {/* 這邊是自選功能按鈕 先拔掉 */}
              {/* {sym && (
                <div className={cx('col-3', 'mx-auto', 'px-2')}>
                  <div
                    className={cx(
                      isThisSymInSelfChoice
                        ? 'self_choice_btn_selected'
                        : 'self_choice_btn'
                    )}
                    onClick={async () => {
                      if (isThisSymInSelfChoice) {
                        setIsThisSymInSelfChoice(!isThisSymInSelfChoice);
                        await api.favoriteRemove({
                          cu_id: cuid,
                          stock_id: sym,
                        });
                        setToggle(!toggle);
                        // console.log('這邊要取消');
                      } else {
                        if (selfChoiceArray.length >= 20) {
                          toast.warn('自選數量已達上限', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          return;
                        }

                        setIsThisSymInSelfChoice(!isThisSymInSelfChoice);
                        await api.favoriteAdd({
                          cu_id: cuid,
                          stock_id: sym,
                        });
                        setToggle(!toggle);
                        // console.log('這邊要加入');
                      }
                    }}
                  >
                    自選&nbsp;
                    {
                      <Icons.Like
                        fill={isThisSymInSelfChoice ? '#fff' : '#a8acae'}
                      />
                    }
                  </div>
                </div>
              )} */}
              <div className={cx('col-3', 'mx-auto', 'px-2')}>
                <div
                  className={cx('lighting_btn', 'disable_financialType')}
                  onClick={() => {
                    showOpenAccountModel();
                  }}
                >
                  <img
                    height={25}
                    src={require('../../static/image/icon_flash.png')}
                    alt="閃電下單"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div
          className={cx(
            'modalFoot',
            orderBS === 'B'
              ? 'backGround-Buy'
              : orderBS === 'S'
              ? 'backGround-Sell'
              : ''
          )}
        >
          <Row>
            <Col className={cx('five-section', 'me-1')}>
              {/* <h5 className="text-danger text-center">委買價/量</h5> */}
              <div className={cx('w-100', 'h-100', 'd-flex', 'flex-column')}>
                {asyncState.value?.Bids.map((item, index) => {
                  if (index >= 5) {
                    return;
                  }
                  return (
                    <InputGroup
                      className={cx('five-group', 'w-100', 'my-1')}
                      key={'B' + index + item.Price + Math.random()}
                      onClick={() => {
                        handleOnFiveOrder('B', item.Price);
                      }}
                    >
                      <div
                        className={cx(
                          'w-100',
                          'd-flex',
                          'justify-content-between',
                          'align-items-center'
                        )}
                      >
                        <Form.Control
                          type="number"
                          value={item.Volume}
                          disabled
                          readOnly
                          className={cx('fiveQuantity', 'me-2', 'text-start')}
                        />
                        <span
                          className={cx(
                            'fivePrice',
                            (asyncState.value?.PreCl &&
                              asyncState.value.PreCl < item.Price) ||
                              item.Price === 0
                              ? 'fivePrice_buy'
                              : asyncState.value?.PreCl &&
                                asyncState.value.PreCl > item.Price
                              ? 'fivePrice_sell'
                              : 'fivePrice_normal'
                          )}
                        >
                          {item.Price === 0 ? '市價' : item.Price}
                        </span>
                      </div>
                    </InputGroup>
                  );
                })}
              </div>
            </Col>
            <Col className={cx('five-section', 'ms-1')}>
              <div className={cx('w-100', 'h-100', 'd-flex', 'flex-column')}>
                {asyncState.value?.Asks.map((item, index) => {
                  if (index >= 5) {
                    return;
                  }

                  return (
                    <InputGroup
                      className={cx('five-group', 'w-100', 'my-1')}
                      key={'S' + index + item.Price + Math.random()}
                      onClick={() => {
                        handleOnFiveOrder('S', item.Price);
                      }}
                    >
                      <div
                        className={cx(
                          'w-100',
                          'd-flex',
                          'justify-content-between',
                          'align-items-center'
                        )}
                      >
                        <span
                          className={cx(
                            'fivePrice',
                            asyncState.value?.PreCl &&
                              asyncState.value.PreCl < item.Price
                              ? 'fivePrice_buy'
                              : asyncState.value?.PreCl &&
                                asyncState.value.PreCl > item.Price
                              ? 'fivePrice_sell'
                              : 'fivePrice_normal'
                          )}
                        >
                          {item.Price === 0 ? '市價' : item.Price}
                        </span>
                        <Form.Control
                          type="number"
                          value={item.Volume}
                          disabled
                          readOnly
                          className={cx('fiveQuantity', 'ms-2', 'text-end')}
                        />
                      </div>
                    </InputGroup>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={cx('button-group ', 'order_btn_display', 'w-100', 'mb-3')}
        >
          <Col style={{ display: 'inline-grid' }}>
            <Button
              className={cx('order-button-cancel')}
              size="lg"
              onClick={reset}
            >
              清除
            </Button>
          </Col>
          <Col style={{ display: 'inline-grid' }}>
            <Button
              className={cx(
                'order-button',
                orderBS === 'B' || orderBS === 'DB'
                  ? 'order_btn_buy'
                  : orderBS === 'S' || orderBS === 'DS'
                  ? 'order_btn_sell'
                  : ''
              )}
              size="lg"
              onClick={OrderPlace}
            >
              下單
            </Button>
          </Col>
        </div>
      </div>
    </>
  );
};
// function usePublisherInfo() {
//   const { loading, value } = useAsync(api.getPublisherQuery, []);

//   function formatter(data: Publisher): SelectTarget {
//     return {
//       name: data.name,
//       value: data.name,
//     };
//   }
//   const data = value?.publisher ? value?.publisher.map(formatter) : [];
//   const allOption = {
//     name: '全部',
//     value: '',
//   };
//   const NewData = data
//     .concat(allOption)
//     .sort((a, b) => (a.value > b.value ? 1 : -1));
//   const publisher = React.useMemo(() => {
//     return NewData;
//   }, [loading]);

//   return {
//     loading,
//     publisher,
//   };
// }

// function useTargetsInfo() {
//   const { loading, value } = useAsync(api.getTargetsQuery, []);

//   function Formatter(data: target): SelectTarget {
//     const symCnName = data.name + '(' + data.id + ')';
//     return {
//       name: symCnName,
//       value: symCnName,
//       key: data.id,
//     };
//   }
//   const targets = React.useMemo(() => {
//     return value?.targets ? value?.targets.map(Formatter) : [];
//   }, [loading]);

//   return {
//     loading,
//     targets,
//   };
// }

export default Order;
