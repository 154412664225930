import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface UserArticleReadTaskResponse {
  err: string;
  message?: string;
}
export interface UserArticleReadTaskQueryParameters {
  cu_id: string | null;
  Stage: 'm19' | 'm20'
}

export const userArticleReadCheck = async (
  axios: AxiosInstance,
  { cu_id, Stage }: UserArticleReadTaskQueryParameters) => {

  const requestData = JSON.stringify({
    cu_id,
    Stage
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const response = await axios.post<UserArticleReadTaskResponse>("api2/Warrant/TaskWrite", requestData,
      { headers });
    if (!response.data) {
      toast.error(`閱讀文章任務寫入Error:not found`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", user_task: [] };
    }
    if (response.data.err !== "0000") {
      toast.warn(`閱讀文章任務寫入Error:${"(" + response.data.message + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return response.data;
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", user_task: [] };
  }
};
