import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface verifyApplyParameters {
  cu_id: string | null;
  v_type: number;
}

interface verifyApplyResponse {
  err: string;
  message: string;
  timeout: number;
}

export const verifyApply = async (
  axios: AxiosInstance,
  { cu_id, v_type }: verifyApplyParameters
) => {
  if (cu_id === null || cu_id === "") {
    return;
  }
  const requestData = JSON.stringify({
    cu_id,
    v_type,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const res = await axios.post<verifyApplyResponse>(
      "api2/Invitation/verifyApply",
      requestData,
      { headers }
    );

    if (!res.data) {
      return {
        err: "9999",
        message: "",
        timeout: Number(process.env.REACT_APP_VERIFY_APPLY_TIMEOUT),
      };
    }
    if (res.data.err !== "0000") {
      toast.error(
        `簡訊發送失敗:${"(" + res.data.err + ")" + res.data.message}`,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
    if (res.data.err === "0000") {
      toast.success(`簡訊已發送`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return res.data;
  } catch (error) {
    return {
      err: "401",
      message: "",
      timeout: Number(process.env.REACT_APP_VERIFY_APPLY_TIMEOUT),
    };
  }
};
