import { AxiosInstance } from "axios";

export interface UserGuideQueryParameters {
  cu_id: string;
}

interface UserGuideQueryResponse {
  err: string;
  display: boolean;
}

export const UserGuideQuery = async (
  axios: AxiosInstance,
  { cu_id }: UserGuideQueryParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<UserGuideQueryResponse>(
      "api2/Warrant/UserGuideQuery",
      requestData,
      { headers }
    );
    if (!data || data.err !== "0000") {
      return { err: "9999", display: false };
    }
    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", display: false };
  }
};
