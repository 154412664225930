import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface GetProfitParameters {
  cu_id: string;
}

export interface ProfitQueryResponse {
  err: string;
  bc: number;
  sa: number;
  pf: number;
  roi: number;
  rpl: ProfitRPLType[];
  QueryTime?: string;
}
export interface ProfitRPLType {
  st: string;
  stime?: string;
  sym: string;
  name: string;
  q: number;
  p: number;
  bc: number;
  sa: number;
  pf: number;
  roi: number;
  f: number;
  t: number;
}

export const getProfitQuery = async (
  axios: AxiosInstance,
  { cu_id }: GetProfitParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  function Formatter(data: ProfitRPLType): ProfitRPLType {
    return {
      st: dayjs(data.st).format("YYYY/MM/DD"),
      stime: dayjs(data.st).format("HH:mm:ss"),
      sym: data.sym,
      name: data.name,
      q: data.q,
      p: data.p,
      bc: data.bc,
      sa: data.sa,
      pf: data.pf,
      roi: data.roi,
      f: data.f,
      t: data.t,
    };
  }
  const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");
  try {
    const { data } = await axios.post<ProfitQueryResponse>(
      "api2/Billing/Profit",
      requestData,
      { headers }
    );

    if (!data) {
      toast.error(`標的清單Error:not found`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return {
        err: "9999",
        bc: 0,
        sa: 0,
        pf: 0,
        roi: 0,
        rpl: [],
        QueryTime,
      };
    }
    if (data.err !== "0000") {
      toast.warn(`己實現損益${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return {
      err: data.err || "",
      bc: data.bc,
      sa: data.sa,
      pf: data.pf,
      roi: data.roi,
      rpl: data.rpl.map(Formatter),
      QueryTime,
    };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return {
      err: "9999",
      bc: 0,
      sa: 0,
      pf: 0,
      roi: 0,
      rpl: [],
      QueryTime,
    };
  }
};
