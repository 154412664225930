import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface GetFavoriteQueryParameters {
  cu_id: string;
  stock_id?: string;
}

export interface FavoriteQuery {
  err: string;
  favorites: FavoriteType[] | null;
  QueryTime?: string;
}
// export interface FavoriteType {
//   action?: any;
//   stock_id: string;
//   stock_name: string;
//   stock_money: string;
//   stock_buy: string;
//   stock_sale: string;
//   stock_percent: string;
//   target_money: string;
//   target_percent: string;
//   run_money: string;
//   run_num: string;
//   stock_volumn: string;
//   surplus_day: string;
//   lever: string;
//   percent_buy: string;
//   diff_ratio: string;
//   t_up_stop: number;
//   t_down_stop: number;
//   ref_price: number;
//   QueryTime?: string;
//   run_percent?: string;
// }
export interface FavoriteType {
  action?: any; //0
  stock_id: string; //2
  stock_name: string; //1
  stock_buy: string; //買進
  stock_sale: string; //賣出
  stock_money: string; //現有價格
  stock_percent: string; //漲跌%
  stock_gain: string; //漲跌
  volume: string; //5
  ref_price: number;
  high_price: number; //6
  low_price: number; //7
  QueryTime?: string;
  s_stats?: number
}



export const getFavoriteQuery = async (
  axios: AxiosInstance,
  { cu_id, stock_id }: GetFavoriteQueryParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
    stock_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");
  try {
    const { data } = await axios.post<FavoriteQuery>(
      "api2/Favorite/FavoriteQuery",
      requestData,
      { headers }
    );
    if (!data || !data.favorites) {
      return { err: "9999", favorites: null, QueryTime };
    }

    if (data.err !== "0000") {
      toast.warn(`自選${":(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { QueryTime, ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", favorites: null, QueryTime };
  }
};
