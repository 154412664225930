import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
import { Spinner, Pagination } from 'react-bootstrap';

import {
  useTable,
  useExpanded,
  useSortBy,
  usePagination,
  Column,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import BottomBlinker from '../../components/BottomBlinker';
import dayjs from 'dayjs';

import classNames from 'classnames/bind';
import style from './index.module.css';
const cx = classNames.bind(style);

// import { UseTableInstanceProps } from "react-table";

type onOrderClickFunc = (row: any, side: 'B' | 'S') => void;
interface TableProps<T extends object> {
  columns: Column<T>[];
  data: Array<any>;
  onOrderModalClick?: onOrderClickFunc;
  loading?: boolean;
  pagination?: boolean;
  renderPage?: string;
  pageSize?: number;
  game?:any;
  statusMessage?: React.ReactNode;
}

export function Table<T extends {}>({
  columns,
  data,
  loading = false,
  pagination = false,
  onOrderModalClick,
  renderPage,
  pageSize,
  game,
  statusMessage,
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageSize: pageSize ? pageSize : 20 },
    },
    useSticky,
    useSortBy,
    useExpanded,
    usePagination
  );
  const CustomMinWidth = ['bS1', 'tif', 'q', 'p', 't'];
  // const isRegStatus = game && game.regstatus ?  (<span>【校園爭霸賽】即日起~5.19<br /> 請點選左上方功能列報名</span>)  : '競賽報名已截止，歡迎體驗【模擬交易】';

  return (
    <>
      <BTable
        {...getTableProps()}
        striped
        bordered
        hover
        size="sm"
        responsive
        variant="light"
        key="BTableWrap"
      >
        <thead key="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                  className={
                    column.id === 'expander'
                      ? cx('expander', 'whiteBorderExpand')
                      : CustomMinWidth.includes(column.id)
                      ? cx('tableHeaderCustom-minWidth50')
                      : cx('tableHeaderCustom')
                  }
                  style={
                    column.id === 'expander' && renderPage === 'orderInquire'
                      ? {
                          position: 'sticky',
                          zIndex: '3',
                          left: '0px',
                          border: '0',
                          borderWidth: '0',
                        }
                      : column.id === 'name' && renderPage === 'orderInquire'
                      ? {
                          position: 'sticky',
                          zIndex: '3',
                          left: '28.9px',
                          border: '0',
                          borderRight: '2px solid white',
                        }
                      : column.id === 'action' && renderPage === 'inv_columns'
                      ? {
                          position: 'sticky',
                          zIndex: '3',
                          left: '0px',
                          border: '0px',
                        }
                      : column.id === 'ranking' && renderPage === 'leaderboard'
                      ? {
                          minWidth: '50px',
                        }
                      : column.id === 'ranking_name' &&
                        renderPage === 'leaderboard'
                      ? {
                          minWidth: '180px',
                        }
                      : {}
                  }
                >
                  <div className={cx('table-headerColumn-flex')}>
                    <div
                      className={cx(
                        column.id === 'expander'
                          ? 'expander'
                          : 'tableHeaderWarp'
                      )}
                    >
                      {column.render('Header')}
                      <div
                        className={
                          column.canSort
                            ? cx('arrow-group')
                            : cx('arrow-disable')
                        }
                      >
                        <div
                          className={cx(
                            'up-arrow',
                            column.isSorted && column.isSortedDesc
                              ? 'up-arrow-action'
                              : ''
                          )}
                        />
                        <div
                          className={cx(
                            'down-arrow',
                            column.isSorted && !column.isSortedDesc
                              ? 'down-arrow-action'
                              : ''
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} key="tbody">
          {!loading &&
            page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <>
                  <tr {...row.getRowProps()} className={cx('tablebodyCustom')}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={cx(
                            cell.column.id === 'expander'
                              ? 'whiteBorderExpand'
                              : '',
                            cell.column.id.includes('custommizeClassName')
                              ? cell.column.id.split('_')[1]
                              : ''
                          )}
                          style={
                            cell.column.id === 'expander' &&
                            renderPage === 'orderInquire'
                              ? {
                                  position: 'sticky',
                                  zIndex: '3',
                                  left: '0px',
                                  border: '0px',
                                  borderWidth: '0',
                                }
                              : cell.column.id === 'name' &&
                                renderPage === 'orderInquire'
                              ? {
                                  position: 'sticky',
                                  zIndex: '3',
                                  left: '28.9px',
                                  border: '0px',
                                }
                              : cell.column.id === 'action' &&
                                renderPage === 'inv_columns'
                              ? {
                                  position: 'sticky',
                                  zIndex: '3',
                                  left: '0px',
                                  border: '0px',
                                }
                              : {}
                          }
                          onClick={() => {
                            cell.column.id === 'expander' &&
                              setTimeout(() => {}, 50);
                          }}
                          key={cell.column.id}
                        >
                          <BottomBlinker
                            key={cell.column.id}
                            className={cx('')}
                            compareValue={cell.value}
                          >
                            {cell.render('Cell')}
                          </BottomBlinker>
                        </td>
                      );
                    })}
                  </tr>
                  {rows[i].isExpanded &&
                    SubRows({ row, rowProps, data: data[i].detail })}
                </>
              );
            })}
        </tbody>
      </BTable>
      {loading && (
        <div className={cx('paginatioWrap')}>
          <Spinner animation="border" />{' '}
        </div>
      )}
      {!loading && page.length === 0 && (
        <div className={cx('paginatioWrap', 'text-secondary')}>{statusMessage}</div>
      )}
       {renderPage === 'leaderboard' && (
         <div className={cx('updateTime')}>
        榜單盤中不更新，每日收盤15:00後更新
       </div>
      )}
      {page.length > 0 && pagination && (
        <div className={cx('paginatioWrap')}>
          <Pagination>
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            {pageOptions.length > 0 && (
              <Pagination.Item
                onClick={() => gotoPage(0)}
                active={pageIndex === 0}
              >
                {1}
              </Pagination.Item>
            )}
            {pageOptions.length > 1 && (
              <Pagination.Item
                onClick={() => gotoPage(1)}
                active={pageIndex === 1}
              >
                {2}
              </Pagination.Item>
            )}
            {pageOptions.length > 2 && pageIndex <= 5 && (
              <Pagination.Item
                onClick={() => gotoPage(2)}
                active={pageIndex === 2}
              >
                {3}
              </Pagination.Item>
            )}
            {pageIndex < 4 && pageOptions.length > 3 && (
              <Pagination.Item
                onClick={() => gotoPage(3)}
                active={pageIndex === 3}
              >
                {4}
              </Pagination.Item>
            )}

            {pageIndex !== 3 && pageIndex > 4 && pageOptions.length >= 3 && (
              <Pagination.Ellipsis />
            )}
            {pageIndex < 3 && pageIndex > 6 && (
              <Pagination.Item
                onClick={() => gotoPage(pageIndex - 2)}
                active={pageIndex - 2 === pageIndex}
              >
                {pageIndex - 1}
              </Pagination.Item>
            )}
            {pageIndex === 4 && (
              <Pagination.Item
                onClick={() => gotoPage(pageIndex - 1)}
                active={pageIndex - 1 === pageIndex}
              >
                {pageIndex}
              </Pagination.Item>
            )}
            {pageIndex >= 4 && (
              <Pagination.Item
                onClick={() => gotoPage(pageIndex)}
                active={pageIndex === pageIndex}
              >
                {pageIndex + 1}
              </Pagination.Item>
            )}
            {pageIndex <= pageOptions.length - 2 && pageIndex >= 3 && (
              <Pagination.Item
                onClick={() => gotoPage(pageIndex + 1)}
                active={pageIndex + 1 === pageIndex}
              >
                {pageIndex + 2}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </div>
      )}
    </>
  );
}
///目前子項目只供委託查詢使用
function SubRows({ row, rowProps, data }: any) {
  return (
    <>
      {data.map((x: any, i: any) => {
        // debugger
        return (
          <tr
            className={cx('')}
            {...rowProps}
            key={`${rowProps.key}-expanded-${i}`}
          >
            {row.cells.map((cell: any) => {
              if (cell.column.id === 'qty') {
                return (
                  <td className={cx('subrow')}>
                    {
                      <>
                        <div>
                          {Digits2NumberFormatter(x.price.toString())}
                          <br />
                          {(+x.qty).toLocaleString('en-US')}
                        </div>
                      </>
                    }
                  </td>
                );
              }
              if (cell.column.id === 'dt') {
                return (
                  <td className={cx('subrow')}>
                    <div>
                      {x.time ? dayjs(x.time).format('MM/DD') : '--'}
                      <br />
                      {x.time ? dayjs(x.time).format('HH:mm:ss') : '--'}
                    </div>
                  </td>
                );
              }
              return (
                <td
                  {...cell.getCellProps()}
                  className={cx('noBorder', 'subrow')}
                  style={
                    cell.column.id === 'expander'
                      ? {
                          position: 'sticky',
                          zIndex: '3',
                          left: '0px',
                          borderWidth: '0',
                        }
                      : cell.column.id === 'sym'
                      ? {
                          position: 'sticky',
                          zIndex: '3',
                          left: '29px',
                        }
                      : {}
                  }
                ></td>
              );
            })}
          </tr>
        );
        
      })}
    </>
  );
}
const Digits2NumberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
