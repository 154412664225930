import React from "react";
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    LineShareButton,
} from "react-share";
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";

import classNames from 'classnames/bind';
import style from './index.module.css';
const cx = classNames.bind(style);



interface ModelProps {
    isOpen: boolean;
    handleClose: () => void;
    istop6K: boolean | null;
    isPrize: boolean | null;
    invitationCode: string | null;

}

const ShareModel: React.FC<ModelProps> = ({
    isOpen,
    handleClose,
    istop6K,
    invitationCode,
    isPrize,

}) => {

    const shareUrl = `${process.env.REACT_APP_URL}#/home?inviteCode=${invitationCode ? invitationCode : ""}`
    const title = invitationCode ? '歡迎一起參加權證模擬交易抽大獎,我的邀請碼:' + `${invitationCode}` : "";
    return (
        <Modal
            isOpen={isOpen}
            contentLabel=""
            onRequestClose={handleClose}
            className="ShareModal"
            overlayClassName="Overlay"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
        >
            <>
                <div className={cx("modalTitleWrap")}>
                    <div className={cx("modalTitle")}>
                        <img src={require('../../static/image/modelCoin.png')} />
                    </div>
                    <div className={cx("modalRight")}>
                        <button className={cx("modalCloseButton")} onClick={handleClose} >
                            <span className={cx("modalCloseButtonFont")}>&times;</span>
                        </button>
                    </div>
                </div>

                {
                 isPrize ?
                 <>
                    <div>
                        <div className={cx("modalContent")} style={{ fontSize: "18px", color: "#000", fontWeight: "bolder" }}>
                            <img width='30%'  src={require('../../static/image/GetLinetext.png')} />
                        </div>
                        <div className={cx("modalContent", "sharebtn",'flex-wrap','mt-3')}>
                            <img width='80%'  src={require('../../static/image/shareWinPrize.png')} />
                            <img width='30%' className="mt-3" src={require('../../static/image/shareWinPrizeIcon.png')} />
                        </div>
                        <div className={cx("text-lightblue")}>
                           星享飲料券預計於五個工作天內透過簡訊發送
                        </div>
                        <div className={cx("modalContent", "shareText", "text-black")}>
                            立即分享連結，邀請好友並成功註冊，還可抽iPhone或SOGO禮券！<br />邀請好友越多，中獎機會越大！
                        </div>
                    </div>
                </> :
                // <>
                //     <div>
                //         <div className={cx("modalContent",'my-3')}>
                //             <img width='80%' src={require('../../static/image/shareWinPrizeExchangeNone.png')} />

                //         </div>
                //         <div className={cx("modalContent", "shareText")}>
                //             別灰心！還有獎勵任務等你來挑戰，<br />有機會獲得iPhone14、Apple Watch與HomePod mini等精美大獎哦！
                //         </div>
                //     </div>
                // </>
                // 等7/1換這塊
                <>
                    <div>
                        <div className={cx("modalContent",'mt-3','mb-5')}>
                            <img  width='80%' src={require('../../static/image/shareWinPrizeNotTaskTime.png')} />

                        </div>
                    </div>
                </>
                }
                <div className='sharemodalFoot'>
                    <div className={cx("wrap")}>
                        <div className={cx("shareurlText")}>專屬連結<br />{shareUrl}</div>
                    </div>
                    <div className={cx("wrap")}>

                        <CopyToClipboard text={shareUrl} onCopy={() => {
                            toast.info(`已複製連結！`, {
                                position: "top-right",
                                autoClose: 1300,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }} >
                            <Button className={cx("copyurl")}>
                                複製連結
                            </Button>
                        </CopyToClipboard>
                    </div>
                    <div className={cx("wrap")}>
                        <FacebookShareButton
                            className={cx("share-btn")}
                            url={shareUrl}
                            quote={title}
                        >
                            <span className={cx("icon")}>
                                <img src={require('../../static/image/FB.png')} />
                            </span>
                        </FacebookShareButton>

                        <LineShareButton
                            className={cx("share-btn")}
                            url={shareUrl}
                            title={title}
                        >
                            <span className={cx("icon")}>
                                <img src={require('../../static/image/LINE.png')} />
                            </span>

                        </LineShareButton>

                    </div>
                </div>
            </>
        </Modal >
    );
}

export default ShareModel;