import React from "react";
import { useSetState, useMountedState } from "react-use";
import { merge } from "rxjs";
import { bufferTime, filter, map } from "rxjs/operators";
import intersection from "lodash/intersection";

import { AsyncState } from "react-use/lib/useAsync";
import { Quote } from "../model/Quote";
import { TickMessage, BidAskMessage, BnsMessage } from "../websocket/quote";

import api from "../api";
import isVirtualSymbol from "../tools/isVirtualSymbol";
import { TickSubject, BidAskSubject, BnsSubject } from "../websocket/quote";

export interface UseSingleQuoteParams {
  symbol: string;
  sessionId: string;
}

export const useSingleQuote = function ({
  symbol,
  sessionId,
}: UseSingleQuoteParams): AsyncState<Quote> {
  const isMount = useMountedState();
  const [state, setState] = useSetState<AsyncState<Quote>>({ loading: true });
  const priceDecimalRef = React.useRef<number>(0);
  const aliasSymbolRef = React.useRef("");

  React.useEffect(() => {
    (async function getQuotes() {
      try {
        if (sessionId === "" || symbol === "") {
          return;
        }

        setState({ loading: true });
        const quotes = await api.getQuotes({
          symbols: symbol,
          sessionId: sessionId,
        });

        if (!isMount()) {
          return;
        }

        if (quotes.length < 1) {
          throw new Error("無法取得報價");
        }

        const quote = quotes[0];
        priceDecimalRef.current = quote.PriceDec || 0;
        if (isVirtualSymbol(symbol)) {
          aliasSymbolRef.current = quote.AliasSymbol || "";
        }
        setState(() => ({ loading: false, value: quote, error: undefined }));
      } catch (error) {
        const err = error instanceof Error ? error : new Error("無法取得報價");
        setState(() => ({ loading: false, error: err, value: undefined }));
      }
    })();
  }, [symbol, sessionId, isMount, setState]);

  const { loading, error } = state;
  React.useEffect(() => {
    if (loading || error) {
      return;
    }

    const regex = /\.(A|D)\./;
    const isTickMessage = (message: any): message is TickMessage =>
      message.open !== undefined;
    const isBnsMessage = (message: any): message is BnsMessage =>
      message[11000] === "BNS";
    const subscriber = merge(TickSubject, BidAskSubject, BnsSubject)
      .pipe(
        filter((message) => {
          if (isBnsMessage(message)) {
            const { 11000: mode } = message;
            return mode === "BNS";
          }

          const { symbol: messageSymbol } = message;
          /** Remove ".A." and ".D." */
          const messageSymbolWithoutDay = messageSymbol.replace(regex, ".");
          const rlt =
            intersection(
              [symbol, aliasSymbolRef.current],
              [messageSymbol, messageSymbolWithoutDay]
            ).length > 0;
          return rlt;
        }),
        bufferTime(1000 / 2),
        filter((messages) => messages.length > 0),
        map<(TickMessage | BidAskMessage | BnsMessage)[], Partial<Quote>>(
          (messages) => {
            const digitMover = (() => {
              const delimiter = Math.pow(10, priceDecimalRef.current);
              return (value: number) => value / delimiter;
            })();
            const updateQuote = messages.reduce<Partial<Quote>>(
              (acc, message) => {
                let updateData: Partial<Quote>;

                if (isTickMessage(message)) {
                  updateData = {
                    Open: digitMover(message.open),
                    Price: digitMover(message.price),
                    TotalVolume: message.totalVolume,
                    UpDown: digitMover(message.upDown),
                    UpDownRate: message.upDownRate / 100,
                    High: digitMover(message.high),
                    Low: digitMover(message.low),
                    PrePrice: digitMover(message.prePrice),
                    BuyCount: message.buyCount,
                    SellCount: message.sellCount,
                    Volume: message.volume.toString(),
                    lastCoverTickTime: new Date(message.tickTime),
                  };
                } else if (isBnsMessage(message)) {
                  updateData = {
                    ...acc,
                    upprice: message.upprice,
                    downprice: message.downprice,
                    target_name_2: message.target_name_2,
                    target_money: message.target_money,
                    target_upprice: message.target_upprice,
                    target_downprice: message.target_downprice,
                  };
                } else {
                  const Bids =
                    message.Bid.length > 0
                      ? message.Bid.map(({ Price, Vol }) => ({
                        Price: digitMover(parseFloat(Price)),
                        Volume: parseInt(Vol),
                      }))
                      : [];
                  const Asks =
                    message.Ask.length > 0
                      ? message.Ask.map(({ Price, Vol }) => ({
                        Price: digitMover(parseFloat(Price)),
                        Volume: parseInt(Vol),
                      }))
                      : [];
                  const APrice = (Asks.length > 0 && Asks[0].Price) || null;
                  const AVol = (Asks.length > 0 && Asks[0].Volume) || null;
                  const BPrice = (Bids.length > 0 && Bids[0].Price) || null;
                  const BVol = (Bids.length > 0 && Bids[0].Volume) || null;

                  updateData = {
                    ...acc,
                    AskPrice: APrice,
                    AskVolume: AVol,
                    BidPrice: BPrice,
                    BidVolume: BVol,
                    Bids: Bids,
                    Asks: Asks,
                  };
                }

                return { ...acc, ...updateData };
              },
              {}
            );
            console.log("updateQuote", updateQuote);
            return updateQuote;
          }
        )
      )
      .subscribe((updateQuote) => {
        if (!isMount()) {
          return;
        }
        setState((prev) => ({
          value: { ...prev.value!, ...updateQuote },
        }));
      });

    return () => {
      subscriber.unsubscribe();
    };
  }, [symbol, loading, error, setState, isMount]);

  return state;
};

export default useSingleQuote;
