import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export interface OnhandParameters {
  cu_id: string;
}
export interface OnhandQueryResponse {
  message?: string;
  err: string;
  /**
   *現金資產
   */
  csh: number;
  /**
   *權證資產
   */
  stk: number;
  /**
   *淨資產
   */
  nat: number;
  /**
   *獲利
   */
  pf: number;
  /**
   *總報酬率
   */
  roi: number;

  inv: invType[];
}
export interface asset {
  csh: number;
  /**
   *權證資產
   */
  stk: number;
  /**
   *淨資產
   */
  nat: number;
  /**
   *獲利
   */
  pf: number;
  /**
   *總報酬率
   */
  roi: number;
}
export interface invType {
  /**
   *權證代號
   */
  sym: string;
  /**
   *權證名稱
   */
  name: string;
  /**
   *張數
   */
  qty: number;
  /**
   *買賣均價
   */
  pa: number;
  /**
   *現價
   */
  pc: number;
  /**
   *買入成本
   */
  bc: number;
  /**
   *市值未稅
   */
  mV1: number;
  /**
   *手續費
   */
  fee: number;
  /**
   *稅金
   */
  tax: number;
  /**
   *市值
   */
  mV2: number;
  /**
   *損益
   */
  pf: number;
  /**
   *報酬率
   */
  roi: number;
  /**
   *剩餘天數
   */
  sd: string;
  t_money: number;
  t_up_stop: number;
  t_down_stop: number;
  action?: any;
  s_stats?: number;
}

export const getOnhandQuery = async (
  axios: AxiosInstance,
  { cu_id }: OnhandParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const QueryTime = dayjs().format("YYYY/MM/DD HH:mm:ss");
  try {
    const { data } = await axios.post<OnhandQueryResponse>(
      "api2/Billing/Onhand",
      requestData,
      { headers }
    );
    if (!data) {
      return {
        err: "9999",
        csh: 0,
        stk: 0,
        nat: 0,
        pf: 0,
        roi: 0,
        inv: [],
        QueryTime,
      };
    }

    if (data.err !== "0000") {
      toast.warn(`資產總計${":(" + data.err + ")" + (data.message || "")}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { QueryTime, ...data };
  } catch (error) {
    return {
      err: "9999",
      csh: 0,
      stk: 0,
      nat: 0,
      pf: 0,
      roi: 0,
      inv: [],
      QueryTime,
    };
  }
};
