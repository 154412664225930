import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as Icons from '../../icons';
import classNames from 'classnames/bind';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';
import api from '../../api';
import './select-search.css';
import { REGEX_DEPARTMENT, REGEX_PROFESSOR, TAIWAN_ID_REGEX } from '../../constants';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { schoolListResponse, schoolData } from '../../api/newApi/getSchoolList';
import AleartModel from '../AleartModel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const cx = classNames.bind(style);

interface ApplyQueryItem {
  code: string;
  school_id: string | null;
  student_id: string | null;
  professor: string | null;
  user_id: string | null;
  act_type: string;
}

interface AgeOption {
  value: number;
  name: string;
}
interface FormStates {
  [key: string]: {
    school_id: string;
    departmentName: string;
    professor: string;
    check: boolean;
    user_id: string;
  };
}
interface CampaignItem {
  code: string;
  name: string;
  regstatus: boolean;
  signed: boolean;
  Professor?: boolean;
  user_id: string;
  camp_type:  number;  //0: 個人 1:團體
  init_cash: number;
  reg_start: string;
  reg_end: string;
  act_type: 'S' | 'C' | 'N' | null;  // S: 校園 C: 企業 N: 個人 
}

interface ShareModelProps {
  isOpen: boolean;
  handleClose: () => void;
  schoolList: Array<schoolData> | [];
  cuid: string;
  loginInfo: any;
  game: any;
}

interface SingleCampaignInfo {
  isSubmit: boolean;
  student_id: string | number | null;
  school_id: string;
  professor: string;
  user_id: string;
  game?: any;
}

const CampaignSubmitModel: React.FC<ShareModelProps> = ({
  isOpen,
  handleClose,
  schoolList,
  cuid,
  loginInfo,
  game,


}) => {
  const [singleSubmit, setSingleSubmit] = useState(true);
  const [groupSubmit, setGroupSubmit] = useState(false);
  const [userID, setUserID] = useState('');
  const [output, setOutput] = useState<React.ReactNode>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [schoolChoose, setSchoolChoose] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [teamCreateName, setTeamCreateName] = useState('');
  const [teamJoinId, setTeamJoinId] = useState('');
  const [check, setCheck] = useState(false);
  const [openAlertModel, setOpenAlertModel] = useState(false);
  const [radioType, setRadioType] = useState('groupCreate');
  const [isLineShareModel, setIsLineShareModel] = useState(false);
  const [openSingleItems, setOpenSingleItems] = useState<{ [key: string]: boolean }>({});
  const [formStates, setFormStates] = useState<FormStates>({});
  const [filteredGames, setFilteredGames] = useState<CampaignItem[]>([]);
  const [initialCode, setInitialCode] = useState<string | null>(null);
  const [decodedCode, setDecodedCode] = useState<string | null>(null);
  const [showCheckConfirm, setShowCheckConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CampaignItem | null>(null);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState(false);
  const [kgiGust, setKgiGust] = useState<string | null>(null);
  const [successName, setSuccessName] = useState<string | null>(null);
  const [campaignData, setCampaignData] = useState<CampaignItem[]>([]);
  const [responseUserId, setResponseUserId] = useState<string>(''); //回傳的身分證字號
  const [ageOptions, setAgeOptions] = useState<AgeOption[]>([]); // 年齡選項
  const [hasBlurred, setHasBlurred] = useState<{ [key: string]: boolean }>({});// 年齡的blur
  const [isChecking, setIsChecking] = useState(false); // 檢查身分證字號的過程


  const [teamCreateData, setTeamCreateData] = useState({
    teamName: '',
    teamCode: '',
    leaderName: '',
  });
  const [sumbitError, setSumbitError] = useState<string[]>([]);
  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    isSubmit: false,
    student_id: '',
    school_id: '',
    professor: '',
    user_id: '',
  });
  const [teamCampaignInfo, setTeamCampaignInfo] = useState<any>({
    isSubmit: false,
    student_id: '',
    school_id: '',
    professor: '',
    team_name: '',
    team_code: '',
  });
  const copyBoardText = `${teamCampaignInfo?.team_name} 邀請你一起參加【凱基股神大賽】。 \n 請下載【隨身e策略】，點擊APP首頁【投資實驗室】，報名輸入隊伍代碼： ${teamCampaignInfo?.team_code} ，比賽開始前完成線上開戶，凱基客戶獨享獎金翻倍！  \n https://www.kgieworld.com.tw/ExternalFiles/mobile/WFLinkApp.aspx?rUrl=kgiA`;
  const errMsg = React.useRef({
    wrongSchool: '*請選擇就讀學校',
    wrongDepartment: '*就讀系所名稱格式錯誤或字數已達上限',
    notCheck: '*尚未勾選',
    wrongTeamName: '*團隊名稱格式錯誤或字數已達上限',
    wrongTeacherName: '*指導教授格式錯誤或字數已達上限',
    wrongTeamCode: '*請填寫完整團隊代碼',
    wrongUserId: '請填寫完整用戶ID',
    wrongUserName: '必填',
    wrongUserAge: '請選擇年齡',
  });

  //取得競賽中所有資料(Array)
  const fetchData = async (c: CampaignItem) => {
    try {
      const response = await api.applyQuery({ cu_id: cuid });
      console.log('API Response:', response);
      if (response.kgi_gust !== 'X') {
        setCheckButtonDisabled(true);
      }
      if (response.kgi_gust) {
        setKgiGust(response.kgi_gust);
      } else {
        console.warn('kgi_gust is null or undefined');
      }
      if (response.data && Array.isArray(response.data)) {
        setResponseUserId(response.id || '');
        setCampaignData(response.data);
        const formValues: FormStates = {};
        response.data.forEach((item: ApplyQueryItem) => {
          console.log('Processing item:', item);
          let schoolName = '';
          const campaignItem = filteredGames.find(game => game.code === item.code);
  
          if (campaignItem && campaignItem.act_type === 'C') {
            // 確保 ageOptions 已經加載完成
            if (ageOptions.length > 0) {
              const ageGroup = ageOptions.find((v) => v.value === parseInt(item.school_id || '0', 10));
              schoolName = ageGroup ? ageGroup.name : '';
            } else {
              // 如果 ageOptions 未加載完成，先設置為空字符串
              schoolName = '';
            }
          } else {
            const schoolIdNumber = item.school_id ? parseInt(item.school_id, 10) : null;
            const school = schoolList.find((v) => v.key === schoolIdNumber);
            schoolName = school?.name || '';
          }
          formValues[item.code] = {
            school_id: schoolName,
            departmentName: item.student_id || '',
            professor: item.professor || '',
            user_id: response.id || '',
            check: false,
          };
        });
  
        setFormStates(formValues);
        console.log('Form values:', formValues);
      } else {
        toast.error('無法獲取競賽數據，請稍後重試');
      }
  
      console.log('response.kgi_gust:', response.kgi_gust);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('無法獲取競賽數據，請稍後重試');
    }
  };
  
  useEffect(() => {
    if (isOpen && filteredGames.length > 0 && ageOptions.length > 0) {
      const selectedCampaign = filteredGames[0];
      fetchData(selectedCampaign);
    }
  }, [isOpen, cuid, filteredGames, ageOptions]);
  
const handleInputChange = (code: string, field: string, value: string | boolean) => {
  setFormStates(prev => ({
    ...prev,
    [code]: {
      ...prev[code],
      [field]: value
    }
  }));
    // 如果有錯誤消息，選擇後應該清除錯誤消息
    setSumbitError(sumbitError.filter(err => !(field === 'school_id' && err === 'wrongUserAge')));
  };
  //送出表單 
  const handleSubmit = async (item: CampaignItem, code: string) => {
    if (kgiGust === 'X') {
      toast.error('請先點擊檢核按鈕');
      return;
    }
    if (!item.regstatus || isSubmitted || item.signed) {
      console.log('條件不允許提交');
      toast.error("競賽報名失敗");
      return;
    }
    // 收集表單數據
    const data: any = {
      code: item.code,
      email: loginInfo.email,
      phone: loginInfo.phone || "",
      check: formStates[code]?.check || false,
      school_id: formStates[code]?.school_id || "", // 在企業 type=C 時，school_id 為年齡選項
      student_id: formStates[code]?.departmentName || "", // 在企業 type=C 時，student_id 為年齡
      professor: formStates[code]?.professor || "", // 在企業 type=C 時，professor 為備註
    };
    if (item.act_type !== "N") {
      data.school_id = formStates[code]?.school_id || "";
      data.student_id = formStates[code]?.departmentName || "";
      data.professor = formStates[code]?.professor || "";
    }
    console.log('提交的數據:', data);
    // 數據驗證
    let errors: string[] = [];
    //這是校園表單送出驗證
    if (item.act_type === "S") {
      if (!data.school_id) errors.push("請選擇學校。");
      if (!data.student_id) errors.push("請填寫系所名稱。");
      if (!REGEX_DEPARTMENT.test(data.student_id)) errors.push("系所名稱格式不正確。");
    }
    //這是企業表單送出驗證
    if (item.act_type === "C") {
      if (!data.school_id) errors.push("請選擇年齡。");
      if (!data.student_id) errors.push("請填寫姓名。");
    }
    if ((kgiGust !== 'Y' && kgiGust !== 'N') && !TAIWAN_ID_REGEX.test(data.user_id)) errors.push("身分證格式有誤");
    if (!data.check) errors.push("個資聲明未勾選");
    // 根據驗證結果處理
    if (errors.length > 0) {
      errors.forEach(error => toast.error(error));
      return;
    }
    try {
      const response = await api.applyCampaign(data);
      setCheckButtonDisabled(true);
      setIsSubmitted(true);
      console.log('API Response:', response);
      if (response.err === '0000') {
        setOpenAlertModel(true);
        setSuccessName(item.name);
        clearURLParams();
        const campaign = campaignData.find(g => g.code === code);
        if (campaign) {
          setSuccessName(campaign.name);
        }
        setOpenAlertModel(true);
        toast.success("報名競賽成功！");
      } else {
        toast.error(`報名失敗: ${response.err}`);
      }
    } catch (error) {
      toast.error('報名競賽失敗，請稍後重試');
    }
  };
//清除input
  const reset = () => {
    setUserID('');
    setSchoolChoose('');
    setDepartmentName('');
    setTeacherName('');
    setCheck(false);
    setTeamJoinId('');
    setTeamCreateName('');
    setRadioType('groupCreate');
    setSumbitError([]);
    setTeamCreateData({
      teamName: '',
      teamCode: '',
      leaderName: '',
    });
  };

  //清除
  const resetAllStates = () => {
    setIsSubmitted(false);
    setSingleSubmit(false);
    setGroupSubmit(false);
  }

function decodeBase64(base64: string): string {
  try {
    const binaryString = atob(base64);
    const utf8Decoder = new TextDecoder('utf-8');
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    
    return utf8Decoder.decode(binaryArray);
  } catch (e) {
    console.error('Failed to decode Base64 string:', e);
    return '';
  }
}

  const getGroupCampaignInfo = async () => {
    const res = await api.teamQuery({ cu_id: cuid });
    if (res.err === '0000' && res.team_code) {
      const school = schoolList.find((v) => v.key === res.school_id);
      console.log(school)
      setTeamCampaignInfo({
        isSubmit: true,
        student_id: res.student_id,
        school_id: school?.name,
        professor: res.professor,
        team_name: res.team_name,
        team_code: res.team_code,
      });
    }
  };
  //toggle 進去展開對應表單
  useEffect(() => {
    if (initialCode && filteredGames.length > 0) {
      const matchedGame = filteredGames.find((game) => game.code === initialCode);
      if (matchedGame) {
        toggleSingleItem(matchedGame.code);
      }
    }
  }, [initialCode, filteredGames]);
  
// 競賽表單渲染 step1
  useEffect(() => {
    const localStorageCode = localStorage.getItem('code');
    if (localStorageCode) {
        try {
            const decoded = decodeBase64(localStorageCode);
            setDecodedCode(decoded);
            setInitialCode(decoded);
        } catch (error) {
            console.error('Error decoding Base64:', error);
            setDecodedCode(''); 
        }
    } else {
        console.error('localStorage code is missing.');
        setDecodedCode(''); 
    }
}, []);

useEffect(() => {
    if (decodedCode) {
        const newFilteredGames = game.filter((g: CampaignItem) =>
            g.code.startsWith('KGI') &&
            (g.code === decodedCode || g.signed === true)
        );
        setFilteredGames(newFilteredGames);

        if (decodedCode) {
            setInitialCode(decodedCode);
        }
    }
}, [game, decodedCode]);

  

  //schoolList 出現年齡選項
  useEffect(() => {
    if (!singleSubmit) {
      reset();
    }
    if (!groupSubmit) {
      reset();
    }
    if (singleSubmit && singleCampaignInfo.isSubmit) {
      setSchoolChoose(singleCampaignInfo.school_id);
    }
  }, [singleSubmit, groupSubmit]);
  // 打開表單時，清除之前的訊息
  useEffect(() => {
    if (isOpen) {
      setIsSubmitted(false);
      resetAllStates();
    }
  }, [isOpen]);
    // 用戶ID
  useEffect(() => {
    setSingleCampaignInfo((prevInfo: SingleCampaignInfo) => ({
      ...prevInfo,
      user_id: userID,
    }));
  }, [userID]);

  useEffect(() => {
    if (isOpen && cuid) {
      getGroupCampaignInfo();
    }
  }, [isOpen, isLineShareModel, openAlertModel]);
  // 年齡的api
  useEffect(() => {
    const fetchAgeGroupList = async () => {
      try {
        const response = await fetch('/api2/Warrant/AgeGroupList', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({})
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Age Group List:', data);

        const ageOptions = data.data.map((item: { id: number, name: string }) => ({
          value: item.id,
          name: item.name,
        }));
        setAgeOptions(ageOptions);
      } catch (error) {
        console.error('Failed to fetch Age Group List:', error);
      }
    };

    if (isOpen) {
      fetchAgeGroupList();
    }
  }, [isOpen]);
  // 開展收合
  const toggleSingleItem = (code: string) => {
    setOpenSingleItems(prev => ({
      ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
      [code]: !prev[code]
    }));
  };

  const getTeamNameQuery = async () => {
    const res = await api.teamNameQuery({ cu_id: cuid, team_code: teamJoinId });
    if (res.err === '0000') {
      setTeamCreateData({ ...teamCreateData, teamName: res.data.team_name });
    }
    return res;
  };
  //清除URL競賽參數
  const clearURLParams = () => {
    try {
      const url = new URL(window.location.href);
      const pathname = url.pathname;
      window.history.pushState({}, '', pathname);
    } catch (error) {
      console.error('URL parameters:', error);
    }
  };
  // 團體組的送出表單
  const CampaignSubmit = async (item: any) => {
    const codeFromURL = new URLSearchParams(window.location.search).get('code') || 'KGI01';
    let errData: string[] = [];
    // 清空之前的錯誤訊息
    setSumbitError([]);
    console.log('Starting CampaignSubmit');

    if (singleSubmit) {
      if (singleCampaignInfo.isSubmit) {
        return;
      }
      if (!TAIWAN_ID_REGEX.test(userID)) {
        errData.push('wrongUserId');
        console.log('用戶ID錯誤');
      }
      if (schoolChoose === '') {
        errData.push('wrongSchool');
        console.log('學校錯誤');
      }
      if (departmentName === '' || !REGEX_DEPARTMENT.test(departmentName)) {
        errData.push('wrongDepartment');
        console.log('科系錯誤');
      }

      if (!REGEX_PROFESSOR.test(teacherName)) {
        errData.push('wrongTeacherName');
        console.log('教授錯誤');
      }

      if (!check) {
        errData.push('notCheck');
        console.log('未勾選條款');
      }
      setSumbitError(errData);

      // 如果有錯誤，則終止提交
      if (errData.length > 0) {
        console.log('Errors present:', errData);
        return;
      }
      
      const data = {
        code: codeFromURL,
        email: loginInfo.email,
        school_id: Number(schoolChoose),
        student_id: departmentName,
        professor: teacherName,
        phone: loginInfo.phone,
        user_id: userID,
      };
      console.log('Submitting data:', data);
      const res = await api.applyCampaign(data);
      if (res.err === '0000') {
        clearURLParams();
        setIsSubmitted(true);
        setOpenAlertModel(true);
      } else {
        console.log('Error submitting:', res.err);
      }
    } else {
      if (radioType === 'groupCreate') {
        if (
          schoolChoose === '' ||
          departmentName === '' ||
          !REGEX_DEPARTMENT.test(departmentName) ||
          teamCreateName === '' ||
          teamCreateName.length > 10 ||
          !check
        ) {
          errData.push('groupCreateError');
          console.log('組隊創建錯誤');
          setSumbitError(errData);
          return;
        }

        const data = {
          cu_id: cuid,
          email: loginInfo.email,
          school_id: Number(schoolChoose),
          student_id: departmentName,
          professor: teacherName,
          team_name: teamCreateName,
        };

        const getUserNamePayload = {
          code: 'RealTime',
          email: loginInfo.email,
          phone: loginInfo.phone,
        };

        const getUserName = await api.applyCampaign(getUserNamePayload);
        console.log('getUserName', getUserName);
        const res = await api.teamCreate(data);
        setOpenAlertModel(false);
        if (res.err === '0000') {
          console.log('teamData', res);
          setTeamCreateData({
            teamName: res.team_name as string,
            teamCode: res.data as string,
            leaderName: getUserName.user_name as string,
          });

          setIsLineShareModel(true);
          setOpenAlertModel(true);
          clearURLParams();
        }
      } else {
        if (
          schoolChoose === '' ||
          departmentName === '' ||
          teamJoinId === '' ||
          !check ||
          !REGEX_DEPARTMENT.test(departmentName)
        ) {
          errData.push('groupJoinError');
          console.log('組隊加入錯誤');
          setSumbitError(errData);
          return;
        }

        const data = {
          cu_id: cuid,
          team_code: teamJoinId,
          school_id: Number(schoolChoose),
          student_id: departmentName,
          professor: teacherName,
          user_id: userID,
        };
        console.log('Submitting team join data:', data);
        const res = await api.teamJoin(data);
        setOpenAlertModel(false);
        if (res.err === '0000') {
          reset();
          setIsLineShareModel(true);
          setOpenAlertModel(true);
        } else {
          console.log('Error', res.err);
        }
      }
    }
  };
  //檢核按鈕的邏輯
  const handleUserIDChange = (event: React.ChangeEvent<HTMLInputElement>, code: string) => {
    const { value } = event.target;
    setUserID(value);
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      [code]: {
        ...prevFormStates[code],
        user_id: value,
      },
    }));
  };
  const handleCheckClick = (campaignInfo: CampaignItem) => {
    if (!TAIWAN_ID_REGEX.test(userID)) {
      toast.error('身分證格式有誤');
      return;
    }
    setSelectedItem(campaignInfo)
    setShowCheckConfirm(true);
  };
  const handleCloseCheckConfirm = () => {
    setShowCheckConfirm(false);
  };
  
  const handleCheck = async (item: CampaignItem) => {
    // 檢核過程中 沒辦法任何動作
    setIsChecking(true);
    //一檢核確認 就disable 身分證input
    setCheckButtonDisabled(true);
    toast.success("檢核中,請稍候");
    try {
      const response = await fetch('api2/Warrant/KGIUserChecker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cu_id: cuid,
          nid: userID,
        }),
       
      });
      if (!response.ok) {
        toast.success("檢核失敗！");
        throw new Error(`${response.status}`);
      }
      toast.success("檢核完成！");
      const data = await response.json();
      if (data.is_cust === 'Y') {
        setKgiGust('Y')
        setOutput(
          <React.Fragment>
            <div
              style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}
              className={cx('mt-3', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}
            >
              <div>凱基證券用戶 <br/>享初始模擬金加碼優惠</div>
            </div>
          </React.Fragment>
        );
      } else if (data.is_cust === 'N') {
        setKgiGust('N')
        setOutput(
          <React.Fragment>
            <div
              style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}
              className={cx('mt-3', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}
            >
              <div>凱基證券用戶 <br />享初始模擬金加碼優惠</div>
            </div>
            <div className={cx('d-flex', 'justify-content-center', 'mt-3')}>
              <button className={cx('btn', 'OpenAccount')}
                onClick={() => window.open('https://kgis.tw/https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401', '_blank')}>立即開戶</button>
            </div>
          </React.Fragment>
        );
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      toast.error('檢核失敗，請稍後重試');
    } finally {
      //不管結果失敗成功 都會解開
      setIsChecking(false);
  }}
  const handleGroupSubmitCheck = () => {
    let errData: string[] = [];
    if (schoolChoose === '') {
      errData = [...errData, 'wrongSchool'];
      setSumbitError(errData);
    } else {
      setSumbitError(sumbitError.filter((item) => item !== 'wrongSchool'));
    }
    if (departmentName === '' || !REGEX_DEPARTMENT.test(departmentName)) {
      errData = [...errData, 'wrongDepartment'];
      setSumbitError(errData);
    } else {
      setSumbitError(sumbitError.filter((item) => item !== 'wrongDepartment'));
    }

    if (teacherName !== '' && !REGEX_PROFESSOR.test(teacherName)) {
      errData = [...errData, 'wrongTeacherName'];
      setSumbitError(errData);
    } else {
      setSumbitError(sumbitError.filter((item) => item !== 'wrongTeacherName'));
    }
    if (!check) {
      errData = [...errData, 'notCheck'];
      setSumbitError(errData);
    } else {
      setSumbitError(sumbitError.filter((item) => item !== 'notCheck'));
    }

    if (radioType === 'groupCreate') {
      if (teamCreateName === '' || teamCreateName.length > 10) {
        errData = [...errData, 'wrongTeamName'];
        setSumbitError(errData);
      } else {
        errData = [...errData].filter((item) => item !== 'wrongTeamName');
        setSumbitError(errData);
      }
    } else if (radioType === 'groupJoin') {
      if (teamJoinId === '') {
        errData = [...errData, 'wrongTeamCode'];
        setSumbitError(errData);
      } else {
        errData = [...errData].filter((item) => item !== 'wrongTeamCode');
        setSumbitError(errData);
      }
    }
  };
  //學校選擇Onchange
  const handleOnchange = (e: any) => {
    setSchoolChoose(e);
  };

  const handleOptionChange = (e: any) => {
    setRadioType(e.target.value);
    setSumbitError(sumbitError.filter((item) => item !== 'wrongTeamName'));
    setSumbitError(sumbitError.filter((item) => item !== 'wrongTeamCode'));
  };

  return (
    <Modal
      closeTimeoutMS={0}
      isOpen={isOpen}
      contentLabel=""
      onRequestClose={handleClose}
      className=""
      overlayClassName="CampaignSubmitModelOverlay"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={cx('d-flex', 'flex-column', 'flex-overlay')}>
        <div className={cx('mainModalTitle')}>
          <div className={cx('modalTitle')}>模擬競賽報名</div>
          <div
            className={cx('modalLeft')}
            onClick={() => {
              reset();
              handleClose();
            }}
          >
            <Icons.ArrowLeft fill={''} width={30} height={30} />
          </div>
        </div>
        <div>
          <div className={cx('modalTitle', 'titleBlue')}>報名專區</div>
        </div>
        {filteredGames.map((item: CampaignItem) => {
          const formState = formStates[item.code] || {};
          //可報名 沒報名過 未送出表單
          const inputEnabled = item.regstatus && !item.signed && !isSubmitted;
          const now = new Date();
          // 報名時間
          const registrationStart = new Date(item.reg_start);
          // 報名截止時間
          const registrationEnd = new Date(item.reg_end);
          // setHours 把報名截止時間設成23:59:59:999
          registrationEnd.setHours(23, 59, 59, 999);

          const btnWord = (() => {
            if (item.signed) {
              return '已報名';
            }
            if (now < registrationStart) {
              return '尚未開放報名';
            }
            if (now >= registrationStart && now < registrationEnd) {
              if (item.regstatus) {
                return '確認';
              }
            }
            if (now >= registrationEnd) {
              return '報名競賽已截止';
            }
            return '';
          })();

          return (
            <div key={item.code} className={cx('px-2', 'submitContent')}>
              {/* 校園個人報名 */}
              {item.camp_type === 0 && item.act_type === 'S' ? (

              
                <div>
                  <div
                    className={cx(
                      'd-flex',
                      'justify-content-between',
                      'section1Bg',
                      'align-items-center'
                    )}
                    onClick={() => {
                      const isSingle = item.camp_type === 0;
                      setSingleSubmit(isSingle ? singleSubmit : false);
                      setGroupSubmit(isSingle ? false : !groupSubmit);
                      toggleSingleItem(item.code);
                    }}
                  >
                    <div className={cx('submitSection', 'ms-1')}>
                      <span>填寫報名資料: {`${item.name}`}</span>
                    </div>
                    <div
                      className={cx(
                        'me-1',
                        'transition',
                        singleSubmit && 'hangleArrowRotate',
                        openSingleItems[item.code] && 'hangleArrowRotate'
                      )}
                    >
                      <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                    </div>
                  </div>
                  {openSingleItems[item.code] && (
                    <div className={cx('d-flex', 'flex-column', 'px-3')}>
                      <div className={cx('mt-3', 'd-flex', 'align-items-center')}>
                        <div className={cx('flex-grow-1', 'me-2')}>
                          <div>*ID</div>
                          <input
                            className={cx(
                              'form-control-plaintext',
                              'boxShadowForInput',
                              'mr-2',
                              'flex-grow-1',
                              { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled) || now > registrationEnd }
                            )}
                            required
                            type="text"
                            value={userID || (singleCampaignInfo.isSubmit ? singleCampaignInfo.user_id : formState.user_id || responseUserId || '')}
                            placeholder='請填寫身分證號碼'
                            maxLength={10}
                            onChange={(e) => handleUserIDChange(e, item.code)}
                            disabled={!inputEnabled || checkButtonDisabled || now > registrationEnd}
                            onBlur={(e) => {
                              if (!TAIWAN_ID_REGEX.test(e.target.value)) {
                                let error = [...sumbitError, 'wrongUserId'];
                                setSumbitError(error);
                              }
                              else {
                                setSumbitError(sumbitError.filter(err => err !== 'wrongUserId'));
                              }
                            }}
                          />
                        </div>
                        <button
                          style={{ marginTop: '25px', }}
                          className={cx('btn', 'btn-warning', 'flex-grow-0', 'flex-shrink-0', { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled || now > registrationEnd) })}
                          disabled={!inputEnabled || checkButtonDisabled || now > registrationEnd}
                          onClick={() => { handleCheckClick(item) }}
                        >檢核
                        </button>
                      </div>
                      <div className={cx(sumbitError.includes('wrongUserId') ? 'd-block' : 'd-none', 'errmsg')}>
                        <small>{errMsg.current.wrongUserId}</small>
                      </div>

                      <div style={{ color: '#FF7518' }}
                        className={cx('mt-3', 'd-flex', 'align-items-center', 'justify-content-center')}>
                      </div>
                      <div>{output}</div>
                      {output === null && (
                        <div>
                          {kgiGust === 'Y' ? (
                            <div style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}>
                              <div>凱基證券用戶 <br />享初始模擬金加碼優惠</div>
                            </div>
                          ) : kgiGust === 'N' || 'X' && now <= registrationEnd ? (
                            <React.Fragment>
                              <div
                                style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}
                                className={cx('mt-3', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}
                              >
                                <div>凱基證券用戶 <br />享初始模擬金加碼優惠</div>
                              </div>
                              <div className={cx('d-flex', 'justify-content-center', 'mt-3')}>
                                <button className={cx('btn', 'OpenAccount')}
                                  onClick={() => window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401', '_blank')}>立即開戶</button>
                              </div>

                            </React.Fragment>
                          ) : null}
                        </div>
                      )}

                      <div className={cx('mt-3', 'schoolSelect',)}>
                        <div>*就讀學校</div>
                        {item.signed ? (
                          <input
                            className={cx(
                              'w-100',
                              'form-control-plaintext',
                              'boxShadowForInput',
                              { 'disabled': (item.signed || !item.regstatus || now > registrationEnd || isSubmitted) }
                            )}
                            required
                            type="text"
                            value={formStates[item.code]?.school_id || ''}
                            onChange={(e) => handleInputChange(item.code, 'school_id', e.target.value)}
                          />
                        ) : (
                          <SelectSearch
                            className={cx('select-search-one', 'w-100', 'boxShadowForInput', { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) })}
                            options={schoolList.map(school => ({ value: school.key, name: school.name }))}
                            search
                            filterOptions={fuzzySearch}
                            emptyMessage="找不到學校選項"
                            placeholder="請選擇學校"
                            disabled={!inputEnabled || now > registrationEnd || isSubmitted}
                            value={formStates[item.code]?.school_id || ''}
                            onChange={(value) => handleInputChange(item.code, 'school_id', value.toString())}
                          />
                        )}
                      </div>
                      <div
                        className={cx(
                          sumbitError.length > 0 && sumbitError.includes('wrongSchool')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongSchool}</small>
                      </div>
                      <div className={cx('mt-2')}>
                        <div>*就讀系所</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) }
                          )}
                          required
                          type="text"
                          disabled={!inputEnabled || now > registrationEnd}
                          value={singleCampaignInfo.isSubmit ? singleCampaignInfo.student_id : formStates[item.code]?.departmentName}
                          placeholder="請填寫中文系所名稱，總長度不超過15位"
                          onChange={(e) => handleInputChange(item.code, 'departmentName', e.target.value)}
                          onBlur={(e) => {
                            if (e.target.value.length > 15 || !REGEX_DEPARTMENT.test(e.target.value)) {
                              let error = [...sumbitError, 'wrongDepartment'];
                              setSumbitError(error);
                            } else {
                              setSumbitError(sumbitError.filter(err => err !== 'wrongDepartment'));
                            }
                          }}
                        />
                      </div>
                      <div
                        className={cx(
                          sumbitError.length > 0 &&
                            sumbitError.includes('wrongDepartment')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongDepartment}</small>
                      </div>
                      <div className={cx('mt-2')}>
                        <div>指導教授</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) }
                          )}
                          type="text"
                          disabled={!inputEnabled || now > registrationEnd}
                          value={
                            singleCampaignInfo.isSubmit ? singleCampaignInfo.professor : formStates[item.code]?.professor}
                          onChange={(e) => handleInputChange(item.code, 'professor', e.target.value)}
                          onBlur={(e) => {
                            if (
                              e.target.value.length > 10 ||
                              !REGEX_PROFESSOR.test(e.target.value)
                            ) {
                              let error = [...sumbitError, 'wrongTeacherName'];
                              setSumbitError(error);
                            } else {
                              setSumbitError(sumbitError.filter(err => err !== 'wrongTeacherName'));
                            }
                          }}
                        />
                      </div>
                      <div
                        className={cx(
                          sumbitError.length > 0 &&
                            sumbitError.includes('wrongTeacherName')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongTeacherName}</small>
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'mt-5',
                          'd-flex',
                          'justify-content-center',
                          'align-items-center'
                        )}
                      >
                        <input
                          className={cx(
                            'br-3',
                            'personalInfoCheck',
                            (game?.regstatus || isSubmitted || now > registrationEnd),
                          )}
                          type="checkbox"
                          value={check as any}
                          onChange={(e) => handleInputChange(item.code, 'check', e.target.checked)}
                          disabled={!inputEnabled}
                          checked={item.signed ? true : undefined}
                        />
                        <div
                          className={cx(
                            'text-center',
                            'ms-1',
                            'cursor-pointer',
                            { 'disabled': (singleCampaignInfo.isSubmit || game?.regstatus || now > registrationEnd) }
                          )}
                        >
                          <span
                            className={cx('personalInfo',)}
                            onClick={() => {
                              window.open('https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information', '_blank');
                            }}
                          >
                            個資同意聲明
                          </span>
                          <span className={cx('alertParagraph')}>*</span>
                        </div>
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'ms-1',
                          'mb-3',
                          check
                            ? 'd-none'
                            : sumbitError.length > 0 && sumbitError.includes('notCheck')
                              ? 'd-block'
                              : 'd-none'
                        )}
                      >
                        <small className={cx('alertParagraph')}>
                          {errMsg.current.notCheck}
                        </small>
                      </div>
                      <div
                        className={cx(
                          'my-2', 'text-center', 'py-3', 'submitBottomBtnCheck', 'fs-3', 'fw-bold',
                          { 'disabled': !item?.regstatus || isSubmitted || item.signed || now < registrationStart || now > registrationEnd }
                        )}
                        style={{ cursor: (!item.regstatus || isSubmitted || item.signed) ? 'not-allowed' : 'pointer' }}
                        onClick={() => handleSubmit(item, item.code)}
                      >
                        {btnWord}
                      </div>
                    </div>
                  )}</div>
                // 校園團體
              ) 
              // 校園團體報名
              : item.camp_type === 1 && item.act_type === 'S' ? (<div>


                <div
                  className={cx(
                    'd-flex',
                    'justify-content-between',
                    'section2Bg',
                    'align-items-center'
                  )}
                  onClick={() => {
                    setGroupSubmit(!groupSubmit);
                    singleSubmit && setSingleSubmit(false);
                  }}
                >
                  <div className={cx('submitSection', 'ms-1')}>
                    <span>填寫報名資料: {`${item.name}`}</span>
                  </div>
                  <div
                    className={cx(
                      'me-1',
                      'transition',
                      groupSubmit && 'hangleArrowRotate'
                    )}
                  >
                    <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                  </div>
                </div>

                {groupSubmit && (
                  <div className={cx('d-flex', 'flex-column', 'px-3')}>
                    <div className={cx('mt-3', 'schoolSelect')}>
                      <div>*就讀學校</div>
                      {teamCampaignInfo.isSubmit ? (
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            teamCampaignInfo.isSubmit ? 'disabled' : ''
                          )}
                          required
                          type="text"
                          disabled={teamCampaignInfo.isSubmit}
                          value={teamCampaignInfo.school_id}
                        />
                      ) : (
                        <SelectSearch
                          className={cx('select-search-one', 'w-100')}
                          options={schoolList.map(school => ({ value: school.key, name: school.name }))}
                          search
                          filterOptions={fuzzySearch}
                          emptyMessage="找不到學校選項"
                          placeholder="請選擇學校"
                          value={schoolChoose}
                          onChange={(e) => {
                            let error = sumbitError.filter(
                              (item) => item !== 'wrongSchool'
                            );
                            setSumbitError(error);
                            handleOnchange(e);
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={cx(
                        sumbitError.length > 0 && sumbitError.includes('wrongSchool')
                          ? 'd-block'
                          : 'd-none',
                        'errmsg'
                      )}
                    >
                      <small>{errMsg.current.wrongSchool}</small>
                    </div>
                    <div className={cx('mt-2')}>
                      <div>*就讀系所</div>
                      <input
                        className={cx(
                          'w-100',
                          'form-control-plaintext',
                          'boxShadowForInput',
                          teamCampaignInfo.isSubmit ? 'disabled' : ''
                        )}
                        required
                        disabled={teamCampaignInfo.isSubmit}
                        type="text"
                        placeholder="請填寫中文系所名稱，總長度不超過15位"
                        value={
                          teamCampaignInfo.isSubmit
                            ? teamCampaignInfo.student_id
                            : departmentName
                        }
                        onChange={(e) => {
                          if (e.target.value.length > 15) return;
                          if (sumbitError.includes('wrongDepartment')) {
                            let error = sumbitError.filter(
                              (item) => item !== 'wrongDepartment'
                            );
                            setSumbitError(error);
                          }
                          setDepartmentName(`${e.target.value}`);
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value.length > 15 ||
                            !REGEX_DEPARTMENT.test(e.target.value)
                          ) {
                            let error = [...sumbitError, 'wrongDepartment'];
                            setSumbitError(error);
                          }
                        }}
                      />
                    </div>
                    <div
                      className={cx(
                        sumbitError.length > 0 &&
                          sumbitError.includes('wrongDepartment')
                          ? 'd-block'
                          : 'd-none',
                        'errmsg'
                      )}
                    >
                      <small>{errMsg.current.wrongDepartment}</small>
                    </div>
                    <div className={cx('mt-2')}>
                      <div>指導教授</div>
                      <input
                        className={cx(
                          'w-100',
                          'form-control-plaintext',
                          'boxShadowForInput',
                          teamCampaignInfo.isSubmit ? 'disabled' : ''
                        )}
                        type="text"
                        disabled={teamCampaignInfo.isSubmit}
                        value={
                          teamCampaignInfo.isSubmit
                            ? teamCampaignInfo.professor
                            : teacherName
                        }
                        onChange={(e) => {
                          if (e.target.value.length > 10) return;
                          if (sumbitError.includes('wrongTeacherName')) {
                            let error = sumbitError.filter(
                              (item) => item !== 'wrongTeacherName'
                            );
                            setSumbitError(error);
                          }
                          setTeacherName(`${e.target.value}`);
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value.length > 10 ||
                            !REGEX_PROFESSOR.test(e.target.value)
                          ) {
                            let error = [...sumbitError, 'wrongTeacherName'];
                            setSumbitError(error);
                          }
                        }}
                      />
                    </div>
                    <div
                      className={cx(
                        sumbitError.length > 0 &&
                          sumbitError.includes('wrongTeacherName')
                          ? 'd-block'
                          : 'd-none',
                        'errmsg'
                      )}
                    >
                      <small>{errMsg.current.wrongTeacherName}</small>
                    </div>

                    <>
                      <div className={cx('mt-2')}>
                        <div>團隊名稱</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            'disabled'
                          )}
                          type="text"
                          disabled
                          value={teamCampaignInfo.team_name}
                        />
                      </div>
                      <div className={cx('mt-2')}>
                        <div>團隊代碼</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            'disabled'
                          )}
                          disabled
                          type="text"
                          value={teamCampaignInfo.team_code}
                        />
                      </div>
                      <div className="mt-3">
                        <CopyToClipboard
                          text={copyBoardText}
                          onCopy={() => {
                            toast.success(`已複製成功！`, {
                              position: 'top-right',
                              autoClose: 1300,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                          }}
                        >
                          <span className={cx('mx-1', 'copyShareButton')}>
                            複製團隊代碼
                          </span>
                        </CopyToClipboard>
                      </div>
                    </>

                    <>
                      <div className={cx('mt-3')}>
                        <input
                          type="radio"
                          value="groupCreate"
                          name="options"
                          checked={radioType === 'groupCreate'}
                          onChange={handleOptionChange}
                        />
                        <span className={cx('ms-1')}>建立隊伍</span>
                      </div>
                      <div className={cx('px-3')}>
                        <div className={cx('mt-2')}>
                          <ul>
                            <li>我的隊伍名稱(限10個字)</li>
                            <li>每隊人數限2~5人，將提供一組隊伍代碼！</li>
                          </ul>
                          <div className={cx('mt-2', 'mb-1', 'alertParagraph')}>
                            ※隊伍名稱及代碼申請後不可以修改！
                          </div>
                          <input
                            className={cx(
                              'w-100',
                              'form-control-plaintext',
                              'boxShadowForInput',
                              radioType !== 'groupCreate' && 'disabled'
                            )}
                            type="text"
                            value={teamCreateName}
                            disabled={radioType !== 'groupCreate'}
                            onChange={(e) => {
                              let error = sumbitError.filter(
                                (item) => item !== 'wrongTeamName'
                              );
                              setSumbitError(error);
                              if (e.target.value.length > 10) return;
                              setTeamCreateName(`${e.target.value}`);
                            }}
                          />
                          <div
                            className={cx(
                              radioType === 'groupJoin'
                                ? 'd-none'
                                : sumbitError.length > 0 &&
                                  sumbitError.includes('wrongTeamName')
                                  ? 'd-block'
                                  : 'd-none',
                              'errmsg'
                            )}
                          >
                            <small>{errMsg.current.wrongTeamName}</small>
                          </div>
                        </div>
                      </div>

                      <div className={cx('mt-4')}>
                        <input
                          type="radio"
                          value="groupJoin"
                          name="options"
                          checked={radioType === 'groupJoin'}
                          onChange={handleOptionChange}
                        />
                        <span className={cx('ms-1')}>加入親朋好友的隊伍</span>
                      </div>
                      <div className={cx('px-3')}>
                        <div className={cx('mt-2', 'alertParagraph')}>
                          ※隊伍名稱及代碼申請後不可以修改！
                        </div>
                        <div className={cx('mt-2')}>
                          <input
                            className={cx(
                              'w-100',
                              'form-control-plaintext',
                              'boxShadowForInput',
                              radioType !== 'groupJoin' && 'disabled'
                            )}
                            type="text"
                            value={teamJoinId}
                            placeholder="請輸入隊伍組代碼"
                            disabled={radioType !== 'groupJoin'}
                            onChange={(e) => {
                              let error = sumbitError.filter(
                                (item) => item !== 'wrongTeamCode'
                              );
                              setSumbitError(error);
                              setTeamJoinId(`${e.target.value}`);
                            }}
                          />
                        </div>
                        <div
                          className={cx(
                            radioType === 'groupCreate'
                              ? 'd-none'
                              : sumbitError.length > 0 &&
                                sumbitError.includes('wrongTeamCode')
                                ? 'd-block'
                                : 'd-none',
                            'errmsg'
                          )}
                        >


                          <small>{errMsg.current.wrongTeamCode}</small>
                        </div>
                      </div>
                    </>


                    <div
                      className={cx(
                        'text-center',
                        'mt-5',
                        'd-flex',
                        'justify-content-center',
                        'align-items-center'
                      )}
                    >
                      <input
                        className={cx(
                          'br-3',
                          'personalInfoCheck',
                          teamCampaignInfo.isSubmit && 'd-none'
                        )}
                        type="checkbox"
                        value={check as any}
                        onChange={(e) => {
                          setCheck(e.target.checked);
                        }}
                      />
                      <div className={cx('text-center', 'ms-1', 'cursor-pointer')}>
                        <span
                          className={cx('personalInfo')}
                          onClick={() => {
                            window.location.href =
                              'https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information';
                          }}
                        >
                          個資同意聲明
                        </span>
                        <span className={cx('alertParagraph')}>*</span>
                      </div>
                    </div>
                    <div
                      className={cx(
                        'text-center',
                        'ms-1',
                        'mb-3',
                        check
                          ? 'd-none'
                          : sumbitError.length > 0 && sumbitError.includes('notCheck')
                            ? 'd-block'
                            : 'd-none'
                      )}
                    >
                      <small className={cx('alertParagraph')}>
                        {errMsg.current.notCheck}
                      </small>
                    </div>
                    <div
                      className={cx(
                        'my-2', 'text-center', 'py-3', 'submitBottomBtnCheck', 'fs-3', 'fw-bold',
                        { 'disabled': !item?.regstatus || isSubmitted || item.signed }
                      )}
                      style={{ cursor: (!item.regstatus || isSubmitted || item.signed) ? 'not-allowed' : 'pointer' }}
                      onClick={CampaignSubmit}
                    >
                      {btnWord}
                    </div>
                  </div>
                )}</div>

                //個人報名
              ) : 
              // 一般個人報名
              item.camp_type === 0 && item.act_type === 'N' ? (
                <div>
                  <div
                    className={cx(
                      'd-flex',
                      'justify-content-between',
                      'section1Bg',
                      'align-items-center'
                    )}
                    onClick={() => {
                      const isSingle = item.camp_type === 0;
                      setSingleSubmit(isSingle ? singleSubmit : false);
                      setGroupSubmit(isSingle ? false : !groupSubmit);
                      toggleSingleItem(item.code);
                    }}
                  >
                    <div className={cx('submitSection', 'ms-1')}>
                      <span>填寫報名資料: {`${item.name}`}</span>
                    </div>

                    <div
                      className={cx(
                        'me-1',
                        'transition',
                        singleSubmit && 'hangleArrowRotate',
                        openSingleItems[item.code] && 'hangleArrowRotate'
                      )}
                    >
                      <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                    </div>
                  </div>
                  {openSingleItems[item.code] && (
                    <div className={cx('d-flex', 'flex-column', 'px-3')}>
                      <div className={cx('mt-3', 'd-flex', 'align-items-center')}>
                        <div className={cx('flex-grow-1', 'me-2')}>
                          <div>*ID</div>
                          <input
                            className={cx(
                              'form-control-plaintext',
                              'boxShadowForInput',
                              'mr-2',
                              'flex-grow-1',
                              { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled) }
                            )}
                            required
                            type="text"
                            value={singleCampaignInfo.isSubmit ? singleCampaignInfo.user_id : formStates[item.code]?.user_id}
                            placeholder='請填寫身分證號碼'
                            maxLength={10}
                            onChange={(e) => handleUserIDChange(e, item.code)}
                            disabled={!inputEnabled}
                            onBlur={(e) => {
                              if (!TAIWAN_ID_REGEX.test(e.target.value)) {
                                let error = [...sumbitError, 'wrongUserId'];
                                setSumbitError(error);
                              }
                              else {
                                setSumbitError(sumbitError.filter(err => err !== 'wrongUserId'));
                              }
                            }}
                          />
                        </div>
                        <button
                          style={{ marginTop: '25px', }}
                          className={cx('btn', 'btn-warning', 'flex-grow-0', 'flex-shrink-0', { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled) })}
                          disabled={!inputEnabled || checkButtonDisabled}
                          onClick={() => { handleCheckClick(item) }}
                        >檢核
                        </button>
                      </div>
                      <div className={cx(sumbitError.includes('wrongUserId') ? 'd-block' : 'd-none', 'errmsg')}>
                        <small>{errMsg.current.wrongUserId}</small>
                      </div>
                      {/* <div>{output}</div> */}
                      {output === null && (
                        <div>
                          {kgiGust === 'Y' ? (
                            <div style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}>

                            </div>
                          ) : kgiGust === 'N' || 'X' ? (
                            <React.Fragment>
                              <div
                                style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}
                                className={cx('mt-3', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}
                              >
                                立即成為凱基客户 <br /> 將享 {item.init_cash / 10000 * 2} 萬初始資金、獎金翻倍優惠
                              </div>
                              <div className={cx('d-flex', 'justify-content-center', 'mt-3')}>
                                <button className={cx('btn', 'OpenAccount')}
                                  onClick={() => window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401', '_blank')}>立即開戶</button>
                              </div>
                              <div className={cx('d-flex', 'justify-content-center', 'mt-3', 'bonus')}>享 籌碼/獎金 翻倍</div>
                            </React.Fragment>
                          ) : null}
                        </div>
                      )}
                      <div
                        className={cx(
                          sumbitError.length > 0 &&
                            sumbitError.includes('wrongTeacherName')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongTeacherName}</small>
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'mt-5',
                          'd-flex',
                          'justify-content-center',
                          'align-items-center'
                        )}
                      >
                        <input
                          className={cx(
                            'br-3',
                            'personalInfoCheck',
                            (game?.regstatus || isSubmitted) && 'd-none',
                          )}
                          type="checkbox"
                          value={check as any}
                          onChange={(e) => handleInputChange(item.code, 'check', e.target.checked)}
                          disabled={!inputEnabled}
                          checked={!inputEnabled ? true : undefined}
                        />
                        <div
                          className={cx(
                            'text-center',
                            'ms-1',
                            'cursor-pointer',
                            { 'disabled': (singleCampaignInfo.isSubmit || game?.regstatus) }
                          )}
                        >
                          <span
                            className={cx('personalInfo',)}
                            onClick={() => {
                              window.open('https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information', '_blank');
                            }}
                          >
                            個資同意聲明
                          </span>
                          <span className={cx('alertParagraph')}>*</span>
                        </div>
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'ms-1',
                          'mb-3',
                          check
                            ? 'd-none'
                            : sumbitError.length > 0 && sumbitError.includes('notCheck')
                              ? 'd-block'
                              : 'd-none'
                        )}
                      >
                        <small className={cx('alertParagraph')}>
                          {errMsg.current.notCheck}
                        </small>
                      </div>
                      <div
                        className={cx(
                          'my-2', 'text-center', 'py-3', 'submitBottomBtnCheck', 'fs-3', 'fw-bold',
                          { 'disabled': !item?.regstatus || isSubmitted || item.signed || now < registrationStart || now > registrationEnd }
                        )}
                        style={{ cursor: (!item.regstatus || isSubmitted || item.signed) ? 'not-allowed' : 'pointer' }}
                        onClick={() => handleSubmit(item, item.code)}
                      >
                        {btnWord}
                      </div>
                    </div>
                  )}</div>
                //企業個人報名
              ) :
              // 企業個人報名
              item.camp_type === 0 && item.act_type === 'C' ? (
                <div>
                  <div
                    className={cx(
                      'd-flex',
                      'justify-content-between',
                      'section1Bg',
                      'align-items-center'
                    )}
                    onClick={() => {
                      const isSingle = item.camp_type === 0;
                      setSingleSubmit(isSingle ? singleSubmit : false);
                      setGroupSubmit(isSingle ? false : !groupSubmit);
                      toggleSingleItem(item.code);
                    }}
                  >
                    <div className={cx('submitSection', 'ms-1')}>
                      <span>填寫報名資料: {`${item.name}`}</span>
                    </div>
                    <div
                      className={cx(
                        'me-1',
                        'transition',
                        singleSubmit && 'hangleArrowRotate',
                        openSingleItems[item.code] && 'hangleArrowRotate'
                      )}
                    >
                      <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                    </div>
                  </div>
                  {openSingleItems[item.code] && (
                    <div className={cx('d-flex', 'flex-column', 'px-3')}>
                      <div className={cx('mt-3', 'd-flex', 'align-items-center')}>
                        <div className={cx('flex-grow-1', 'me-2')}>
                          <div>*ID</div>
                          <input
                            className={cx(
                              'form-control-plaintext',
                              'boxShadowForInput',
                              'mr-2',
                              'flex-grow-1',
                              { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled) || now > registrationEnd }
                            )}
                            required
                            type="text"
                            value={userID || (singleCampaignInfo.isSubmit ? singleCampaignInfo.user_id : formState.user_id || responseUserId || '')}
                            placeholder='請填寫身分證號碼'
                            maxLength={10}
                            onChange={(e) => handleUserIDChange(e, item.code)}
                            disabled={!inputEnabled}
                            onBlur={(e) => {
                              if (!TAIWAN_ID_REGEX.test(e.target.value)) {
                                let error = [...sumbitError, 'wrongUserId'];
                                setSumbitError(error);
                              }
                              else {
                                setSumbitError(sumbitError.filter(err => err !== 'wrongUserId'));
                              }
                            }}
                          />
                        </div>
                        <button
                          style={{ marginTop: '25px', }}
                          className={cx('btn', 'btn-warning', 'flex-grow-0', 'flex-shrink-0', { 'disabled': (item.signed || !item.regstatus || checkButtonDisabled || now > registrationEnd) })}
                          disabled={!inputEnabled || checkButtonDisabled || now > registrationEnd}
                          onClick={() => { handleCheckClick(item) }}
                        >檢核
                        </button>
                      </div>
                      <div className={cx(sumbitError.includes('wrongUserId') ? 'd-block' : 'd-none', 'errmsg')}>
                        <small>{errMsg.current.wrongUserId}</small>
                      </div>
                      <div style={{ color: '#FF7518' }}
                        className={cx('mt-3', 'd-flex', 'align-items-center', 'justify-content-center')}>
                      </div>
                      <div>{output}</div>
                      {output === null && (
                        <div>
                          {kgiGust === 'Y' ? (
                            <div style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}>
                              <div>凱基證券用戶 <br />享初始模擬金加碼優惠</div>
                            </div>
                          ) : kgiGust === 'N' || 'X' && now <= registrationEnd ? (
                            <React.Fragment>
                              <div
                                style={{ color: '#FF7518', textAlign: 'center', fontWeight: 'bold' }}
                                className={cx('mt-3', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}
                              >
                                <div>凱基證券用戶 <br />享初始模擬金加碼優惠</div>
                              </div>
                              <div className={cx('d-flex', 'justify-content-center', 'mt-3')}>
                                <button className={cx('btn', 'OpenAccount')}
                                  onClick={() => window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401', '_blank')}>立即開戶</button>
                              </div>

                            </React.Fragment>
                          ) : null}
                        </div>
                      )}
                      <div className={cx('mt-2')}>
                        <div>*姓名</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) }
                          )}
                          maxLength={15}
                          required
                          type="text"
                          disabled={!inputEnabled || now > registrationEnd}
                          value={singleCampaignInfo.isSubmit ? singleCampaignInfo.student_id : formStates[item.code]?.departmentName}
                          placeholder="中獎聯繫使用"
                          onChange={(e) => handleInputChange(item.code, 'departmentName', e.target.value)}
                          onBlur={(e) => {
                            if (e.target.value === '') {
                              let error = [...sumbitError, 'wrongUserName'];
                              setSumbitError(error);
                            } else {
                              setSumbitError(sumbitError.filter(err => err !== 'wrongUserName'));
                            }
                          }}
                        />
                      </div>
                      <div
                        className={cx(
                          sumbitError.length > 0 &&
                            sumbitError.includes('wrongUserName')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongUserName}</small>
                      </div>
                      <div className={cx('mt-3', 'schoolSelect',)}>
                        <div>*年齡</div>
                        {item.signed ? (
                          <input
                            className={cx(
                              'w-100',
                              'form-control-plaintext',
                              'boxShadowForInput',
                              { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) }
                            )}
                            required
                            type="text"
                            value={formStates[item.code]?.school_id || ''}
                            onChange={(e) => handleInputChange(item.code, 'school_id', e.target.value)}
                          />
                        ) : (

                          <div
                            onBlur={() => {
                              setHasBlurred((prev) => ({ ...prev, [item.code]: true }));
                            }}
                          >
                            <SelectSearch
                              className={cx('select-search-one', 'w-100', 'boxShadowForInput', { 'disabled': (item.signed || !item.regstatus || now > registrationEnd) })}
                              options={ageOptions}
                              search
                              filterOptions={fuzzySearch}
                              emptyMessage="找不到年齡選項"
                              placeholder="請選擇年齡"
                              disabled={!inputEnabled || now > registrationEnd}
                              value={formStates[item.code]?.school_id || ''}
                              onChange={(value) => {
                                handleInputChange(item.code, 'school_id', value.toString());
                                if (hasBlurred[item.code]) {
                                  setSumbitError(sumbitError.filter(err => err !== 'wrongUserAge'));
                                }
                              }}
                            />
                          </div>



                        )}
                      </div>

                      <div
                        className={cx(
                          sumbitError.length > 0 && sumbitError.includes('wrongUserAge')
                            ? 'd-block'
                            : 'd-none',
                          'errmsg'
                        )}
                      >
                        <small>{errMsg.current.wrongUserAge}</small>
                      </div>


                      <div className={cx('mt-2')}>
                        <div>備註</div>
                        <input
                          className={cx(
                            'w-100',
                            'form-control-plaintext',
                            'boxShadowForInput',
                            { 'disabled': (item.signed || !item.regstatus || now > registrationEnd || isSubmitted) }
                          )}
                          type="text"
                          disabled={!inputEnabled || now > registrationEnd || isSubmitted}
                          value={
                            singleCampaignInfo.isSubmit ? singleCampaignInfo.professor : formStates[item.code]?.professor}
                          onChange={(e) => handleInputChange(item.code, 'professor', e.target.value)}

                        />
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'mt-5',
                          'd-flex',
                          'justify-content-center',
                          'align-items-center'
                        )}
                      >
                        <input
                          className={cx(
                            'br-3',
                            'personalInfoCheck',
                            (game?.regstatus || isSubmitted || now > registrationEnd),
                          )}
                          type="checkbox"
                          value={check as any}
                          onChange={(e) => handleInputChange(item.code, 'check', e.target.checked)}
                          disabled={!inputEnabled}
                          checked={item.signed ? true : undefined}
                        />
                        <div
                          className={cx(
                            'text-center',
                            'ms-1',
                            'cursor-pointer',
                            { 'disabled': (singleCampaignInfo.isSubmit || game?.regstatus || now > registrationEnd) }
                          )}
                        >
                          <span
                            className={cx('personalInfo',)}
                            onClick={() => {
                              window.open('https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information', '_blank');
                            }}
                          >
                            個資同意聲明
                          </span>
                          <span className={cx('alertParagraph')}>*</span>
                        </div>
                      </div>
                      <div
                        className={cx(
                          'text-center',
                          'ms-1',
                          'mb-3',
                          check
                            ? 'd-none'
                            : sumbitError.length > 0 && sumbitError.includes('notCheck')
                              ? 'd-block'
                              : 'd-none'
                        )}
                      >
                        <small className={cx('alertParagraph')}>
                          {errMsg.current.notCheck}
                        </small>
                      </div>
                      <div
                        className={cx(
                          'my-2', 'text-center', 'py-3', 'submitBottomBtnCheck', 'fs-3', 'fw-bold',
                          { 'disabled': !item?.regstatus || isSubmitted || item.signed || now < registrationStart || now > registrationEnd }
                        )}
                        style={{ cursor: (!item.regstatus || isSubmitted || item.signed) ? 'not-allowed' : 'pointer' }}
                        onClick={() => handleSubmit(item, item.code)}
                      >
                        {btnWord}
                      </div>
                    </div>
                  )}</div>
                // 校園團體
              ) : null
              }

            </div>
          )
        })}




        <div className={cx('submitContent')}>
          <div className={cx('d-flex', 'flex-column', 'px-2')}>
            <div
              className={cx('my-2')}
              onClick={async () => {
                if (!game?.regstatus || isSubmitted) {
                  return;
                }
                if (singleSubmit) {
                  CampaignSubmit(filteredGames);
                } else {
                  if (teamCampaignInfo.isSubmit) return;
                  handleGroupSubmitCheck();
                  if (radioType === 'groupCreate') {
                    if (
                      schoolChoose === '' ||
                      departmentName === '' ||
                      !REGEX_DEPARTMENT.test(departmentName) ||
                      teamCreateName === '' ||
                      !check ||
                      teamCreateName.length > 10 ||
                      !REGEX_PROFESSOR.test(teacherName) ||
                      sumbitError.length > 0
                    ) {
                      return;
                    }
                    setOpenAlertModel(true);
                  } else {
                    if (
                      schoolChoose === '' ||
                      departmentName === '' ||
                      teamJoinId === '' ||
                      !check ||
                      !REGEX_PROFESSOR.test(teacherName) ||
                      sumbitError.length > 0
                    ) {
                      return;
                    }
                    const teamName = await getTeamNameQuery();
                    if (teamName.err === '0000') {
                      setOpenAlertModel(true);
                    }
                  }
                }
              }}
            >
              {/* <div
  className={cx(
    'my-2', 'text-center', 'py-3', 'submitBottomBtnCheck', 'fs-3', 'fw-bold',
    {'disabled': !game?.regstatus || isSubmitted || game.signed}  
  )}
        style={{ cursor: (!game?.regstatus || isSubmitted || game.signed) ? 'not-allowed' : 'pointer' }}  
      onClick={handleButtonClick}
>
</div>  */}
            </div>
          </div>
        </div>
      </div>
      <AleartModel
        isOpen={openAlertModel}
        handleClose={() => {
          setTimeout(() => {
          setIsLineShareModel(false);
          }, 500);
          setOpenAlertModel(false);
        }}
        handleCloseAllModel={() => {
          reset();
          setTimeout(() => {
          setIsLineShareModel(false);
          }, 500);
          setOpenAlertModel(false);
          handleClose();
        }}
        handleCheck={() => {
        CampaignSubmit(filteredGames);
        }}
        teamData={teamCreateData}
        isLineShareModel={isLineShareModel}
        type={radioType as any}
        loginInfo={loginInfo}
        singleSubmitSuccess={!singleSubmit}
        successName={successName}
      />
      <AleartModel
        isOpen={showCheckConfirm}
        handleClose={handleCloseCheckConfirm}
        handleCheck={async () => {
          if (selectedItem) {
            await handleCheck(selectedItem);
          }
          handleCloseCheckConfirm();
        }}
        type="checkConfirm"
        singleSubmitSuccess={false}
        isChecking={isChecking}
      />
    </Modal>
  );
};

export default CampaignSubmitModel;
