import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Container, Badge, Pagination } from 'react-bootstrap';
import { Column } from 'react-table';
import { Table } from '../../components/DataTable';

import { useAsyncRetry } from 'react-use';

import api from '../../api';
import { OrderType } from '../../api/newApi/orderQuery';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Plus, Dash } from '../../icons/';

import style from './index.module.css';
import { Page, loginInfo } from '../../model';

const cx = classNames.bind(style);

export interface OrderInquire {
  cuid: string;
  code: string;
  campaignName: string | null;
  orderCount: number;
  campaign_active: boolean;
  continueBell: () => void;
  loginInfo: loginInfo;
  pageButton: Page;
}
function useOrderQuery(cu_id: string, retryToggle: boolean, page: number) {
  const { loading, value, retry } = useAsyncRetry(
    () =>
      api.orderQuery({
        cu_id,
        page,
      }),
    [cu_id, retryToggle, page]
  );
  const orders = React.useMemo(() => {
    return value?.orders ? value?.orders : [];
  }, [loading]);

  const QueryTime = React.useMemo(() => {
    return value?.QueryTime ? value?.QueryTime : '';
  }, [loading]);
  const pages = React.useMemo(() => {
    return value?.pages ? value?.pages : 0;
  }, [loading]);

  const recs = React.useMemo(() => {
    return value?.recs ? value?.recs : 0;
  }, [loading]);

  return {
    loading,
    orders,
    QueryTime,
    error: value?.err,
    pages,
    recs,
    retry,
  };
}
function statCN(status: number) {
  if (status === 0) {
    return '委託成功';
  }
  if (status === 1) {
    return '部分成交';
  }
  if (status === 10) {
    return '完全成交';
  }
  if (status === 20) {
    return '刪單成功';
  }
  if (status === 30) {
    return '取消';
  }
  return '';
}
const Digits2NumberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const DigitsNoneNumberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
const checkDayTradeBsName = (data: any) => {
  console.log('dataasdasdasdas', data);
  if (data.bS1 === 'B' && data.day_trade) {
    return '沖買';
  }
  if (data.bS1 === 'B' && !data.day_trade) {
    return '現買';
  }
  if (data.bS1 === 'S' && data.day_trade) {
    return '沖賣';
  }
  if (data.bS1 === 'S' && !data.day_trade) {
    return '現賣';
  }
};
const OrderInquire: React.FC<OrderInquire> = ({
  cuid,
  code,
  campaignName,
  orderCount,
  continueBell,
  campaign_active,
  loginInfo,
  pageButton,
}) => {
  const [retryToggle, setRetryToggle] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [t1, setT1] = useState<string>(
    dayjs().format('YYYY/MM/DD HH:mm:ss.000')
  );
  const [t2, setT2] = useState<string>('');
  const {
    loading: OrderQueryLoading,
    orders: OrderQuery,
    error: OrderQueryError,
    QueryTime,
    retry,
    pages,
    recs,
  } = useOrderQuery(cuid, retryToggle, page);
  console.log('asdhashjkadhjks', OrderQuery);
  const handleOrderCancele = (cuid: string, orderno: number) => {
    if (!campaign_active) {
      const t1 = dayjs()
        .subtract(3, 'second')
        .format('YYYY/MM/DD HH:mm:ss.000');
      const t2 = dayjs().format('YYYY/MM/DD HH:mm:ss.000');
      setT1(t1);
      setT2(t2);
      if (!campaign_active) {
        api.bellCheck({ cu_id: cuid, t1: t1, t2: t2 }).then((e) => {
          if (e?.err === '1200') {
            toast.warn('非系統交易時間！', {
              position: 'top-right',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          } else {
            continueBell();
            api
              .orderCancel({
                cu_id: cuid,
                order_no: orderno,
                cancel_user_id: loginInfo.uid as number,
              })
              .then((e) => {
                if (e.err === '0000') {
                  setRetryToggle(!retryToggle);
                }
              });
          }
        });
      }
    } else {
      api
        .orderCancel({
          cu_id: cuid,
          order_no: orderno,
          cancel_user_id: loginInfo.uid as number,
        })
        .then((e) => {
          if (e.err === '0000') {
            setRetryToggle(!retryToggle);
          }
        });
    }
  };
  // React.useEffect(() => { setTimeout(() => { (window.parent as any).resizeIframe() }, 100) }, [OrderQuery, OrderQueryLoading])
  React.useEffect(() => {
    if (orderCount > 0) {
      setRetryToggle(!retryToggle);
    }
  }, [orderCount]);
  const tifType = {
    R: 'ROD',
    F: 'FOK',
    I: 'IOC',
  };

  const columns = React.useMemo<Column<OrderType>[]>(() => {
    return code === 'KGI02'
      ? [
          {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            Header: () => null,
            sticky: 'left',
            Cell: (cellInfo: {
              cell: {
                row: {
                  canExpand: any;
                  original: any;
                  getToggleRowExpandedProps: () => JSX.IntrinsicAttributes &
                    React.ClassAttributes<HTMLSpanElement> &
                    React.HTMLAttributes<HTMLSpanElement>;
                  isExpanded: any;
                };
              };
            }) => (
              <>
                {cellInfo.cell.row.original.detail &&
                  cellInfo.cell.row.original.detail.length > 0 && (
                    <span
                      className={cx('expander')}
                      {...cellInfo.cell.row.getToggleRowExpandedProps()}
                    >
                      {cellInfo.cell.row.isExpanded ? <Dash /> : <Plus />}
                    </span>
                  )}
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>商品</div>
              </>
            ),
            accessor: 'name',
            sticky: 'left',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>{<div>{cellInfo.cell.row.original.name}</div>}</>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>商品代號</div>
              </>
            ),
            accessor: 'sym',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <div className={cx('tablewrap')}>
                    {cellInfo.cell.row.original.sym}
                  </div>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap', 'min_width_55px')}>類別</div>
              </>
            ),
            accessor: 'bS1',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div
                  className={cx(
                    'tablewrap',
                    cellInfo.cell.row.original.bS1 === 'B' ? 'buy' : 'sell'
                  )}
                >
                  {checkDayTradeBsName(cellInfo.cell.row.original)}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('')}>下單人</div>
              </>
            ),
            accessor: 'user_name',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>{<div>{cellInfo.cell.row.original.user_name}</div>}</>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('')}>
                  掛單
                  <br />
                  類別
                </div>
              </>
            ),
            accessor: 'tif',
            disableSortBy: true,
            Cell: (cellInfo) => {
              const tif = cellInfo.cell.row.original.tif;

              return <>{tif ? tifType[tif as 'R' | 'F' | 'I'] : ''}</>;
            },
          },
          {
            Header: (
              <>
                <div>
                  委託價/
                  <br />
                  委託量
                </div>
              </>
            ),
            accessor: 'p1',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {cellInfo.cell.row.original.ort === '1'
                        ? '市價'
                        : Digits2NumberFormatter(
                            cellInfo.cell.row.original.p1.toString()
                          )}
                      <br />
                      {Number(cellInfo.cell.row.original.qty).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div>
                  成交價/
                  <br />
                  成交量
                </div>
              </>
            ),
            accessor: 'qty',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {Digits2NumberFormatter(
                        cellInfo.cell.row.original.tda.toString()
                      )}
                      <br />
                      {Number(cellInfo.cell.row.original.tdq).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div>未成交量</div>
              </>
            ),
            accessor: 'hp',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {Number(cellInfo.cell.row.original.hq).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託單號</div>
              </>
            ),
            accessor: 'orn',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div className={cx('tablewrap')}>
                  {cellInfo.cell.row.original.orn}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託時間</div>
              </>
            ),
            accessor: 'ot',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div>
                  {cellInfo.cell.row.original.ot}
                  <br />
                  {cellInfo.cell.row.original.ottime}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>成交時間</div>
              </>
            ),
            accessor: 'dt',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div>
                  {cellInfo.cell.row.original.dt}
                  <br />
                  {cellInfo.cell.row.original.dttime}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託狀態</div>
              </>
            ),
            accessor: 'stat',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div className={cx('tablewrap')}>
                  {statCN(cellInfo.cell.row.original.status)}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>選項</div>
              </>
            ),
            accessor: 'action',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {cellInfo.cell.row.original.status < 10 &&
                cellInfo.cell.row.original.tif === 'R' ? (
                  <div
                    className={cx('tablewrap')}
                    onClick={() =>
                      handleOrderCancele(cuid, cellInfo.cell.row.original.orn)
                    }
                  >
                    <div className={cx('del-btn')}>刪單</div>
                  </div>
                ) : (
                  <div className={cx('tablewrap')}> --</div>
                )}
              </>
            ),
          },
        ]
      : [
          {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            Header: () => null,
            sticky: 'left',
            Cell: (cellInfo: {
              cell: {
                row: {
                  canExpand: any;
                  original: any;
                  getToggleRowExpandedProps: () => JSX.IntrinsicAttributes &
                    React.ClassAttributes<HTMLSpanElement> &
                    React.HTMLAttributes<HTMLSpanElement>;
                  isExpanded: any;
                };
              };
            }) => (
              <>
                {cellInfo.cell.row.original.detail &&
                  cellInfo.cell.row.original.detail.length > 0 && (
                    <span
                      className={cx('expander')}
                      {...cellInfo.cell.row.getToggleRowExpandedProps()}
                    >
                      {cellInfo.cell.row.isExpanded ? <Dash /> : <Plus />}
                    </span>
                  )}
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>商品</div>
              </>
            ),
            accessor: 'name',
            sticky: 'left',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>{<div>{cellInfo.cell.row.original.name}</div>}</>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>商品代號</div>
              </>
            ),
            accessor: 'sym',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <div className={cx('tablewrap')}>
                    {cellInfo.cell.row.original.sym}
                  </div>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap', 'min_width_55px')}>類別</div>
              </>
            ),
            accessor: 'bS1',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div
                  className={cx(
                    'tablewrap',
                    cellInfo.cell.row.original.bS1 === 'B' ? 'buy' : 'sell'
                  )}
                >
                  {checkDayTradeBsName(cellInfo.cell.row.original)}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('')}>
                  掛單
                  <br />
                  類別
                </div>
              </>
            ),
            accessor: 'tif',
            disableSortBy: true,
            Cell: (cellInfo) => {
              const tif = cellInfo.cell.row.original.tif;

              return <>{tif ? tifType[tif as 'R' | 'F' | 'I'] : ''}</>;
            },
          },
          {
            Header: (
              <>
                <div>
                  委託價/
                  <br />
                  委託量
                </div>
              </>
            ),
            accessor: 'p1',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {cellInfo.cell.row.original.ort === '1'
                        ? '市價'
                        : Digits2NumberFormatter(
                            cellInfo.cell.row.original.p1.toString()
                          )}
                      <br />
                      {Number(cellInfo.cell.row.original.qty).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div>
                  成交價/
                  <br />
                  成交量
                </div>
              </>
            ),
            accessor: 'qty',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {Digits2NumberFormatter(
                        cellInfo.cell.row.original.tda.toString()
                      )}
                      <br />
                      {Number(cellInfo.cell.row.original.tdq).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div>未成交量</div>
              </>
            ),
            accessor: 'hp',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {
                  <>
                    <div>
                      {Number(cellInfo.cell.row.original.hq).toLocaleString(
                        'en-US'
                      )}
                    </div>
                  </>
                }
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託單號</div>
              </>
            ),
            accessor: 'orn',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div className={cx('tablewrap')}>
                  {cellInfo.cell.row.original.orn}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託時間</div>
              </>
            ),
            accessor: 'ot',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div>
                  {cellInfo.cell.row.original.ot}
                  <br />
                  {cellInfo.cell.row.original.ottime}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>成交時間</div>
              </>
            ),
            accessor: 'dt',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div>
                  {cellInfo.cell.row.original.dt}
                  <br />
                  {cellInfo.cell.row.original.dttime}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>委託狀態</div>
              </>
            ),
            accessor: 'stat',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                <div className={cx('tablewrap')}>
                  {statCN(cellInfo.cell.row.original.status)}
                </div>
              </>
            ),
          },
          {
            Header: (
              <>
                <div className={cx('tablewrap')}>選項</div>
              </>
            ),
            accessor: 'action',
            disableSortBy: true,
            Cell: (cellInfo) => (
              <>
                {cellInfo.cell.row.original.status < 10 &&
                cellInfo.cell.row.original.tif === 'R' ? (
                  <div
                    className={cx('tablewrap')}
                    onClick={() =>
                      handleOrderCancele(cuid, cellInfo.cell.row.original.orn)
                    }
                  >
                    <div className={cx('del-btn')}>刪單</div>
                  </div>
                ) : (
                  <div className={cx('tablewrap')}>--</div>
                )}
              </>
            ),
          },
        ];
  }, [OrderQuery, retryToggle]);

  return (
    <Container className={cx('container-left')} fluid="xl">
      <div className={cx('text-color', ' container-left', 'my-2')}>
        {' '}
        <div>
          {pageButton === 'orderInquire' ? '委託' : '成交'}查詢{' '}
          <Badge className={cx('badge-box')}>{recs}筆</Badge>
        </div>
      </div>
      <div className={cx('updateTime')}>更新時間：{QueryTime || ''}</div>
      <div className={cx('tableHead-minHeight')}>
        <Table
          columns={columns}
          data={OrderQuery}
          loading={OrderQueryLoading}
          renderPage="orderInquire"
        />
      </div>
      {pages > 0 && OrderQuery.length > 0 && (
        <div className={cx('paginatioWrap')}>
          <Pagination>
            <Pagination.First
              onClick={() => setPage(1)}
              disabled={page === 1}
            />
            <Pagination.Prev
              onClick={() => page > 1 && setPage(page - 1)}
              disabled={page - 1 === 0}
            />
            {pages >= 1 && (
              <Pagination.Item onClick={() => setPage(1)} active={page === 1}>
                {1}
              </Pagination.Item>
            )}
            {pages >= 2 && (
              <Pagination.Item onClick={() => setPage(2)} active={page === 2}>
                {2}
              </Pagination.Item>
            )}
            {pages >= 3 && page < 5 && (
              <Pagination.Item onClick={() => setPage(3)} active={page === 3}>
                {3}
              </Pagination.Item>
            )}
            {pages >= 5 && page > 4 && <Pagination.Ellipsis />}
            {page >= 4 && (
              <Pagination.Item
                onClick={() => pages > page && setPage(page + 1)}
                active={page === page}
              >
                {page}
              </Pagination.Item>
            )}
            {pages > page && pages - page >= 1 && page >= 3 && (
              <Pagination.Ellipsis />
            )}
            <Pagination.Next
              onClick={() => setPage(page + 1)}
              disabled={page === pages}
            />
            <Pagination.Last
              onClick={() => setPage(pages)}
              disabled={page === pages}
            />
          </Pagination>
        </div>
      )}
    </Container>
  );
};

export default OrderInquire;
