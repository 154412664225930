import axios, { AxiosInstance } from "axios";
// import { getLoginStatus } from "./php/getLoginStatus";
import * as quote from "./Quote";
import {
  invitationQuery,
  invitationQueryParameters,
} from "./newApi/InvitationQuery";

import { applyCampaign, applyCampaignParameters } from "./newApi/applyCampaign";
import { getCampaignQuery } from "./newApi/getCampaignQuery";

import {
  GetFavoriteQueryParameters as FavoriteQueryParameters,
  getFavoriteQuery,
} from "./newApi/getFavoriteQuery";

import { GetProfitParameters, getProfitQuery } from "./newApi/getProfit";
import { OrderPlace, OrderPlaceParameters } from "./newApi/orderPlace";
import {
  orderQuery,
  GetOrderQueryParameters as OrderQueryParameters,
} from "./newApi/orderQuery";

import {
  getLeaderBoard,
  GetLeaderBoardParameters,
} from "./newApi/getLeaderBoard";

import { orderCancel, OrderCancelParameters } from "./newApi/orderCancel";
import { favoriteAdd, FavoriteAddParameters } from "./newApi/favoriteAdd";
import {
  favoriteRemove,
  FavoriteRemoveParameters,
} from "./newApi/favoriteRemove";

import { getTargetsQuery } from "./newApi/getTargetsQuery";
import { getPublisherQuery } from "./newApi/getPublisherQuery";
import {
  getWarrantQuery,
  GetWarrantQueryParameters,
} from "./newApi/getWarrantQuery";
import {
  searchWarrants,
  SearchWarrantsParameters,
} from "./newApi/searchWarrants";
import { getOnhandQuery, OnhandParameters } from "./newApi/getOnhand";
import { deposit, depositParameters } from "./newApi/deposit";
import { campaignReset, campaignResetParameters } from "./newApi/campaignReset";
import { accountReset, accountResettParameters } from "./newApi/accountReset";
import { bellCheck, bellCheckParameters } from "./newApi/bellCheck";
import {
  UserGuideClose,
  UserGuideCloseParameters,
} from "./newApi/userGuideClose";
import {
  UserGuideQuery,
  UserGuideQueryParameters,
} from "./newApi/userGuideQuery";
import { getTaskList, UserTaskQueryParameters } from "./newApi/getTaskList";
import { userArticleReadCheck, UserArticleReadTaskQueryParameters } from "./newApi/userArticleReadCheck";
import { verifyCheck, verifyCheckParameters } from "./newApi/verifyCheck";
import { verifyApply, verifyApplyParameters } from "./newApi/verifyApply";
import { verifyCode, verifyCodeParameters } from "./newApi/verifyCode";
import { loginInfo } from "../model";
// import { getEnv } from "tools/getEnv";
import { changePassword, ChangePasswordPayload } from "./newApi/changePassword";
import { getSchoolList } from "./newApi/getSchoolList";
import { teamCreate, teamCreatePayload } from "./newApi/teamCreate";
import { teamJoin, teamJoinPayload } from "./newApi/teamJoin";
import { teamQuery, teamQueryPayload } from "./newApi/teamQuery";
import { applyQuery, applyQueryPayload } from "./newApi/applyQuery";
import { lineUserLoginPayloadType, lineUserLogin } from "./newApi/lineUserLogin";
import { teamNameQuery, teamNameQueryPayload } from "./newApi/teamNameQuery";
//php
import { userJoin, userJoinType, userJoinPayloadType } from "./php/userJoin";
import { userLogin, userLoginPayloadType } from "./php/userLogin";
import { googleUserLogin, googleUserLoginPayloadType } from "./php/googleUserLogin";
import { googleUserJoinPayloadType, googleUserJoin } from "./php/googleUserJoin";
import { userForgetPassword, userForgetPasswordPayloadType } from "./php/userForgetPassword";
import { userReset, userResetPayloadType } from "./php/userReset";
import { fbUserLogin, fbUserLoginPayloadType } from "./php/fbUserLogin";
import { fbUserJoinPayloadType, fbUserJoin } from "./php/fbUserJoin";
class Api {
  constructor(
    private clientOrderIdPrefix: string = "",
    private tokenInvalidAction: () => void = () => { }
  ) {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_URL}`,
      validateStatus: (status) => status === 200,
    });

    this.setAxiosInterceptors();
  }

  private axios: AxiosInstance;
  private count = 1;

  public setTokenInvalidAction(action: () => void) {
    this.tokenInvalidAction = action;
  }

  public setClientOrderIdPrefix(clientOrderIdPrefix: string) {
    this.clientOrderIdPrefix = clientOrderIdPrefix;
  }

  private setAxiosInterceptors = () => {
    this.setAxiosInterceptorsForRequest();
    this.setAxiosInterceptorForResponse();
  };

  private setAxiosInterceptorsForRequest = () => {
    this.axios.interceptors.request.use(
      (config) => {
        console.log(
          `Send request to url:${config.baseURL}${config.url} using method: %c${config.method}\n`,
          "background:#D6EAF8;color:#E74C3C;padding:0px 5px;"
        );
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  };

  private setAxiosInterceptorForResponse = () => {
    this.axios.interceptors.response.use(
      (response) => {
        console.log(
          `Get response from url: ${response.config.url}, Status code: ${response.status}\n`,
          response.data
        );

        if (response.data?.ReturnCode === -2) {
          return Promise.reject();
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          console.log('login fail UNAUTHORIZED 401')
          window.localStorage.setItem("UNAUTHORIZED", JSON.stringify(true));
          window.location.replace(`${process.env.REACT_APP_URL}/#/home`)
        }
        console.error("error.response.status:", error.response.status);

        return Promise.reject(error);
      }
    );
  };

  getQuotes = async (parameters: quote.GetQuotesParameter) => {
    return quote.getQuotes(this.axios, parameters);
  };
  registerTick = async (parameters: quote.RegisterParameters) => {
    return quote.register(this.axios, parameters);
  };

  // getLoginStatus = async () => {
  //   return getLoginStatus(this.axios);
  // };
  invitationQuery = async (parameters: invitationQueryParameters) => {
    return invitationQuery(this.axios, parameters);
  };

  applyCampaign = async (parameters: applyCampaignParameters) => {
    return applyCampaign(this.axios, parameters);
  };
  getCampaignQuery = async (parameters: loginInfo) => {
    return getCampaignQuery(this.axios, parameters);
  };
  favoriteAdd = async (parameters: FavoriteAddParameters) => {
    return favoriteAdd(this.axios, parameters);
  };
  favoriteRemove = async (parameters: FavoriteRemoveParameters) => {
    return favoriteRemove(this.axios, parameters);
  };

  getFavoriteQuery = async (parameters: FavoriteQueryParameters) => {
    return getFavoriteQuery(this.axios, parameters);
  };

  getProfitQuery = async (parameters: GetProfitParameters) => {
    return getProfitQuery(this.axios, parameters);
  };

  OrderPlace = async (parameters: OrderPlaceParameters) => {
    return OrderPlace(this.axios, parameters);
  };

  orderQuery = async (parameters: OrderQueryParameters) => {
    return orderQuery(this.axios, parameters);
  };
  getLeaderBoard = async (parameters: GetLeaderBoardParameters) => {
    return getLeaderBoard(this.axios, parameters);
  };
  orderCancel = async (parameters: OrderCancelParameters) => {
    return orderCancel(this.axios, parameters);
  };
  getOnhandQuery = async (parameters: OnhandParameters) => {
    return getOnhandQuery(this.axios, parameters);
  };
  deposit = async (parameters: depositParameters) => {
    return deposit(this.axios, parameters);
  };
  campaignReset = async (parameters: campaignResetParameters) => {
    return campaignReset(this.axios, parameters);
  };
  accountReset = async (parameters: accountResettParameters) => {
    return accountReset(this.axios, parameters);
  };
  getTargetsQuery = async () => {
    return getTargetsQuery(this.axios);
  };
  getPublisherQuery = async () => {
    return getPublisherQuery(this.axios);
  };
  getWarrantQuery = async (parameters: GetWarrantQueryParameters) => {
    return getWarrantQuery(this.axios, parameters);
  };
  searchWarrants = async (parameters: SearchWarrantsParameters) => {
    return searchWarrants(this.axios, parameters);
  };
  bellCheck = async (parameters: bellCheckParameters) => {
    return bellCheck(this.axios, parameters);
  };
  getTaskList = async (parameters: UserTaskQueryParameters) => {
    return getTaskList(this.axios, parameters);
  }
  userArticleReadCheck = async (parameters: UserArticleReadTaskQueryParameters) => {
    return userArticleReadCheck(this.axios, parameters);
  }
  userGuideClose = async (parameters: UserGuideCloseParameters) => {
    return UserGuideClose(this.axios, parameters);
  };
  userGuideQuery = async (parameters: UserGuideQueryParameters) => {
    return UserGuideQuery(this.axios, parameters);
  };
  verifyCheck = async (parameters: verifyCheckParameters) => {
    return verifyCheck(this.axios, parameters);
  };
  verifyApply = async (parameters: verifyApplyParameters) => {
    return verifyApply(this.axios, parameters);
  };
  verifyCode = async (parameters: verifyCodeParameters) => {
    return verifyCode(this.axios, parameters);
  };
  changePassword = async (parameters: ChangePasswordPayload) => {
    return changePassword(this.axios, parameters);
  };
  userJoin = async (parameters: userJoinPayloadType) => {
    return userJoin(this.axios, parameters);
  }
  userLogin = async (parameters: userLoginPayloadType) => {
    return userLogin(this.axios, parameters);
  }
  googleUserLogin = async (parameters: googleUserLoginPayloadType) => {
    return googleUserLogin(this.axios, parameters);
  }
  googleUserJoin = async (parameters: googleUserJoinPayloadType) => {
    return googleUserJoin(this.axios, parameters);
  }
  lineUserLogin = async (parameters: lineUserLoginPayloadType) => {
    return lineUserLogin(this.axios, parameters);
  }
  fbUserLogin = async (parameters: fbUserLoginPayloadType) => {
    return fbUserLogin(this.axios, parameters);
  }
  fbUserJoin = async (parameters: fbUserJoinPayloadType) => {
    return fbUserJoin(this.axios, parameters);
  }
  userForgetPassword = async (parameters: userForgetPasswordPayloadType) => {
    return userForgetPassword(this.axios, parameters);
  }
  getSchoolList = async () => {
    return getSchoolList(this.axios);
  }
  teamCreate = async (parameters: teamCreatePayload) => {
    return teamCreate(this.axios, parameters);
  }
  teamJoin = async (parameters: teamJoinPayload) => {
    return teamJoin(this.axios, parameters);
  }
  teamQuery = async (parameters: teamQueryPayload) => {
    return teamQuery(this.axios, parameters);
  }
  teamNameQuery = async (parameters: teamNameQueryPayload) => {
    return teamNameQuery(this.axios, parameters);
  }
  applyQuery = async (parameters: applyQueryPayload) => {
    return applyQuery(this.axios, parameters);
  }
  userReset = async (parameters: userResetPayloadType) => {
    return userReset(this.axios, parameters);
  }
}

export default new Api();
