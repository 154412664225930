import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface teamCreatePayload {
  cu_id: string;
  email: string;
  school_id: number;
  student_id: string;
  professor: string;
  team_name: string;
}


export interface teamCreateResponse {
  err: string;
  message: string;
  data?: string;
  team_name?: string;
}

export const teamCreate = async (
  axios: AxiosInstance,
  payload: teamCreatePayload
) => {

  const res = await axios.post<teamCreateResponse>(
    "api2/Team/TeamCreate", payload
  );


  if (res.data.err !== "0000") {
    toast.error(
      `隊伍建立失敗:${"(" + res.data.err + ")" + res.data.message}`,
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  } else {
    // toast.success(`隊伍建立成功，隊伍編號為${res.data.data}`, {
    //   position: "top-left",
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
  }
  console.log('teamData', res)
  return res.data;
};
