import { AxiosInstance } from "axios";
import { toast } from "react-toastify";


export interface ChangePasswordPayload {
  password: string;
  cuid: string;
}




export const changePassword = async (axios: AxiosInstance, payload: ChangePasswordPayload) => {

  //新密碼可能是空值 帶空值過去代表不進行變更
  console.log('payload', payload)
  if (payload.cuid === "") {

    return
  }

  const requestData = JSON.stringify({
    new_Password: payload.password,
    cu_id: payload.cuid,
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const response = await axios.post(
    "api2/User/ChangePassword",
    requestData,
    { headers }
  );
  if (!response.data) {
    return { err: "9999", cu_id: "", campaign: [] };
  }
  if (response.data.err !== "0000") {
    toast.warn(`密碼變更失敗${":(" + response.data.err + ")"}`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  } else {
    // toast.success(`密碼變更成功`, {
    //   position: "top-right",
    //   autoClose: 1300,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
  }
  return response.data
};
