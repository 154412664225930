import React from 'react';
import classNames from 'classnames/bind';
import RcInputNumber from 'rc-input-number';
import { toast } from 'react-toastify';
import { ScaleItem } from '../../model/productsTree';

import { scaleCalculator, getClosest } from '../../tools/scaleCalculator';

import * as Icons from '../../icons';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface PriceWithStepperProps {
  value: number | null;
  min?: number;
  max?: number;
  scaleItems?: ScaleItem[];
  readOnly?: boolean;
  disabled?: boolean;
  handleClassName?: string;
  className?: string;
  onChange?: (value: number) => void;
}

const DEFAULT_SCALE_ITEMS: ScaleItem[] = [
  {
    ScopeMin: 0,
    ScopeMax: 0,
    Numerator: 1,
    Denominator: 1,
    MinMovement: 1,
  },
];

const PriceWithStepper: React.FC<PriceWithStepperProps> = ({
  value,
  scaleItems = DEFAULT_SCALE_ITEMS,
  disabled = false,

  handleClassName = '',
  readOnly = false,
  min = -99999999999,
  max = 99999999999,
  className,
  onChange,
}) => {
  React.useEffect(() => {
    if (min > 0 && value) {
      value = Math.abs(value);
      onChange?.(value);
    }
  }, [value]);
  const handleButtonClick = (isPlus: boolean) => {
    if (value === null) {
      value = 0;
    }

    const _value = getClosest(scaleItems, isPlus, value);
    const newValue = scaleCalculator(_value, isPlus, 1, scaleItems, {
      min,
      max,
    });

    if (newValue.length < 1) {
      return;
    }
    if (newValue[0] < 1) return;
    onChange?.(newValue[0]);
  };
  const onChangeCheckUnit = (isPlus: boolean) => {
    if (value === null) {
      value = 0;
    }
    const _value = getClosest(scaleItems, isPlus, value);
    const newValuePlus = scaleCalculator(_value, true, 1, scaleItems, {
      min,
      max,
    });

    // const newValueMinus = scaleCalculator(_value, false, 1, scaleItems, {
    //   min,
    //   max,
    // });
    let newValueToSet = 0;

    // 這段是會將不符合檔差價格自動調整為最接近之檔差價格
    // if (value === _value) {
    //   newValueToSet = _value;
    // } else if (value > _value) {
    //   const diffToTop = newValuePlus[0] - value;
    //   const diffToBottom = value - _value;
    //   if (diffToTop < diffToBottom) {
    //     newValueToSet = newValuePlus[0];
    //   }
    // } else {
    //   newValueToSet = _value;
    // }

    // 這段是會將不符合檔差價格變空值
    if (value === _value) {
      newValueToSet = _value;
    } else {
      toast.warning(`委託價格錯誤，不符合最小升降單位`, {
        position: 'top-right',
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      newValueToSet = null as any;
    }

    // else if (value > _value) {
    //   const diffToTop = newValuePlus[0] - value;
    //   const diffToBottom = value - _value;
    //   if (diffToTop < diffToBottom) {
    //     newValueToSet = newValuePlus[0];
    //   }} else  {
    //     newValueToSet = _value;
    //   }
    // }
    onChange?.(newValueToSet);
  };

  return (
    <div className={cx('wrap', { 'wrap--disabled': disabled }, className)}>
      <div
        className={cx('handler-wrap__handler', 'p-2')}
        onClick={
          disabled ? undefined : handleButtonClick?.bind(undefined, false)
        }
      >
        <Icons.MinusStepper fill="black" height={15} />
      </div>
      <div className={cx('input-wrap')}>
        <RcInputNumber
          className={cx('input-wrap__input')}
          type="number"
          value={value}
          onChange={onChange as any}
          onBlur={onChangeCheckUnit as any}
          upHandler={null}
          downHandler={null}
          pattern={'[0-9]*'}
          disabled={disabled}
          readOnly={readOnly}
          inputMode="decimal"
        />
      </div>

      <div
        className={cx('handler-wrap__handler', 'p-2')}
        onClick={
          disabled ? undefined : handleButtonClick?.bind(undefined, true)
        }
      >
        <Icons.PlusStepper fill="black" height={15} />
      </div>
    </div>
  );
};
export default PriceWithStepper;
