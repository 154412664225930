import { Subject } from "rxjs";

import {
  KLineMessage,
  BidAskMessage,
  TickMessage,
  InfoMessage,
  UpdateEventMessage,
  BnsMessage,
} from "./model";

const HeartbeatSubject = new Subject<string>();
const SessionIdSubject = new Subject<InfoMessage>();
const BidAskSubject = new Subject<BidAskMessage>();
const KLineSubject = new Subject<KLineMessage>();
const TickSubject = new Subject<TickMessage>();
const UpdateEventSubject = new Subject<UpdateEventMessage>();
const BnsSubject = new Subject<BnsMessage>();

export {
  HeartbeatSubject,
  SessionIdSubject,
  BidAskSubject,
  KLineSubject,
  TickSubject,
  UpdateEventSubject,
  BnsSubject,
};
