import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface depositParameters {
  cu_id: string;
}

interface depositResponse {
  err: string;
  cu_id: string;
  message?: string;
}

export const deposit = async (
  axios: AxiosInstance,
  { cu_id }: depositParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<depositResponse>(
      "api2/Billing/Deposit",
      requestData,
      { headers }
    );
    if (!data) {
      toast.error(`入金失敗:${"(" + "9999" + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", message: "入金失敗!" };
    }
    if (!data || data.err !== "0000") {
      toast.error(`入金失敗:${"(" + data.err + ")" + data.message}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.success(`入金成功:${"(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", message: "入金失敗!" };
  }
};
