import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface accountResettParameters {
  cu_id: string;
}

interface accountResetResponse {
  err: string;
}

export const accountReset = async (
  axios: AxiosInstance,
  { cu_id }: accountResettParameters
) => {
  const requestData = JSON.stringify({
    cu_id,
  });

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<accountResetResponse>(
      "api2/Warrant/AccountReset",
      requestData,
      { headers }
    );
    if (!data || data.err !== "0000") {
      toast.error(`資金重設失敗:${"(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", message: "入金失敗!" };
    } else {
      toast.success(`資金重設成功:${"(" + data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", message: "入金失敗!" };
  }
};
