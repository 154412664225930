import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface teamQueryPayload {
  cu_id: string;
}
export interface member {
  name: string;
}
export interface teamQueryResponse {
  err: string;
  message: string;
  data: any;
  team_name: string;
  team_code: string;
  student_id: string;
  school_id: number;
  professor: string;
}

export const teamQuery = async (
  axios: AxiosInstance,
  payload: teamQueryPayload
) => {

  const res = await axios.post<teamQueryResponse>(
    "api2/Team/TeamQuery", payload
  );


  if (res.data.err !== "0000") {
    // toast.error(
    //   `隊伍查詢失敗:${"(" + res.data.err + ")" + res.data.message}`,
    //   {
    //     position: "top-left",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //   }
    // );
  }

  return res.data;
};
