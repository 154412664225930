import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Container, Badge } from 'react-bootstrap';
import { Column } from 'react-table';
import { Table } from '../../components/DataTable';
import { toast } from 'react-toastify';
import api from '../../api';
import { FavoriteType } from '../../api/newApi/getFavoriteQuery';
import { useFavoriteInfo } from '../../hook/useFavoriteInfo';

import style from './index.module.css';
import OrderModel from '../../components/OrderModel';
import ShareModel from '../../components/ShareGuideModel';
import dayjs from 'dayjs';
import { loginInfo, Page } from '../../model';

const cx = classNames.bind(style);

export interface SelfSelect {
  cuid: string;
  campaignName: string | null;
  sessionId: string | null;
  mtype: string;
  invitationCode: string | null;
  handleOninvitationCode: (code: string) => void;
  campaign_active: boolean;
  continueBell: () => void;
  loginInfo: loginInfo;
  setPageButton: (page: Page) => void;
}

const SelfSelect: React.FC<SelfSelect> = ({
  cuid,
  campaignName,
  sessionId,
  mtype,
  invitationCode,
  handleOninvitationCode,
  campaign_active,
  continueBell,
  loginInfo,
  setPageButton,
}) => {
  const Digits2NumberFormatter = (value: string) =>
    (+value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const Digits3NumberFormatter = (value: string) =>
    (+value).toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });

  const [retryToggle, setRetryToggle] = useState(false);
  const [favorite, setFavorite] = useState<any>(null);

  const getFavorite = useFavoriteInfo({
    cu_id: cuid,
    retryToggle: retryToggle,
    sessionId: sessionId,
    mtype,
  });
  const favoriteData = getFavorite.value ? getFavorite.value.favorites : [];
  const favoritesCount = favoriteData?.length || 0;
  const queryTime = getFavorite.value ? getFavorite.value.QueryTime : '';

  const [show, setShow] = useState(false);
  const [showinviteModel, setShowinviteModel] = useState(false);

  const [istop6K, setIstop6K] = useState<boolean | null>(null);
  //20230601開始活動 前500名獲得line points
  const [isPrize, setIsPrize] = useState<boolean | null>(null);
  // 母股漲停
  const [t_up_stop, setT_up_stop] = useState<number | null>(null);
  // 母股跌停
  const [t_down_stop, setT_down_stop] = useState<number | null>(null);
  //母股現價
  const [target_money, setTarget_money] = useState<string>('');

  const [symCnName, setSymCnName] = useState<string>('');
  const [sym, setSym] = useState<string>('');
  const [bS1, setBS1] = useState<'B' | 'S' | null>(null);
  const [p1, setP1] = useState<number | null>(null);
  const [qty, setQty] = useState<number>(1);
  const [tif, setTif] = useState<'R' | 'F' | 'I' | null>(null);
  const [ort, setOrt] = useState<'1' | '2' | null>(null);
  const [t1, setT1] = useState<string>(
    dayjs().format('YYYY/MM/DD HH:mm:ss.000')
  );
  const [t2, setT2] = useState<string>('');

  React.useEffect(() => {
    const newData = favoriteData;
    const data: any = [];

    setFavorite(() => {
      return data.concat(newData);
    });
  }, [getFavorite]);

  const handleShareModelClose = () => {
    setShowinviteModel(false);
    // (window.parent as any).showBanner(1);
  };

  const handleFavoriteRemove = (cuid: string, stock: string) => {
    api
      .favoriteRemove({
        cu_id: cuid,
        stock_id: stock,
      })
      .then((e) => {
        if (e.err === '0000') {
          setRetryToggle(!retryToggle);
        }
      });
  };
  // React.useEffect(() => { (window.parent as any).resizeIframe() }, [favorite])
  const columns = React.useMemo<Column<FavoriteType>[]>(
    () => [
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>選項</div>
          </>
        ),
        accessor: 'action',
        sticky: 'left',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <div className={cx('btnwrap')}>
            <button
              className={cx(
                'btn',
                'order',
                cellInfo.cell.row.original.s_stats === 0 ? 'disabled_btn' : ''
              )}
              onClick={() => {
                if (cellInfo.cell.row.original.s_stats === 0) return;
                window.sessionStorage.setItem(
                  'ORDER_OBJECT',
                  JSON.stringify({
                    stock: cellInfo.cell.row.original.stock_id,
                    BS: null,
                  })
                );
                setPageButton('order');
                // handleOrder(cellInfo.cell.row.original, 'B');
              }}
            >
              下單
            </button>
            <button
              className={cx('btn', 'del')}
              onClick={() =>
                handleFavoriteRemove(cuid, cellInfo.cell.row.original.stock_id)
              }
            >
              移除
            </button>
          </div>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>商品</div>
          </>
        ),
        accessor: 'stock_name',
        Cell: (cellInfo) => (
          <>{<div>{cellInfo.cell.row.original.stock_name}</div>}</>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>商品代號</div>
          </>
        ),
        accessor: 'stock_id',
        Cell: (cellInfo) => (
          <>
            {
              <div className={cx('tablewrap')}>
                {cellInfo.cell.row.original.stock_id}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>漲跌</div>
          </>
        ),
        accessor: 'stock_gain',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.stock_gain) > 0
                    ? 'red'
                    : Number(cellInfo.cell.row.original.stock_gain) < 0
                    ? 'green'
                    : ''
                )}
              >
                {Digits2NumberFormatter(
                  `${Number(cellInfo.cell.row.original.stock_gain)}`
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>漲跌%</div>
          </>
        ),
        accessor: 'stock_percent',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.stock_percent) > 0
                    ? 'red'
                    : Number(cellInfo.cell.row.original.stock_percent) < 0
                    ? 'green'
                    : ''
                )}
              >
                {Digits2NumberFormatter(
                  cellInfo.cell.row.original.stock_percent
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>總量</div>
          </>
        ),
        accessor: 'volume',
        Cell: (cellInfo) => (
          <>
            {
              <div className={cx('tablewrap')}>
                {Number(cellInfo.cell.row.original.volume).toLocaleString(
                  'en-US'
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>最高價</div>
          </>
        ),
        accessor: 'high_price',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.high_price) &&
                    Number(cellInfo.cell.row.original.ref_price)
                    ? Number(cellInfo.cell.row.original.high_price) >
                      Number(cellInfo.cell.row.original.ref_price)
                      ? 'red'
                      : Number(cellInfo.cell.row.original.high_price) <
                        Number(cellInfo.cell.row.original.ref_price)
                      ? 'green'
                      : ''
                    : ''
                )}
              >
                {Number(cellInfo.cell.row.original.high_price)
                  ? Digits2NumberFormatter(
                      `${cellInfo.cell.row.original.high_price}`
                    )
                  : '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>最低價</div>
          </>
        ),
        accessor: 'low_price',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.low_price) &&
                    Number(cellInfo.cell.row.original.ref_price)
                    ? Number(cellInfo.cell.row.original.low_price) >
                      Number(cellInfo.cell.row.original.ref_price)
                      ? 'red'
                      : Number(cellInfo.cell.row.original.low_price) <
                        Number(cellInfo.cell.row.original.ref_price)
                      ? 'green'
                      : ''
                    : ''
                )}
              >
                {Number(cellInfo.cell.row.original.low_price)
                  ? Digits2NumberFormatter(
                      `${cellInfo.cell.row.original.low_price}`
                    )
                  : '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>買進</div>
          </>
        ),
        accessor: 'stock_buy',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.stock_buy) &&
                    Number(cellInfo.cell.row.original.ref_price)
                    ? Number(cellInfo.cell.row.original.stock_buy) >
                      Number(cellInfo.cell.row.original.ref_price)
                      ? 'red'
                      : Number(cellInfo.cell.row.original.stock_buy) <
                        Number(cellInfo.cell.row.original.ref_price)
                      ? 'green'
                      : ''
                    : ''
                )}
              >
                {Number(cellInfo.cell.row.original.stock_buy)
                  ? Digits2NumberFormatter(cellInfo.cell.row.original.stock_buy)
                  : '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tablehaderWrap')}>賣出</div>
          </>
        ),
        accessor: 'stock_sale',
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  'tablewrap',
                  Number(cellInfo.cell.row.original.stock_sale) &&
                    Number(cellInfo.cell.row.original.ref_price)
                    ? Number(cellInfo.cell.row.original.stock_sale) >
                      Number(cellInfo.cell.row.original.ref_price)
                      ? 'red'
                      : Number(cellInfo.cell.row.original.stock_sale) <
                        Number(cellInfo.cell.row.original.ref_price)
                      ? 'green'
                      : ''
                    : ''
                )}
              >
                {Number(cellInfo.cell.row.original.stock_sale)
                  ? Digits2NumberFormatter(
                      cellInfo.cell.row.original.stock_sale
                    )
                  : '--'}
              </div>
            }
          </>
        ),
      },
    ],
    [getFavorite]
  );
  return (
    <div className={cx('SelfWrap')}>
      <Container className={cx('container-left')} fluid="xl">
        <div className={cx('text-color', ' container-left', 'my-2')}>
          {' '}
          自選清單 <Badge className={cx('badge-box')}>{favoritesCount}筆</Badge>
        </div>
        <div className={cx('updateTime')}>更新時間：{queryTime || ''}</div>
        <div className={cx('tableHead-minHeight')}>
          <Table
            columns={columns}
            data={favorite || []}
            pagination={true}
            loading={getFavorite.loading}
          />
        </div>

        <ShareModel
          isOpen={showinviteModel}
          handleClose={() => {
            handleShareModelClose();
          }}
          istop6K={istop6K}
          invitationCode={invitationCode}
          isPrize={isPrize}
        />
      </Container>
    </div>
  );
};

export default SelfSelect;
