import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export interface OrderPlaceParameters {
  cu_id: string;
  // 來源
  src: string;
  sym: string;
  bS1: string;
  p1: number;
  qty: number;
  /**
   * R:ROD/F:FOK/I:IOC
   */
  tif: "R" | "F" | "I";
  /**
   * 1:市價
   * 2:限價
   * 3.漲停
   * 4.跌停
   * 5.平盤（彬哥那邊取開盤參考價）
   */
  ort: string;
  //客戶端單號
  cik?: string;
  //送出時間
  mgt?: string;
  //ICC = Invite Code Check = "0"/"1" = 要不要啟動邀請碼流程
  icc: "0" | "1";
  dt: boolean;
  uid: number
}

export interface OrderPlaceResponse {
  err: string;
  message?: string;
  inv_code?: string;
  order_no?: number;
  new_mbr?: boolean;
  top6K?: boolean;
  prize?: boolean;
}

export const OrderPlace = async (
  axios: AxiosInstance,
  {
    cu_id,
    src,
    sym,
    bS1,
    p1,
    qty,
    tif,
    ort,
    cik,
    mgt,
    icc,
    dt,
    uid
  }: OrderPlaceParameters
) => {
  const timestamp = dayjs().format("YYYYMMDDHHmmss.SSS");
  const requestData = JSON.stringify({
    cu_id,
    src,
    sym,
    bS1,
    p1,
    qty,
    tif,
    ort,
    cik: timestamp,
    mgt: timestamp,
    icc,
    dt,
    uid
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const { data } = await axios.post<OrderPlaceResponse>(
    "api2/Trade/OrderPlace",
    requestData,
    { headers }
  );
  setTimeout(() => {
    if (!data) {
      toast.warn(`Error:(系統異常)`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, 10000);

  if (data.err !== "0000") {
    toast.warn(`Error${":(" + data.err + ")"}:${data.message}`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return data;
};
