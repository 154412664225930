import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface userLoginPayloadType {
  email: string;
  password: string;
}
export interface userLoginType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number
}



export const userLogin = async (axios: AxiosInstance, payload: userLoginPayloadType) => {

  const { data } = await axios.post<userLoginType>(
    `${process.env.REACT_APP_URL}api/user_login.php`, payload
  );

  console.log(data)
  if (data.code === 0) {
    toast.success(`登入成功`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } else {
    toast.error(`登入失敗，原因：${data.msg}`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return data;
};
