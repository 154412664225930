import React from 'react';

import { Container, Badge } from 'react-bootstrap';
import { Table } from '../../components/DataTable';
import { LEADERBOARD_COLUMN_SIZE } from '../../constants';
import { Column } from 'react-table';

import { LeaderboarType } from '../../model/index';

import { useAsync } from 'react-use';
import api from '../../api';

import classNames from 'classnames/bind';
import style from './index.module.css';
const cx = classNames.bind(style);

function useLeaderboard(cu_id: string) {
  const { loading, value } = useAsync(
    () =>
      api.getLeaderBoard({
        cu_id,
      }),
    []
  );

  return {
    loading,
    value,
    error: value?.err,
  };
}
export interface LeaderboardProps {
  cuid: string;
  campaignName: string | null;
  userName: string;
  code: string;
}
const numberFormatter = (value: string) =>
  (+value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
const Leaderboard: React.FC<LeaderboardProps> = ({
  cuid,
  campaignName,
  userName,
  code,
}) => {
  const { loading: LeaderboardLoading, value } = useLeaderboard(cuid);
  const [teamName, setTeamName] = React.useState('');
  // React.useEffect(() => { (window.parent as any).resizeIframe() }, [value])
  const getTeamQuery = () => {
    api.teamQuery({ cu_id: cuid }).then((e) => {
      if (e?.err === '0000') {
        setTeamName(e.team_name as any);
      }
    });
  };

  React.useEffect(() => {
    if (code === 'KGI02') {
      getTeamQuery();
    }
  }, []);

  const leaderboard_columns = React.useMemo<Column<LeaderboarType>[]>(
    () => [
      {
        Header: '排名',
        id: 'ranking',
        accessor: 'ranking',
        sticky: 'left',
        disableSortBy: true,
        style: { minWidth: '0' },
      },
      {
        Header: '排名變化',
        accessor: 'rank_prev',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            <div
              className={cx(
                'tablewrap',
                cellInfo.cell.row.original.rank_prev === 0 ||
                  cellInfo.cell.row.original.rank_prev === 65535
                  ? ''
                  : cellInfo.cell.row.original.rank_prev >= 1
                    ? 'rank_up'
                    : 'rank_down'
              )}
            >
              {cellInfo.cell.row.original.rank_prev === 65535
                ? '--'
                : cellInfo.cell.row.original.rank_prev}
            </div>
          </>
        ),
      },
      {
        Header: `${code === 'KGI02' ? '隊伍名稱' : '玩家名稱'}`,
        accessor: 'user_name',
        id: 'ranking_name',
        disableSortBy: true,
      },
      {
        Header: code.startsWith('KGI') ? '獲利金額' : '投組淨值',
        accessor: 'nav',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(cellInfo.cell.row.original.nav.toString())}
              </div>
            }
          </>
        ),
      },
      {
        Header: '報酬率',
        accessor: 'roi',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>{<div>{cellInfo.cell.row.original.roi.toFixed(2)}％</div>}</>
        ),
      },
    ],
    []
  );

  return (
    <div className={cx('')}>
      <div className={cx('page-Leaderboardt')}>
        <Container className={cx('container-left')} fluid>
          <div className={cx('text-color', ' container-left', 'my-2')}>
            {' '}
            {/* <Play /> */}
            排行榜
            <span className={cx('badge-username')}>
              {code === 'KGI02' ? teamName : userName}
            </span>
            <span className={cx('ms-2')}>
              排名 {value?.rank === 0 ? '--' : `#${value?.rank}`}
            </span>
          </div>
          <div className={cx('updateTime')}>
            更新時間：{value?.rank_date || ''}
          </div>
   
          <Table
            columns={leaderboard_columns}
            data={value?.leaderboard || []}
            loading={LeaderboardLoading}
            pagination={true}
            renderPage="leaderboard"
            pageSize={LEADERBOARD_COLUMN_SIZE}
          />
          {/* <ul
            className={cx('text-secondary', 'small')}
            style={{ marginBottom: '0' }}
          >
            {' '}
          </ul> */}
          <div className={cx('text-secondary', 'mb-3', 'small')}>
            <div className="my-2">Tip:</div>
            <br />
            <div className="d-flex">
              <div className="flex-grow-0">◎</div>
              <div className="flex-grow-1">
                    {/* 模擬跟常規競賽 排行榜 Tip 分開 */}
                    {`${code === 'KGI02' ? '隊伍' : '玩家'}排名依照獲利金額、報酬率、投組淨值、註冊時間順序排列。`}
              {code.startsWith('KGI') ? '排名方式：依序按獲利金額大到小' : '排名方式：依序按報酬率大到小，投組淨值大到小、註冊時間早到晚。'}
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-0">◎</div>
              <div className="flex-grow-1">
                開盤前淨值以開盤參考價試算。
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-0">◎</div>
              <div className="flex-grow-1">
                排行榜更新時間：盤中不更新，收盤15:00後更新。
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-0">◎</div>
              <div className="flex-grow-1">
                「投組淨值」計算公式：投組淨值=[現金資產] +
                [庫存部位價值=加總每檔商品(現價×張數×1000)]。
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-0">◎</div>
              <div className="flex-grow-1">
              「報酬率」計算公式：報酬率=[投組淨值-(初始金額+加碼+入金)] / (初始金額+加碼+入金)
              </div>
            </div>
          </div>

          {/* <ul className={cx('list-decimal', 'text-secondary', 'small')}>
            <li>
              「投組淨值」計算公式：投組淨值=[現金資產] +
              [庫存部位價值=加總每檔商品(現價×張數×1000)]。
            </li>
            <li>
              「報酬率」計算公式：報酬率=[投組淨值-(初始金額+入金)] /
              (初始金額+入金)。
            </li>
          </ul> */}
        </Container>
      </div>
    </div>
  );
};

export default Leaderboard;
