import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface googleUserLoginPayloadType {
  goid: string;
  goname: string;
  goemail: string;

}


export interface googleUserLoginType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number
}



export const googleUserLogin = async (axios: AxiosInstance, payload: googleUserLoginPayloadType) => {

  const { data } = await axios.post<googleUserLoginType>(
    `${process.env.REACT_APP_URL}api/google_login.php`, payload
  );

  console.log(data)

  if (data.code === 0) {
    toast.success(`登入成功`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  // else if (data.code === 3) {
  //   toast.warning(`登入失敗，原因：尚未註冊`, {
  //     position: "top-right",
  //     autoClose: 1300,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  // }


  // else {
  //   toast.error(`登入失敗，原因：${data.msg}`, {
  //     position: "top-right",
  //     autoClose: 1300,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  // }
  return data;
};
