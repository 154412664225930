import React, { useState } from 'react';

import { Button, Col, Modal } from 'react-bootstrap';
import { Page, loginInfo } from '../../model';
import classNames from 'classnames/bind';
import 'react-slideshow-image/dist/styles.css';
import style from './index.module.css';
import {
  REGEX_PASSWORD,
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_PHONE,
} from '../../constants';
import api from '../../api';
import { Lock, Eye } from '../../icons';
import { toast } from 'react-toastify';
const cx = classNames.bind(style);

interface SubmitModelProps {
  isOpen: boolean;
  handleClose: () => void;
  isThirdPartyLogin?: string;
  thirdWayProfile?: any;
  setPageButton: (page: Page) => void;
  setUserIslogin: (isLogin: boolean) => void;
  setLoginInfo: (loginInfo: loginInfo) => void;
}

const SubmitModel: React.FC<SubmitModelProps> = ({
  isOpen,
  handleClose,
  isThirdPartyLogin,
  thirdWayProfile,
  setLoginInfo,
  setUserIslogin,
  setPageButton,
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCanSee, setPasswordCanSee] = useState(false);
  const [password2, setPassword2] = useState('');
  const [password2CanSee, setPassword2CanSee] = useState(false);
  const [check, setcheck] = useState(false);
  const [sumbitError, setSumbitError] = useState<string[]>([]);
  const errMsg = React.useRef({
    wrongPhone: '*請填寫手機號碼(共10碼)',
    differentPassword: '*兩次密碼不相符',
    passwordLength: '*密碼格式錯誤',
    wrongEmail: '*E-mail格式錯誤',
    wrongName: '*請填寫中文暱稱，上限10個字',
    wrongPassword: '*密碼格式錯誤',
    notCheck: '*尚未勾選',
  });
  const resetInput = () => {
    setName('');
    setPhone('');
    setEmail('');
    setPassword('');
    setPassword2('');
    setcheck(false);
    setSumbitError([]);
  };
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    
  };
  
  const validateName = () => {
    if (!/^[\u4e00-\u9fa5]{2,}$/.test(name)) {
      let error = [...sumbitError, 'wrongName'];
      setSumbitError(error);
      return false;
    } else {
      let error = sumbitError.filter((item) => item !== 'wrongName');
      setSumbitError(error);
      return true;
    }
  };
  const successLogin = (data: any) => {
    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
    resetInput();
    handleClose();
    setLoginInfo({
      email: data.mail,
      phone: data.phone,
      uid: data.uid,
    });
    setUserIslogin(true);
    setPageButton('gameIndex');
  };
  const handleSubmit = async () => {
    if (!validateName()) {
      console.log('validateName error');
      return;
    }
    if (isThirdPartyLogin !== '') {
      let errData: string[] = [];
      const payload = { ...thirdWayProfile, name: name, phone: phone };
      if (name === '' || !REGEX_NAME.test(name) || name.length > 10) {
        errData = [...errData, 'wrongName'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongName');
        setSumbitError(errData);
      }
      if (phone === '' || !REGEX_PHONE.test(phone)) {
        errData = [...errData, 'wrongPhone'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongPhone');
        setSumbitError(errData);
      }
      if (!check) {
        errData = [...errData, 'notCheck'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'notCheck');
        setSumbitError(errData);
      }
      if (!check || !name || !phone) {
        return;
      }
      if (isThirdPartyLogin === 'google') {
        // console.log('31231', payload);

        const data = await api.googleUserJoin(payload);
        if (data.code === 0) {
          window.sessionStorage.setItem('LOGIN_TYPE', 'google');
          successLogin(data);
        } else {
          toast.error(`註冊失敗${':(' + data.code + ')' + data.msg}`, {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        return;
      } else if (isThirdPartyLogin === 'line') {
        const data = await api.lineUserLogin(payload);
        if (data.code !== '0000') {
          toast.error(`註冊失敗${':(' + data.code + ')' + data.msg}`, {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        } else {
          const storedCode = localStorage.getItem('code');
          if (storedCode) { 
            const newUrl = new URL(window.location.origin);
            window.history.pushState({}, document.title, `${newUrl}?code=${storedCode}`)
          }
          window.sessionStorage.setItem('LOGIN_TYPE', 'line');
          successLogin(data);
        }
        return;
      } else {
        const data = await api.fbUserJoin(payload);
        if (data.code === 0) {
          window.sessionStorage.setItem('LOGIN_TYPE', 'fb');
          successLogin(data);
        } else {
          toast.error(`註冊失敗${':(' + data.code + ')' + data.msg}`, {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        return;
      }
    } else {
      let errData: string[] = [];
      if (name === '' || !REGEX_NAME.test(name) || name.length > 10) {
        errData = [...errData, 'wrongName'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongName');
        setSumbitError(errData);
      }
      if (phone === '' || !REGEX_PHONE.test(phone)) {
        errData = [...errData, 'wrongPhone'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongPhone');
        setSumbitError(errData);
      }
      if (email === '' || !REGEX_EMAIL.test(email)) {
        errData = [...errData, 'wrongEmail'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongEmail');
        setSumbitError(errData);
      }
      if (password === '' || !REGEX_PASSWORD.test(password)) {
        errData = [...errData, 'wrongPassword'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'wrongPassword');
        setSumbitError(errData);
      }
      if (!check) {
        errData = [...errData, 'notCheck'];
        setSumbitError(errData);
      } else {
        errData = sumbitError.filter((item) => item !== 'notCheck');
        setSumbitError(errData);
      }

      if (
        !name ||
        !phone ||
        !email ||
        !password ||
        !password2 ||
        sumbitError.length > 0 ||
        !check
      ) {
        return;
      }

      const data = await api.userJoin({
        email,
        password,
        name,
        phone,
      });
      if (data.code === 0) {
        resetInput();
        handleClose();
        setLoginInfo({
          email: data.mail,
          phone: data.phone,
          uid: data.uid,
        });
        setUserIslogin(true);
        window.sessionStorage.setItem('LOGIN_TYPE', 'email');
        setPageButton('gameIndex');
      }
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        resetInput();
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        className={cx('popup_header')}
      >
        <Modal.Title className={cx('w-100', 'text-center', 'text-white')}>
          會員註冊
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={cx('modal-body')}>
          <div className={cx('modal-body-title')}>
            <h4>請輸入註冊資料</h4>
          </div>
          <div className={cx('modal-body-content', 'd-flex', 'flex-column')}>
            <div className={cx('mt-2')}>
              <input
                type="text"
                className={cx('form-control', 'form-control-lg')}
                placeholder="暱稱(中文)"
                value={name}onChange={handleChangeName}
                onBlur={validateName}
              />
              <div
                className={cx(
                  sumbitError.length > 0 && sumbitError.includes('wrongName')
                    ? 'd-block'
                    : 'd-none',
                  'errmsg'
                )}
              >
                <small>{errMsg.current.wrongName}</small>
              </div>
            </div>
            <div className={cx('mt-2')}>
              <input
                type="text"
                className={cx('form-control', 'form-control-lg')}
                placeholder="手機號碼"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value !== '' && !REGEX_PHONE.test(phone)) {
                    let error = [...sumbitError, 'wrongPhone'];
                    setSumbitError(error);
                  } else {
                    let error = sumbitError.filter(
                      (item) => item !== 'wrongPhone'
                    );
                    setSumbitError(error);
                  }
                }}
              />
              <div
                className={cx(
                  sumbitError.length > 0 && sumbitError.includes('wrongPhone')
                    ? 'd-block'
                    : 'd-none',
                  'errmsg'
                )}
              >
                <small>{errMsg.current.wrongPhone}</small>
              </div>
            </div>

            {!!isThirdPartyLogin ? (
              ''
            ) : (
              <>
                <div className={cx('mt-2')}>
                  <input
                    type="text"
                    className={cx('form-control', 'form-control-lg')}
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => {
                      if (!REGEX_EMAIL.test(email)) {
                        let error = [...sumbitError, 'wrongEmail'];
                        setSumbitError(error);
                      } else {
                        let error = sumbitError.filter(
                          (item) => item !== 'wrongEmail'
                        );
                        setSumbitError(error);
                      }
                    }}
                  />
                  <div
                    className={cx(
                      sumbitError.length > 0 &&
                        sumbitError.includes('wrongEmail')
                        ? 'd-block'
                        : 'd-none',
                      'errmsg'
                    )}
                  >
                    <small>{errMsg.current.wrongEmail}</small>
                  </div>
                </div>
                <div className={cx('mt-2')}>
                  <div
                    className={cx(
                      'w-100',
                      'h-100',
                      'd-flex',
                      'form-control',
                      'form-control-lg',
                      'position-relative',
                      'p-0',
                      'align-items-center'
                    )}
                  >
                    <div className={cx('position-absolute', 'lockIcon')}>
                      <Lock width={25} height={25} fill="#eeeeee" />
                    </div>
                    <input
                      type={passwordCanSee ? 'text' : 'password'}
                      className={cx('w-100', 'border-0', 'passwordInput')}
                      placeholder="輸入密碼"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (
                          e.target.value !== '' &&
                          !REGEX_PASSWORD.test(e.target.value)
                        ) {
                          let error = [...sumbitError, 'wrongPassword'];
                          setSumbitError(error);
                        } else {
                          let error = sumbitError.filter(
                            (item) => item !== 'wrongPassword'
                          );
                          setSumbitError(error);
                        }
                      }}
                      onBlur={(e) => {
                        if (password !== '' && password !== password2) {
                          let error = [...sumbitError, 'differentPassword'];
                          setSumbitError(error);
                        } else {
                          let error = sumbitError.filter(
                            (item) => item !== 'differentPassword'
                          );
                          setSumbitError(error);
                        }
                      }}
                    />
                    <div
                      className={cx('position-absolute', 'eyeIcon')}
                      onClick={() => {
                        setPasswordCanSee(!passwordCanSee);
                      }}
                    >
                      <Eye
                        width={25}
                        height={25}
                        fill={passwordCanSee ? '#000' : '#eee'}
                      />
                    </div>
                  </div>

                  <div
                    className={cx(
                      sumbitError.length > 0 &&
                        sumbitError.includes('differentPassword')
                        ? 'd-block'
                        : 'd-none',
                      'errmsg'
                    )}
                  >
                    <small>{errMsg.current.differentPassword}</small>
                  </div>
                </div>

                <div className={cx('mt-2')}>
                  <div
                    className={cx(
                      'w-100',
                      'h-100',
                      'd-flex',
                      'form-control',
                      'form-control-lg',
                      'position-relative',
                      'p-0',
                      'align-items-center'
                    )}
                  >
                    <div className={cx('position-absolute', 'lockIcon')}>
                      <Lock width={25} height={25} fill="#eeeeee" />
                    </div>
                    <input
                      type={password2CanSee ? 'text' : 'password'}
                      className={cx('w-100', 'border-0', 'passwordInput')}
                      placeholder="再次輸入密碼"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      onBlur={(e) => {
                        if (
                          !sumbitError.includes('wrongPassword') &&
                          password !== password2
                        ) {
                          let error = [...sumbitError, 'differentPassword'];
                          setSumbitError(error);
                        } else {
                          let error = sumbitError.filter(
                            (item) => item !== 'differentPassword'
                          );
                          setSumbitError(error);
                        }
                      }}
                    />
                    <div
                      className={cx('position-absolute', 'eyeIcon')}
                      onClick={() => {
                        setPassword2CanSee(!password2CanSee);
                      }}
                    >
                      <Eye
                        width={25}
                        height={25}
                        fill={password2CanSee ? '#000' : '#eee'}
                      />
                    </div>
                  </div>

                  <div
                    className={cx(
                      sumbitError.length > 0 &&
                        sumbitError.includes('differentPassword')
                        ? 'd-block'
                        : 'd-none',
                      'errmsg'
                    )}
                  >
                    <small>{errMsg.current.differentPassword}</small>
                  </div>
                </div>

                <small className={cx('text-start')}>
                  密碼：請填寫6~12位英文或數字
                </small>
              </>
            )}

            <small className={cx('mt-5')}>
            ※請填寫正確會員註冊資料，如參加競賽，將為中獎聯絡使用
            </small>
            <div className={cx('text-start', 'mt-1')}>
              <input
                type="checkbox"
                value={check as any}
                onChange={(e) => {
                  setcheck(e.target.checked);
                }}
                className={cx(
                  'd-inline-block',
                  'me-1',
                  sumbitError.length > 0 &&
                    sumbitError.includes('notCheck') &&
                    !check
                    ? 'errorInputCheckBox'
                    : ''
                )}
              ></input>
              <span className={cx('text-start')}>
                同意《活動辦法》與
                <span
                  className={cx('hyperLinkText')}
                  onClick={() => {
                    window.open('https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information');
                  }}
                >
                  《蒐集個人資料告知事項與同意書》
                </span>
                內容。
              </span>
              <div
                className={cx(
                  check
                    ? 'd-none'
                    : sumbitError.length > 0 && sumbitError.includes('notCheck')
                    ? 'd-block'
                    : 'd-none',
                  'errmsg'
                )}
              >
                <small>{errMsg.current.notCheck}</small>
                {/* <small>請勾選同意活動辦法</small>notCheck */}
              </div>
            </div>
            <div className={cx('col-10', 'mx-auto')}>
              <Button
                className={cx(
                  'form-control',
                  'form-control-lg',
                  'mt-5',
                  'submitBtn'
                )}
                size="lg"
                onClick={() => handleSubmit()}
              >
                註冊
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default SubmitModel;
