import { AxiosInstance } from "axios";
import { toast } from "react-toastify";
export interface PublisherQueryResponse {
  err: string;
  publisher: Publisher[];
}

export interface Publisher {
  name: string;
}

export const getPublisherQuery = async (axios: AxiosInstance) => {
  try {
    const response = await axios.post<PublisherQueryResponse>(
      "api2/Warrant/PublisherQuery"
    );
    if (!response.data) {
      toast.error(`取得發行人Error:not found`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { err: "9999", publisher: [] };
    }
    if (response.data.err !== "0000") {
      toast.warn(`取得發行人${"(" + response.data.err + ")"}`, {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    return response.data;
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", publisher: [] };
  }
};
