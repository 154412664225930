import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface applyCampaignParameters {
  phone: string;
  email: string;
  code: string;
  student_id?: string;
  school_id?: number;
  professor?: string;
  user_id?:string;
}

interface applyCampaignResponse {
  err: string;
  cu_id: string;
  message?: string;
  user_name?: string;
}

export const applyCampaign = async (
  axios: AxiosInstance,
  payload: applyCampaignParameters
) => {
  const reaquestPayload: { phone: string; email: string; code: string; student_id?: string; school_id?: number; professor?: string; user_id?: string } = {
    phone: payload.phone,
    email: payload.email,
    code: payload.code
  }
  payload.student_id && (reaquestPayload.student_id = payload.student_id)
  payload.school_id && (reaquestPayload.school_id = payload.school_id)
  payload.professor && (reaquestPayload.professor = payload.professor)
  payload.user_id && (reaquestPayload.user_id = payload.user_id) 


  const requestData = JSON.stringify(reaquestPayload);

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  try {
    const { data } = await axios.post<applyCampaignResponse>(
      "api2/Warrant/ApplyCampaign",
      requestData,
      { headers }
    );
    if (!data) {
      return { err: "9999", cu_id: null, user_name: "" };
    }
    // if (data.err !== "0000") {
    //   if (payload.student_id && payload.school_id) {
    //     toast.error(`報名個人競賽失敗:${"(" + data.err + ")：" + data.message}`, {
    //       position: "top-right",
    //       autoClose: 1300,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //     });
    //   } else {
    //     toast.error(`加入競賽失敗:${"(" + data.err + ")：" + data.message}`, {
    //       position: "top-right",
    //       autoClose: 1300,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //     });
    //   }

    // }
    // else {
    //   if (payload.student_id && payload.school_id) {
    //     toast.success(`競賽報名成功`, {
    //       position: "top-left",
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //     });
    //   }
    // }
    return { ...data };
  } catch (error) {
    // (window.parent as any).reactLogout(`登入閒置已超過20分鐘，請重新登入。`);
    return { err: "9999", cu_id: null, user_name: "" };
  }
};
